import React from "react";
import AppTitle from "../AppLayout/AppTitle";
import AppFooter from "../AppLayout/AppFooter";
import Browse from "../Browse";
import Env from "../../utils/Services/Env";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Input,
  InputAdornment,
  // Select,
  // MenuItem,
  Card,
  CardContent,
  Divider,
  Pagination,
  Box,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../Assets/css/Search.css";
import "../../Assets/css/AlphabetFilter.css";
import "../../Assets/css/Pagination.css";
import "../../Assets/css/PaginationStyle.css";
import Container from "@mui/material/Container";
import Searchimg from "../../Assets/Static/banner-search.svg";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { searchLocation } from "../reducer/action";
import { toast } from "react-toastify";


class Payers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        count: 50,
        speciality: "",
        filterbyalphabet: "",
        page: 0,
        pageSize: 15,
      },
      statesList: [],
      payerData: [],
      payerOptions: [],
      statesData: [],
      alphabets: [],
      payerName: "",
      stateName: "",
      hospitalName: "",
      selectedAlphabet: "All",
      searchClose: false,
      optionDisplay: false,
      selectedItem: "",
      locationOptions: false,
      showByAddress: false,
      showList: false,
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    this.getAllStates();
    // this.getAllPayerData();

    const { payerName, location, searchLoc } = this.props;

    if (location !== null) {
      this.setState({
        stateName: location
      })
      if (payerName !== null) {
        const textAfterComma =
          location === undefined
            ? searchLoc?.split(",")[1].trim()
            : location?.split(",")[1].trim();
        const firstLetter = payerName?.charAt(0);

        this.setState(
          {
            payerName: payerName,
            stateName: location === undefined ? searchLoc : location,
            selectedAlphabet: firstLetter,
          },
          () => {
            if (location !== null) {
              this.getAllPayerData("", firstLetter, textAfterComma);
            } else {
              this.getAllPayerData("",firstLetter, location === undefined ? searchLoc : location,);
            }
          }
        );
      }
    } else {
      this.getAllPayerData("", null, searchLoc === "" || searchLoc === null ? location :searchLoc);
      const local = searchLoc === "" || searchLoc === null ? location :searchLoc
      this.handleLocation("", local);
    }
    window.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.payerName !== this.props.payerName) {
      // const textAfterComma =
      //   this.props.location === undefined
      //     ? this.props.searchLoc
      //     : this.props.location;
      this.setState(
        {
          payerName: nextProps.payerName,
          stateName: nextProps.textAfterComma,
        },
        () => {
          this.getAllStates();
          // this.getAllPayerData();
          const firstLetter = this.props.payerName?.charAt(0);
          this.getAllPayerData("", firstLetter, "");
        }
      );
    }
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current?.contains(event.target)) {
      this.state.optionDisplay &&
        this.setState({
          optionDisplay: false,
          payerName: "",
        });
      this.state.locationOptions &&
        this.setState({
          locationOptions: false,
          stateName: "",
        });
    }
  };

  getSearchPayerOption = (value) => {
    let stateVal
    if(this.state.stateName !== "" || this.state.stateName !== undefined || this.state.stateName || null){
      stateVal = this.state.stateName    }else{
      stateVal = this.props.location === undefined || this.props.location === "" || this.props.location === null ? this.props.searchLoc: this.props.location;

    }
    const getSearchPayer = Env.get(`payer/autocomplete?q=${value}&state=${stateVal}`);

    getSearchPayer.then(
      (response) => {
        this.setState({
          payerOptions: response.data.suggession,
          optionDisplay: true,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  payerValue = (e) => {
    this.setState({
      payerName: e.target.value,
      optionDisplay: e.target.value.length > 2 ? true : false,
    });
    if (e.target.value.length > 0) {
      this.setState({
        searchClose: true,
      });
    } else {
      this.setState({
        searchClose: false,
      });
    }

    if (e.target.value.length > 2) {
      this.getSearchPayerOption(e.target.value);
    }
  };

  getAllPayerData = (pageNo, alphabet, state, preSearch) => {
    if (this.state.optionDisplay) {
      this.setState({
        optionDisplay: false,
        stateName: state,
      });
    }

    alphabet =
      this.state.selectedAlphabet !== "All" &&
      alphabet !== undefined &&
      alphabet !== "All"
        ? this.state.selectedAlphabet
        : alphabet === undefined
        ? this.state.selectedAlphabet
        : alphabet === ""
        ? "all"
        : alphabet;

    state =
      (this.state.stateName !== "" || this.state.stateName !== null) &&
      state !== undefined
        ? this.state.stateName === "" ? this.props.location === undefined || this.props.location === null || this.props.location === '' ? this.props.searchLoc : this.props.location : this.state.stateName
        : state === undefined
        ? this.state.stateName : state === "" ? this.props.location === undefined || this.props.location === null || this.props.location === '' ? this.props.searchLoc : this.props.location : state


    let url = `payer/list?`;

    if (state === "") {
      toast.error("Select location..");
      return;
    }

    if (
      this.state.payerName === "" &&
      alphabet === "All" &&
      pageNo === undefined
    ) {
      url = `${url}state=${state}&filterbyalphabet=null&page=${
        pageNo ? pageNo : "1"
      }&pageSize=30`;
    } else if (
      this.state.payerName === undefined &&
      alphabet === "All" &&
      pageNo === undefined
    ) {
      url = `${url}state=${state}&filterbyalphabet=All&page=${
        pageNo ? pageNo : "1"
      }&pageSize=30`;
    } else {
      url = `${url}q=${
        this.state.payerName
      }&state=${state}&filterbyalphabet=${alphabet}&page=${
        pageNo ? pageNo : "1"
      }&pageSize=30`;
    }

    const getPayerData = Env.get(url);

    getPayerData.then((response) => {
      const getData = response.data;
      this.setState({
        payerData: getData.results,
        alphabets: getData.parameters.alphabets,
        parameters: getData.parameters,
        selectedAlphabet:
          getData.parameters.filterbyalphabet === "all"
            ? "All"
            : getData.parameters.filterbyalphabet,
      });
    });
  };

  getAllStates = () => {
    const getAllStateData = Env.get("location/states");

    getAllStateData.then(
      (response) => {
        const getStatesVal = response.data.states;
        let addAll = { code: "All States", display: "All States" };
        let addNone = { code: "None", display: "None" };
        let allState = [addAll, ...getStatesVal];
        let allStates = [...allState, addNone];
        this.setState(
          {
            statesList: allStates,
          },
          () => {
            // if (this.state.statesList.length > 0) {
            //   this.setState({
            //     stateName:
            //       this.state.statesList[this.state.statesList.length - 1].code,
            //   });
            // }
          }
        );
      },
      (err) => {
        console.error(err);
      }
    );
  };

  handleStateChange = (e) => {
    this.setState(
      {
        stateName: e.target.value,
      },
      () => {
        this.getAllPayerData("", "All", this.state.stateName);
      }
    );
  };

  handlePayerOptionClick = (option) => {
    this.setState({
      payerName: option.data,
      optionDisplay: false,
    });
  };

  handlePayerItemClick = (item) => {
    let name = encodeURIComponent(item.display.toLowerCase());

    this.props.navigate(`/payer/info/${name}`);
  };

  selectedPayerAlphabet = (data) => {
    this.setState(
      {
        selectedAlphabet: data,
      },
      () => {
        this.getAllPayerData("1", this.state.selectedAlphabet, "", "");
      }
    );
  };

  handlePageChange = (event, value) => {
    this.getAllPayerData(value);
  };

  clearAll = () => {
    // const textAfterComma =
    //   this.props.location === undefined
    //     ? this.props.searchLoc.split(",")[1].trim()
    //     : this.props.location.split(",")[1].trim();
    this.setState(
      {
        payerName: "",
        searchClose: false,
        optionDisplay: false,
        selectedAlphabet: "All",
        // stateName: "None",
        // stateName:
        //   this.props.location === undefined
        //     ? this.props.searchLoc.split(",")[1].trim()
        //     : this.props.location.split(",")[1].trim(),
        stateName:
          this.props.location === undefined
            ? this.props.searchLoc
            : this.props.location,
      },
      () => {
        this.getAllPayerData("1", "All");
      }
    );
  };

  handleLocation = (e, loc) => {
    // if (e.target.value.length > 4) {
    let val;
    if (loc === "" || loc === undefined) {
      val = e.target.value;
      // if (typeof val === "string" && val !== undefined) {
      //   const { dispatch } = this.props;
      //   dispatch(searchLocation(val));
      // }
    }
    const locValue = {
      location: val === undefined ? loc : val,
    };
    const getSearchProvider = Env.post(`location/getLocation`, locValue);

    getSearchProvider.then(
      (response) => {
        this.setState({
          statesList: response.data.data,
        });
      },
      (error) => {
        console.error(error);
      }
    );
    // }
    this.setState({
      stateName: val === undefined ? loc : val,
      locationOptions: val === undefined ? false : true,
    });
  };

  handleLocationOptionClick = (option) => {
    const { dispatch } = this.props;

    this.setState({
      stateName: option.label,
      locationOptions: false,
    }
  );
    dispatch(searchLocation(option.label));
  };

  render() {
    return (
      <>
        <AppTitle value={false} />
        <div style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
          <Container className="container-common">
            <Browse selectTab={2} />
            <div className="input-container__search" ref={this.dropdownRef}>
              <div
                className="dropdown-list prod-search"
                style={{ position: "relative", width: "60%" }}
              >
                <Input
                  id="banner-input"
                  sx={{ width: "100%" }}
                  disableUnderline
                  autoComplete="off"
                  placeholder="Enter a payer name..."
                  value={this.state.payerName}
                  onChange={(e) => this.payerValue(e)}
                  startAdornment={
                    <InputAdornment
                      sx={{ paddingLeft: "6px" }}
                      position="start"
                    >
                      <img
                        src={Searchimg}
                        alt="search"
                        style={{ width: "100%" }}
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    this.state.searchClose ? (
                      <Button
                        onClick={() => this.clearAll()}
                        type="submit"
                        style={{ position: "absolute", right: "0px" }}
                      >
                        <CloseIcon
                          sx={{ color: "#59B04B", fontStyle: "none" }}
                        />
                      </Button>
                    ) : (
                      ""
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      this.getAllPayerData();
                    }
                  }}
                />
                {this.state.payerOptions.length > 0 &&
                this.state.optionDisplay ? (
                  <List className="procedure-list">
                    {this.state.payerOptions?.map((option, index) => (
                      <ListItem
                        key={index}
                        button
                        onClick={() => this.handlePayerOptionClick(option)}
                      >
                        <ListItemText primary={option.data} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  ""
                )}
              </div>

              <Divider
                orientation="vertical"
                sx={{ marginTop: "3px", height: "30px", color: "#000" }}
              />

              <div className="payer-search">
              <Input
                id="input-with-icon-textfield"
                sx={{
                  marginTop: "2px",
                  paddingLeft: "34px",
                  border: "none !important",
                  color: "#000",
                  width: "350px",
                  fontWeight: "500",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                disableUnderline
                autoComplete="off"
                value={
                  this.state.stateName?.length > 25
                    ? this.state.stateName.slice(0, 25) + "..."
                    : this.state.stateName
                }
                onFocus={() => {
                  this.setState({
                    showList: false,
                    showByAddress: true,
                  });
                }}
                onChange={(e) => this.handleLocation(e)}
                placeholder="Location"
                startAdornment={
                  <InputAdornment position="start" sx={{ color: "#000" }}>
                    <FmdGoodOutlinedIcon sx={{ color: "#000" }} />
                  </InputAdornment>
                }
              />
              {this.state.locationOptions &&
                this.state.statesList?.length > 0 && (
                  <List
                    sx={{
                      position: "absolute",
                      top: "228px",
                      // left: "1133px",
                      backgroundColor: "#f5f5f5",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      border: "1px solid #ccc",
                      borderTop: "none",
                      borderRadius: "22px",
                      marginLeft: "10px",
                      maxHeight: "150px",
                      overflowY: "auto",
                      color: "#000",
                      width: "362px",
                    }}
                  >
                    {this.state.statesList?.map((option) => (
                      <ListItem
                        key={option.id}
                        button
                        onClick={() => this.handleLocationOptionClick(option)}
                      >
                        <ListItemText primary={option.label} />
                      </ListItem>
                    ))}
                  </List>
                )}
                </div>
              <Button
                className="search-ppp-pages"
                onClick={() => this.getAllPayerData()}
              >
                Search
              </Button>
            </div>
            <div className="search-result__content">
              <div className="alphabet-search">
                <Card
                  variant="outlined"
                  className="card-listing__bottomHandler"
                >
                  <CardContent sx={{ paddingBottom: "18px !important" }}>
                    <button
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      onClick={() => this.selectedPayerAlphabet("All")}
                      className={
                        this.state.selectedAlphabet === "All" ? "active" : ""
                      }
                    >
                      ALL
                    </button>
                    {this.state.alphabets.map((letter) => (
                      <button
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          fontStyle: "normal",
                        }}
                        key={letter.letter}
                        onClick={() =>
                          this.selectedPayerAlphabet(letter.letter)
                        }
                        className={
                          this.state.selectedAlphabet === letter.letter
                            ? "active"
                            : ""
                        }
                      >
                        {letter.letter}
                      </button>
                    ))}
                  </CardContent>
                </Card>
              </div>
            </div>
          </Container>
          {/* {this.state.payerData.length <= 0 && ( */}
          <Container className="container-common">
            <Card variant="outlined" className="card-listing__topHandler">
              {this.state.alphabets.length < 0 && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "center",
                    color: "#59B04B",
                    height: "123px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  NO DATA FOUND
                </Grid>
              )}
              {this.state.payerData.length <= 0 && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#45A736",
                    padding: "15px",
                  }}
                >
                  View the payers by clicking above alphabets
                </div>
              )}
            </Card>
          </Container>
          {/* )} */}
          {this.state.payerData.length > 0 && (
            <div style={{ background: "#fff", marginBottom: "2.5rem" }}>
              <Container className="container-common">
                <Card variant="outlined" className="card-listing__topHandler">
                  <CardContent>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        {this.state.payerData.length > 0 ? (
                          this.state.payerData.map((item, index) => (
                            <Grid
                              item
                              md={4}
                              sm={6}
                              xs={12}
                              key={index}
                              sx={{ paddingBottom: "16px" }}
                            >
                              <div
                                key={item.id}
                                onClick={() => this.handlePayerItemClick(item)}
                                className="all-inner"
                              >
                                <div>
                                  {item.display === null
                                    ? "NULL"
                                    : item.display}
                                </div>
                              </div>
                            </Grid>
                          ))
                        ) : (
                          <>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                textAlign: "center",
                                color: "#59B04B",
                                height: "123px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              NO DATA FOUND
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Box>
                  </CardContent>
                  <Pagination
                    count={Math.ceil(
                      this.state.parameters?.count /
                        this.state.parameters?.pageSize
                    )}
                    page={this.state.parameters?.page}
                    onChange={this.handlePageChange}
                  />
                </Card>
              </Container>
            </div>
          )}
        </div>
        <AppFooter value={false} />
      </>
    );
  }
}

export default withRouter(
  connect((state) => {
    return {
      payerName: state.payerName,
      location: state.location,
      searchLoc: state.searchLoc,
    };
  })(Payers)
);
