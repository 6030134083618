export function isLogged(state) {
  return {
    type: "IS_LOGGED_IN",
    payload: state,
  };
}

export function userPreference(state) {
  return {
    type: "PREFERENCE_TYPE",
    payload: state,
  };
}
export function user(state) {
  return {
    type: "USER_TYPE",
    payload: state,
  };
}

export function auth(state) {
  return {
    type: "TOKEN_TYPE",
    payload: state,
  };
}
export function location(state) {
  return {
    type: "LOCATION",
    payload: state,
  };
}

export function memberId(state) {
  return {
    type: "MEMBER_ID",
    payload: state,
  };
}
export function specialtySearch(state) {
  return {
    type: "SPECIALTY",
    payload: state,
  };
}

export function payerSearch(state) {
  return {
    type: "PAYER_Name",
    payload: state,
  };
}

export function initialLocation(state) {
  return {
    type: "INITIAL_LOCATION",
    payload: state,
  };
}

export function searchLocation(state) {
  return {
    type: "SEARCH_LOCATION",
    payload: state,
  };
}

export function scode(state) {
  return {
    type: "SEARCH_PROCEDURE",
    payload: state,
  };
}

export function clearStore(state) {
  return {
    type: "CLEAR_STORE",
    payload: state,
  };
}

export function payerHeaderInfo(state) {
  return {
    type: "PAYER_HEADER_INFO",
    payload: state,
  };
}

export function userName(state) {
  return {
    type: "USER_NAME",
    payload: state,
  };
}
