import * as React from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import { styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { autoPlay } from "react-swipeable-views-utils-react-18-fix";
import AppTitle from "../AppLayout/AppTitle";
import AppFooter from "../AppLayout/AppFooter";
import Avatar from "@mui/material/Avatar";
import profile from "../../Assets/Static/usertestiomials1.png";
import IconButton from "@mui/material/IconButton";
import { Box, Container, Grid } from "@mui/material";
import "../../Assets/Static/usertestiomials1.png";
import Mrs from "../../Assets/Static/testimonialsMrs.jpg";
import Jones from "../../Assets/Static/testimonialsJones.jpg";
import Smith from "../../Assets/Static/testimonialsSmith.jpg";
import Patel from "../../Assets/Static/testimonialsPatel.jpg";
import Johnson from "../../Assets/Static/testimonialsJohnson.jpg";
import Ms from "../../Assets/Static/testimonialsMs.jpg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const images = [
  {
    label: "LLC",
    text: "Healthcare Dollar, LLC has proven an invaluable resource for individuals seeking affordable and efficient healthcare options. These testimonials from satisfied customers highlight the company's commitment to providing personalized and cost-effective solutions for elective procedures and tests. Whether negotiating lower rates, navigating insurance coverage, or providing compassionate support, Healthcare Dollar has consistently gone above and beyond for its clients.",
    imgPath: Mrs,
    title:"Cardiologist",
  },
  {
    label: "Mr. Jones",
    text: "Mr. Jones was highly impressed with their services. The entire process was seamless and stress-free from the initial consultation to scheduling the procedure. The information within Healthcare Dollar was knowledgeable about my insurance coverage and worked with me to ensure that I received the best possible rate for my procedure. Despite my initial doubts about using a third-party company, Healthcare Dollar exceeded my expectations, and I highly recommend their software platform to anyone seeking affordable and efficient healthcare options.",
    imgPath: Jones,
    title:"Neurologist",
  },
  {
    label: "Mrs. Smith",
    text: "Mrs. Smith used their services to schedule a routine mammogram. As a busy working mom, Mrs. Smith found navigating the complicated world of insurance and healthcare difficult. Still, with the help of Healthcare Dollar, she could easily schedule her procedure and even save money on her out-of-pocket expenses by using the mobile app. Mrs. Smith was particularly impressed with the professionalism and efficiency of the mobile app, and she plans on using their services for all future medical procedures.",
    imgPath: Ms,
    title:"Dermotology",
  },
  {
    label: "Mr. Johnson",
    text: "Mr. Johnson had a different experience with Healthcare Dollar, LLC. He had to undergo an unexpected elective surgery and was worried about the financial burden it would place on his family. However, after researching, Mr. Johnson decided to try the Healthcare Dollar mobile app. He was relieved to find that they were able to negotiate a significantly lower rate for his procedure. Not only did Healthcare Dollar save him and his family thousands of dollars, but they also provided compassionate and personalized support throughout the process.",
    imgPath: Johnson,
    title:"Cardiologist",
  },
  {
    label: "Ms. Rodriguez",
    text: "Ms. Rodriguez had put off getting a colonoscopy due to the high costs associated with the procedure. However, after discovering Healthcare Dollar, she finally decided to take care of her health. Ms. Rodriguez was thrilled to find out that not only could Healthcare Dollar schedule her procedure at a much more affordable price, but they also made the process easy and stress-free. Thanks to Healthcare Dollar, Ms. Rodriguez could prioritize her health without breaking the bank.",
    imgPath: Patel,
    title:"Medicine",
  },
  {
    label: "Mr. Patel",
    text: "Mr. Patel used Healthcare Dollar, LLC when his doctor recommended he undergo expensive genetic testing for a potential hereditary condition. With the help of Healthcare Dollar, Mr. Patel could secure the testing at a fraction of the cost and even use his insurance to cover a portion of the remaining expenses. He was pleasantly surprised by the level of personalized attention and support he received from the Healthcare Dollar team, and he has since recommended their services to several of his friends and family members.",
    imgPath: Smith,
    title:"Cardiologist",
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function UserTestimonials() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const index = 0;
  return (
    <>
      <AppTitle value={false} />
      <Container
        className="container-common testimonials"
        sx={{ marginTop: "120px" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9} sx={{ flexGrow: 0 }}>
            <h2
              style={{
                fontSize: "40px",
                fontWeight: 600,
                lineHeight: "53.2px",
                textAlign: "left",
                margin: "0px",
              }}
            >
              Testimonials
            </h2>
          </Grid>
          <Grid item xs={12} lg={3} sx={{ textAlign: "right" }}>
            <Button onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />{" "}
            </Button>
            <Button onClick={handleNext} disabled={activeStep === maxSteps - 4}>
              <KeyboardArrowRight />{" "}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={10} sx={{ flexGrow: 0 }}>
            {/* <p
              style={{
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: "27px",
                textAlign: "left",
                color: "#858585",
              }}
            >
              Testimonials—A list of testimonials to be shown on the pictures
              for each testimonial is noted below.
            </p> */}
          </Grid>
        </Grid>
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 50,
            pl: 2,
            bgcolor: "background.default",
          }}
        >
          {/* <Typography>{images[activeStep].label}</Typography> */}
        </Paper>
        <AutoPlaySwipeableViews
  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
  interval={50000} // Adjust the interval as needed
  index={activeStep}
  onChangeIndex={handleStepChange}
  enableMouseEvents
>
  {images.map((step, index) => (
    <div key={step.label}>
      {Math.abs(activeStep - index) <= 2 ? (
        <Box>
          <Grid container spacing={2} style={{ marginBottom: "70px" }}>
            {[0, 1, 2, 3].map((offset) => {
              const stepIndex = (index + offset) % images.length;
              return (
                <Grid item xs={12} md={6} lg={3} key={stepIndex}>
                  <div className="testimonials-outbox out-text">
                    <div
                      className="testimonials-outbox"
                      style={{
                        border: "1px solid",
                        borderColor: "#DADADA",
                        borderRadius: "20px",
                      }}
                    >
                      <span>
                        <FormatQuoteIcon
                          style={{
                            color: "#45A736",
                            width: "49px",
                            height: "49px",
                            top: "376px ",
                            left: "1164px ",
                            padding: "11px, 4.19px, 8.91px, 5px",
                          }}
                        />
                      </span>
                      <p style={{ marginTop: 0, textAlign: "center" , padding:"10px", paddingBottom:"50px"}}>
                        {images[stepIndex].text}
                      </p>
                      
                    </div>
                    <div
                    style={{
                      marginTop: "-95px",
                      // position: "absolute",
                      // bottom: "-35px",
                      // left:"0px",
                      // right:"0px"
                    }}
                    >
                    <h3
                        style={{
                          color: "#45A736",
                          textAlign: "center",
                          margin: 0,
                        }}
                      >
                        {images[stepIndex].label}
                      </h3>
                      <p
                        style={{
                          color: "#02363D",
                          textAlign: "center",
                          marginTop: 0,
                          marginBottom: "50px",
                        }}
                      >
                        {images[stepIndex].title}
                      </p>
                    
                    <div
                    className="avatar-img"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-45px",
                        // position: "absolute",
                        // bottom: "-35px",
                        // left:"0px",
                        // right:"0px"
                      }}
                    >
                      <Avatar
                        sx={{ width: 80, height: 80 }}
                        src={images[stepIndex].imgPath}
                      />
                    </div>
                  </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : null}
    </div>
  ))}
</AutoPlaySwipeableViews>

        {/* </Box> */}
      </Container>
      <AppFooter value={false} />
    </>
  );
}

export default UserTestimonials;
