import React from "react";
import AppTitle from "../AppLayout/AppTitle";
import AddressMap from "../AddressMap";
import "../../Assets/css/ProviderInfo.css";

import {
  Box,
  Grid,
  Stack,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import AppFooter from "../AppLayout/AppFooter";
import Env from "../../utils/Services/Env";
import withRouter from "../Router/withRouter";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
class ProviderInfo extends React.Component {
  id;
  constructor(props) {
    super(props);
    this.state = {
      totalData: {},
      organizations: [],
      locations: [],
      clinicalAreas: [],
      filteredResults: [],
      serviceData: [],
      data1: [],
      data2: [],
      tab: "pro-info",
      service: "",
      nearByProviders: [],
      subServiceFlag: false,
      inputValue: "",
      suggestions: [],
      listProviders: [],
      showList: false,
    };
    // this.handleNavigation = this.handleNavigation.bind(this);
  }

  handleInputChange = (e) => {
    //   const inputValue = e.target.value;
    //   const filteredSuggestions = [];

    //   // Filtering based on service names
    //   const filteredServices = this.state.serviceData.filter(service =>
    //     service.name.toLowerCase().includes(inputValue.toLowerCase())
    //   );
    //   filteredSuggestions.push(...filteredServices);

    //   // Filtering based on sub-service names
    //   this.state.serviceData.forEach(service => {
    //     const filteredSubServices = service.subService.filter(subService =>
    //       subService.name.toLowerCase().includes(inputValue.toLowerCase())
    //     );
    //     filteredSuggestions.push(...filteredSubServices.map(subService => ({
    //       ...subService,
    //       serviceName: service.name // Include parent service name for identification
    //     })));
    //   });
    //  console.log("filteredSuggestions", filteredSuggestions);
    //   this.setState({
    //     inputValue,
    //     suggestions: filteredSuggestions
    //   });
    const inputValue = e.target.value;
    // this.state.showList = true;
    this.setState({
      showList: true
    })
    const filteredResults = this.state.serviceData.reduce((acc, service) => {
      const filteredSubServices = service.subService.filter((subService) =>
        subService.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      return [...acc, ...filteredSubServices];
    }, []);
    this.setState({
      inputValue: inputValue,
      filteredResults: inputValue.length >= 2 ? filteredResults : [],
    });
  };

  componentDidMount() {
    this.getData();
    this.initialLoadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.id !== prevProps.params.id) {
      this.setState({
        totalData: null,
        nearByProviders: [],
      });

      this.getData();
    }
  }

  initialLoadData = async () => {
    Env.get(`provider/services?locationId=${this.props.params.id}`)
      .then((res) => {
        const data = res.data.services;

        let data1 = data?.map((d) => d.name);
        this.setState({ data1: data1, serviceData: data });
      })
      .catch((err) => console.log(err));
  };

  // handleNavigation(service) {
  //   // const url = `http://64.227.181.23/provider/providerListing?q=&location=10003&pageSize=&service=${service}&sortBy=`;
  //   const url = `https://hcddemo.site/provider/providerListing?q=&location=10003&pageSize=&service=${service}&sortBy=`;
  //   // const url = `http://localhost:3000/provider/providerListing?q=&location=&pageSize=&service=${service}&sortBy=`;
  //   window.location.href = url;
  // }

  async getData() {
    Env.get(`provider/info?uuid=${this.props.params.id}`)
      .then((res) => {
        const data = res.data;
        this.setState({ totalData: data }, () => {
          Env.get(
            `provider/nearbyproviders?location=${this.state.totalData.latitude},${this.state.totalData.longitude}`
          )
            .then((res) => {
              const data = res.data;
              this.setState({ nearByProviders: data.nearbyproviders });
            })
            .catch((err) => this.props.navigate("*"));
        });
      })
      .catch((err) => this.props.navigate("*"));
  }

  subServiceHandle(data) {
    let data2 = this.state.serviceData.filter((item) => item.name === data);

    this.setState({
      data2: data2[0].subService,
      service: data,
      subServiceFlag: true,
    });
  }

  filteredServiceList = () => {
    return (
      <>
        {this.state.filteredResults.length === 0 && (
          <p
            style={{
              color: "#02363D",
              paddingLeft: "20px",
              marginBottom: "6px",
              fontSize: "20px",
              fontWeight: "800",
            }}
          >
            No Services
          </p>
        )}
        {this.state.filteredResults.length > 0 &&
          this.state.filteredResults?.map((data, i) => (
            <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
              <Chip
                className="chip-service"
                label={data.name}
                variant="outlined"
                // onClick={() => this.handleNavigation(data.name)}
              />
            </Grid>
          ))}
      </>
    );
  };
  serviceList = () => {
    return (
      <>
        {this.state.data1.length === 0 && (
          <p
            style={{
              color: "#02363D",
              paddingLeft: "20px",
              marginBottom: "6px",
              fontSize: "20px",
              fontWeight: "800",
            }}
          >
            No Services
          </p>
        )}
        {this.state.data1.length > 0 &&
          this.state.data1?.map((data, i) => (
            <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
              <Chip
                className="chip-service"
                label={data}
                variant="outlined"
                onClick={() => this.subServiceHandle(data)}
              />
            </Grid>
          ))}
      </>
    );
  };

  subServiceList = () => {
    return (
      <>
        {this.state.data2?.map((data, i) => (
          <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
            <Chip
              className="chip-service"
              label={data.name}
              variant="outlined"
              // onClick={() => this.handleNavigation(data.name)}
            />
          </Grid>
        ))}
      </>
    );
  };

  serviceChangeHandler = () => {
    this.setState({
      tab: "pro-service",
      service: null,
    });
  };

  handleListItemClick = (optionName) => {
    this.setState({ inputValue: optionName, showList: false });
  };

  render() {
    const { inputValue } = this.state;
    if (!this.state.totalData || this.state.nearByProviders.length === 0) {
      return (
        <>
          <AppTitle value={false} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              alignItems: "center",
            }}
          >
            <CircularProgress color="success" />
          </Box>
        </>
      );
    }
    return (
      <>
        <AppTitle value={false} />
        <Box sx={{ backgroundColor: "#F5F5F5" }}>
          <AddressMap
            totalData={this.state.totalData}
            nearByProviders={this.state.nearByProviders}
          />
        </Box>
        <Container className="container-common" sx={{ padding: "30px 0px" }}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={6}
            className="info-buttons"
          >
            <button
              onClick={() => this.setState({ tab: "pro-info" })}
              className={this.state.tab === "pro-info" ? "active" : ""}
            >
              Provider Info
            </button>
            {this.state.data1.length > 0 && <button
           
           onClick={this.serviceChangeHandler}
           className={this.state.tab === "pro-service" ? "active" : ""}
         >
           Provider Service
         </button>}
          </Stack>
          <Box
            sx={{
              marginTop: "30px",
              padding: { xs: "25px", lg: "40px 44px" },
              boxSizing: "border-box",
              background: "rgba(69, 167, 54, 0.05)",
              boxShadow: "0px 1px 24px 0px rgba(0, 0, 0, 0.10)",
            }}
          >
            {this.state.tab === "pro-info" && (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    className="provinfo-title"
                  >
                    Provider Info
                  </Typography>
                  <table className="Provider-info__table">
                    <tbody>
                      <tr>
                        <td>Name</td>

                        <td>{this.state.totalData.providerName}</td>
                      </tr>
                      <tr>
                        <td>Address</td>

                        <td>{this.state.totalData.address}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td
                          style={{
                            color: this.state.totalData.phone
                              ? "rgb(69, 167, 54)"
                              : null,
                            textDecoration: this.state.totalData.phone
                              ? "underline"
                              : null,
                          }}
                        >
                          {this.state.totalData.phone
                            ? this.state.totalData.phone
                            : "Not Available"}
                        </td>
                      </tr>
                      <tr>
                        <td>Medical Provider ID</td>
                        <td>
                          {this.state.totalData.medicationProviderId
                            ? this.state.totalData.medicationProviderId
                            : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>National Provider ID(NPI)</td>
                        <td>
                          {this.state.totalData.npi
                            ? this.state.totalData.npi
                            : "ID not available"}
                        </td>
                      </tr>
                      <tr>
                        <td>website</td>
                        {this.state.totalData.website ? (
                          <Link
                            to={this.state.totalData.website}
                            target="_blank"
                          >
                            {" "}
                            <td
                              style={{
                                color: "rgb(69, 167, 54)",
                                textDecoration: "underline",
                              }}
                            >
                              Visit
                            </td>{" "}
                          </Link>
                        ) : (
                          <td>Not Available</td>
                        )}
                      </tr>
                      <tr>
                        <td>Provider Type</td>
                        <td>
                          {this.state.totalData.providerType
                            ? this.state.totalData.providerType
                            : "Data Not Found"}
                        </td>
                      </tr>
                      <tr>
                        <td>Beds</td>
                        <td>
                          {this.state.totalData.beds
                            ? this.state.totalData.beds
                            : "-"}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
                <Grid item xs={12} lg={6}>
                {this.state.totalData.clinicalService && (<Typography
                    variant="h4"
                    gutterBottom
                    className="provinfo-title"
                  >
                    Clinical Services
                  </Typography>)}
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "14px" }}>
                    {this.state.totalData.clinicalService &&
                      this.state.totalData.clinicalService.map((data, i) => (
                        <Chip
                          sx={{
                            color: "#858585",
                            fontSize: "10px",
                            border: "1px solid #858585",
                          }}
                          key={i}
                          label={data}
                          variant="outlined"
                        />
                      ))}

                    {/* {!this.state.totalData.clinicalService && (
                      <p
                        style={{
                          color: "#02363D",
                          paddingLeft: "20px",
                          marginBottom: "6px",
                          fontSize: "20px",
                          fontWeight: "800",
                        }}
                      >
                        No clinical Services
                      </p>
                    )} */}
                  </Box>
                </Grid>
              </Grid>
            )}
            {this.state.tab === "pro-service" && (
              <Box>
                <Typography variant="h4" className="provinfo-title">
                  Provider Service
                </Typography>
                <Stack
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    margin: "1.5rem 0rem",
                    display: { xs: "block", md: "flex" },
                    flexDirection: { xs: "column", md: "row" },
                  }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontSize: "20px",
                      color: "#212220",
                    }}
                  >
                    <span
                      style={{
                        cursor: this.state.service ? "pointer" : "default",
                      }}
                      onClick={() =>
                        this.setState({ subServiceFlag: false, service: null })
                      }
                    >
                      All Services
                    </span>
                    {this.state.service && (
                      <span style={{ margin: "0px 12px", color: "#D9DED9" }}>
                        /
                      </span>
                    )}
                    {this.state.service && (
                      <span style={{ color: "#45A736" }}>
                        {this.state.service}
                      </span>
                    )}
                  </Typography>
                  <div style={{ position: "relative" }}>
                    <input
                      idz
                      className="input-service"
                      style={{
                        border: "2px solid #DADADA",
                        borderRadius: "8px",
                        padding: "3px 8px",
                        fontSize: "16px",
                      }}
                      onChange={this.handleInputChange}
                      value={inputValue}
                      placeholder="Enter service name or code..."
                      type="text"
                    />
                    {this.state.showList &&
                    this.state.filteredResults &&
                    this.state.filteredResults.length > 0 ? (
                      <List className="procedure-list">
                        {this.state.filteredResults.map((option, index) => (
                          <ListItem
                            key={index}
                            button
                            value={option.name}
                            // onClick={() => this.handleNavigation(option.name)}
                            onClick={() =>
                              this.handleListItemClick(option.name)
                            }
                          >
                            <ListItemText primary={option.name} />
                          </ListItem>
                        ))}
                      </List>
                    ) : this.state.filteredResults &&
                      this.state.filteredResults.length === 0 &&
                      inputValue.length > 2 ? (
                      <List className="procedure-list">
                        <ListItem button>
                          <ListItemText>no data found</ListItemText>
                        </ListItem>
                      </List>
                    ) : null}
                  </div>
                </Stack>
                {}
                {this.state.inputValue.length < 2 ? (
                  <Grid container spacing={2}>
                    {!this.state.subServiceFlag && this.serviceList()}
                    {this.state.subServiceFlag && this.subServiceList()}
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    {this.filteredServiceList()}
                  </Grid>
                )}
              </Box>
            )}
          </Box>
        </Container>
        <AppFooter value={false} />
      </>
    );
  }
}

export default withRouter(ProviderInfo);
