/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AppTitle from "../AppLayout/AppTitle";
import AppFooter from "../AppLayout/AppFooter";
import DoctorBackground from "../../Assets/Static/Maskgroup.png";
// import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, TextField } from "@mui/material";
// import Textarea from "@mui/joy/Textarea";
import { Container } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DoctorProfile from "../../Assets/Static/cont-img.svg";
import { apiUrl } from "../../utils/Services/Env";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  // const [subject, setSubject] = useState(null);
  let location = useLocation();
  const { state } = location;
  React.useEffect(() => {
    state?.topic?setFormData({
      subject: state.topic,
    }) : setFormData({});
  }, []);
  const [openAlert, setOpenAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    contactNumber:"",
    subject:"",
  });
  const [errors, setErrors] = useState({
    email: '',
    name: '',
    message: '',
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({});
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation rules
    let errors = {};
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    if (!formData.message) {
      errors.message = 'Message is required';
    }
    if (!formData.name) {
      errors.name = 'Name is required';
    }
    // Set errors
    setErrors(errors);

    // If no errors, submit form
    if (Object.keys(errors).length === 0) {
      try {
        const results = await axios.post(`${apiUrl}/contactdetials`, formData);
 
        if (results.data.status === 200) {
          setMessage(results.data.message);
          setOpenAlert(true);
          setSuccess(true);
          // Reload the screen
          setFormData({
            name: "",
            email: "",
            message: "",
            contactNumber:"",
            subject:"",
          });


        } else {
          setMessage(results.data.message);
          setOpenAlert(true);
          setSuccess(false);
        }
      } catch (error) {
        setMessage(error.response.data.message);
        setOpenAlert(true);
        setSuccess(false);
      }
    }
  }

  const handleEnter =(e) => {
    if (e.key === 'Enter') {
      // Trigger button click event
      document.getElementById('contact').click();
    }
  }

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openAlert} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={success === true ? "success" : "error"}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {`${message}`}
        </Alert>
      </Snackbar>
      <AppTitle value={false} />
      <AppBar
        style={{
          backgroundImage: `url(${DoctorBackground})`,
          backgroundSize: "cover",
          width: "100%",
          padding: "0.5rem 0rem 0rem",
          position: "relative",
          boxShadow: "none",
          marginTop: "80px",
          zIndex: "-1",
        }}
      >
        <Container className="container-common">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            height="500px"
            sx={{ fontWeight: "900", fontSize: '36px', letterSpacing: '2px' }}
          >
            CONTACT US
          </Grid>
        </Container>
      </AppBar>

      {/* <h1 className="make-better">
        What Makes Us Better
      </h1> */}

      {/* <Grid container spacing={0} style={{ margin: 0 }}>
        <Grid item xs={12} md={1} lg={4}></Grid>
        <Grid item xs={12} md={10} lg={4}>
          <p style={{
            color: "grey",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            textAlign: "center",
            padding:"16px"
          }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Vivamus eu
            lacus ex. Class aptent taciti sociosqu ad litoratorquent per conubia
            nostra, per inceptos ipsum dolor sit amet.
          </p>
        </Grid>
        <Grid item xs={12} md={1} lg={4}></Grid>
      </Grid> */}

      <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center" , width: "100%", margin: "0rem" , marginBottom:"2rem"}}>

        <Grid item xs={12} lg={5} sx={{ height: "100%" }} className="doctprofile">
          <img
            style={{ objectFit: "contain", width: "100%", height: '100%' }}
            src={DoctorProfile}
            alt=""
          ></img>
        </Grid>
        <Grid item xs={12} lg={5} sx={{ height: "100%" , padding:"16px !important"}}>
          <p className="get-touch">
            Get In Touch
          </p>
          <p
            style={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "20.16px",
              textAlign: "center",
              color: "gray"
            }}
          >
            If you need any help, please contact us or send us an email or go to
            our forum. We are sure that you can receive our reply as soon as
            possible.
          </p>
          <Grid container spacing={2} style={{ width: "100%", marginTop: "20px", marginLeft:"0px" }} className="contact-text">
            <Grid item xs={12} md={6}>
              <p style={{ margin: 0 }}>Name *</p>
              <TextField
                fullWidth
                margin="normal"
                placeholder="Enter Name"
                size="small"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onKeyDown={handleEnter}
                error={!!errors.name}
                helperText={errors.name}
                InputLabelProps={{ shrink: true }}
              />
              {/* <p style={{ margin: 0 }}>Subject</p> */}
              {/* <TextField
                fullWidth
                margin="normal"
                placeholder="Enter Subject"
                size="small"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                onKeyDown={handleEnter}
                InputLabelProps={{ shrink: true }}
              /> */}
            </Grid>
            <Grid item xs={12} md={6} sx={{paddingTop:"16px !important"}}>
              <p style={{ margin: 0 }}>Contact Number</p>
              <TextField
                fullWidth
                margin="normal"
                placeholder="Enter Contact Number"
                size="small"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                onKeyDown={handleEnter}
                InputLabelProps={{ shrink: true }}

              />
              {/* <p style={{ margin: 0 }}>Email *</p>
              <TextField
                fullWidth
                margin="normal"
                placeholder="Enter Email"
                size="small"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onKeyDown={handleEnter}
                error={!!errors.email}
                helperText={errors.email}
                InputLabelProps={{ shrink: true }}
              /> */}
            </Grid>
            <Grid item xs={12} md={6} >
              <p style={{ margin: 0 }}>Subject</p>
              <TextField
                fullWidth
                margin="normal"
                placeholder="Enter Subject"
                size="small"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                onKeyDown={handleEnter}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>              
              <p style={{ margin: 0 }}>Email *</p>
              <TextField
                fullWidth
                margin="normal"
                placeholder="Enter Email"
                size="small"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onKeyDown={handleEnter}
                error={!!errors.email}
                helperText={errors.email}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sx={{padding:"16px !important"}} className="message-textarea">
              <p style={{ margin: 0 }}>Message *</p>
              <TextField
                fullWidth
                margin="normal"
                placeholder="Enter Message"
                size="small"
                name="message"
                multiline
                rows={5}
                value={formData.message}
                onChange={handleChange}
                error={!!errors.message}
                helperText={errors.message}
                InputLabelProps={{ shrink: true }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Button
                  id="contact"
                  style={{
                    backgroundColor: "rgb(69, 167, 54)",
                    color: "white",
                    width: "148px",
                    height: "43.31px",
                    borderRadius: "8.53px",
                    fontSize: "15px",
                    fontWeight: 600,
                    lineHeight: "21.31px",
                    textAlign: "center",
                    textTransform: "capitalize"
                  }}
                  onClick={handleSubmit}
                >
                  Contact Us
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <AppFooter value={false} />
    </>
  );
};

export default ContactUs;