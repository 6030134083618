/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import "../Assets/css/Home.css";
import { useNavigate } from "react-router-dom";
import HomeWhiteBanner from "../Assets/Static/HomeWhiteBanner.png";
import { Grid, Box, Button, Skeleton } from "@mui/material";
import FooterImage from "../Assets/Static/FooterImage.png";
// import bgImage from "../Assets/Static/bgImage.png";
import VerifiedIcon from "@mui/icons-material/Verified";
import Env from "../utils/Services/Env";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "./Hooks/useDebounce";
import { scode } from "./reducer/action";

const CardGrid = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [procedure, setProcedure] = useState([]);
  const [provider, setProvider] = useState([]);
  const [payer, setPayer] = useState([]);
  const [loadingProcedure, setLoadingProcedure] = useState(true);
  const [loadingProvider, setLoadingProvider] = useState(true);
  const [loadingPayer, setLoadingPayer] = useState(true);

  const searchLocation = useSelector((state) => state.searchLoc);
  // const initialLocation = useSelector((state) => state.initialLoc);
  // const debouncedSearchLocation = useDebounce(initialLocation, 600);
  const debouncedSearchLocation = useDebounce(searchLocation, 600);

  useEffect(() => {
    if (debouncedSearchLocation) {
      providerValue(debouncedSearchLocation);
      procedureValue(debouncedSearchLocation);
      payerValue(debouncedSearchLocation);
    }
  }, [debouncedSearchLocation]);

  const payerValue = (location) => {
    if (searchLocation !== undefined) {
      setLoadingPayer(true);
      const payerVal = Env.get(`payer/popular?location=${location}`);

      payerVal.then(
        (response) => {
          const data = response.data;
          setPayer(data);
          setLoadingPayer(false);
        },
        (error) => {
          console.error(error);
          setLoadingPayer(false);
        }
      );
    }
  };

  const providerValue = (location) => {
    if (searchLocation !== undefined) {
      setLoadingProvider(true);
      const providerVal = Env.get(`provider/popular?location=${location}`);

      providerVal.then(
        (response) => {
          setProvider([]);
          const data = response.data;
          setProvider(data);
          setLoadingProvider(false);
        },
        (error) => {
          console.error(error);
          setLoadingProvider(false);
        }
      );
    }
  };

  const procedureValue = (location) => {
    if (searchLocation !== undefined) {
      setLoadingProcedure(true);
      const procedureVal = Env.get(`procedure/popular?location=${location}`);

      procedureVal.then(
        (response) => {
          const data = response.data;
          setProcedure(data);
          setLoadingProcedure(false);
        },
        (error) => {
          console.error(error);
          setLoadingProcedure(false);
        }
      );
    }
  };

  const renderSkeletons = (count, type) => {
    return Array.from(new Array(count)).map((_, index) => (
      <Grid
        key={index}
        item
        lg={4}
        md={4}
        sm={12}
        xs={12}
        className="list-name"
      >
        <div
          className="brower-right"
          style={{
            paddingTop: "15px",
            paddingBottom: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {type === "procedure" ? (
            <>
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="60%" />
              <Skeleton variant="text" width="40%" />
            </>
          ) : (
            <>
              <Skeleton variant="text" width="80%" />
              <Skeleton variant="text" width="40%" />
            </>
          )}
        </div>
      </Grid>
    ));
  };

  return (
    <>
      <Box className="home-page">
        <Container className="container-common">
          <Grid container sx={{ position: "relative", margin: "4rem 0rem" }}>
            <Grid item lg={3} md={12} sm={12} xs={12}>
              <div>
                <p className="browse-procedure">
                  Browse <br /> by Procedure
                </p>
              </div>
              <div>
                <p className="browse-subtext">
                  Search by entering procedure name or CPT code
                </p>
              </div>
              <div>
                <Button
                  className="view-more"
                  onClick={() => navigate("/procedures")}
                >
                  View More <span style={{ marginLeft: "6px" }}>&gt;</span>
                </Button>
              </div>
            </Grid>
            <Grid item lg={9} md={12} sm={12} xs={12}>
              <Grid container spacing={2} sx={{ width: "100%", margin: "0px" }}>
                {loadingProcedure
                  ? renderSkeletons(3, "procedure")
                  : procedure?.popularServices?.map((service) => (
                      <Grid
                        key={service.uuid}
                        item
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        className="list-name"
                        onClick={() => {
                          dispatch(scode(service.name));
                          navigate(`/provider/providerListing`);
                        }}
                      >
                        <div className="brower-right">
                          <p className="service-name">{service.name}</p>
                          <p className="service-avgPrice">
                            $ {service.avgPrice}
                          </p>
                          <p className="service-CashPrice">Avg. Cash Price</p>
                        </div>
                      </Grid>
                    ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Box
          sx={{
            background: "#fafafa",
            padding: "4rem 0rem",
            backgroundImage: `url(${HomeWhiteBanner})`,
          }}
        >
          <Container className="container-common">
            <Grid container>
              <Grid item lg={3} md={12} sm={12} xs={12}>
                <div>
                  <p className="browse-procedure">
                    Browse <br /> by Provider
                  </p>
                </div>
                <div>
                  <p className="browse-subtext">
                    Search by the entering facility name
                  </p>
                </div>
                <div>
                  <Button
                    className="view-more"
                    onClick={() => navigate("/providers")}
                  >
                    View More <span style={{ marginLeft: "6px" }}>&gt;</span>
                  </Button>
                </div>
              </Grid>
              <Grid item lg={9} md={12} sm={12} xs={12}>
                <Grid
                  container
                  spacing={2}
                  sx={{ width: "100%", margin: "0px" }}
                >
                  {loadingProvider
                    ? renderSkeletons(3, "provider")
                    : provider?.popularProviders?.map((prov, index) => (
                        <Grid
                          key={index}
                          item
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className="list-name"
                          onClick={() => navigate(`/providerinfo/${prov.uuid}`)}
                        >
                          <div
                            className="brower-right"
                            style={{
                              paddingTop: "15px",
                              paddingBottom: "25px",
                            }}
                          >
                            <div className="image-container">
                              {/* {prov.logo_url === "" || prov.logo_url === null ? (
                              <>
                                <img
                                  src={bgImage}
                                  alt="bgImage "
                                  className="overlay-image"
                                />
                                <p className="overlay-letter">{prov.name[0]}</p>
                              </>
                            ) : (
                              <img
                                src={prov.logo_url}
                                alt={prov.name}
                                className="overlay-image"
                              />
                            )} */}
                            </div>
                            <div>
                              <p className="prov-name">
                                {prov.name}
                                {prov.verified ? (
                                  <VerifiedIcon
                                    sx={{
                                      textAlign: "center",
                                      color: "#45a736",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            <div>
                              <p className="prov-location">{prov.location}</p>
                            </div>
                          </div>
                        </Grid>
                      ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box
          sx={{
            padding: "4rem 0rem",
            paddingBottom: "6rem",
            backgroundImage: `url(${HomeWhiteBanner})`,
          }}
        >
          <Container className="container-common">
            <Grid container>
              <Grid item lg={3} md={12} sm={12} xs={12}>
                <div>
                  <p className="browse-procedure">
                    Browse <br /> by Payers
                  </p>
                </div>
                <div>
                  <p className="browse-subtext">
                    Search by entering insurance health plan
                  </p>
                </div>
                <div>
                  <Button
                    className="view-more"
                    onClick={() => navigate("/payers")}
                  >
                    View More <span style={{ marginLeft: "6px" }}>&gt;</span>
                  </Button>
                </div>
              </Grid>
              <Grid item lg={9} md={12} sm={12} xs={12}>
                <Grid
                  container
                  spacing={2}
                  sx={{ width: "100%", margin: "0px" }}
                >
                  {loadingPayer
                    ? renderSkeletons(3, "payer")
                    : payer.popularPayers?.map((payer) => (
                        <Grid
                          key={payer.uuid}
                          item
                          lg={4}
                          md={4}
                          sm={12}
                          xs={12}
                          className="list-name"
                          onClick={() =>
                            navigate(
                              `/payer/info/${payer.name.toLowerCase()}`,
                              { state: payer.location }
                            )
                          }
                        >
                          <div
                            className="brower-right"
                            style={{
                              paddingTop: "15px",
                              paddingBottom: "35px",
                            }}
                          >
                            <div className="image-container"></div>
                            <div>
                              <p className="prov-name">{payer.name}</p>
                            </div>
                            <div>
                              <p className="prov-location">{payer.location}</p>
                            </div>
                          </div>
                        </Grid>
                      ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>

      <Container className="container-common home-footer">
        <div className="ads-container">
          <img
            src={FooterImage}
            alt="FooterImage"
            className="footer-image"
            style={{ position: "absolute", top: "-83px" }}
          />
          <div className="ads-text">
            <p
              className="ads-subtext1"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/contactus")}
            >
              GET IN TOUCH
            </p>
            <p
              className="ads-subtext2"
              style={{ fontSize: "40px", lineHeight: "50px" }}
            >
              Consumers need a cost estimation tool to help them make informed
              financial decisions about elective medical procedures.
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default CardGrid;
