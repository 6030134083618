const initialState = {
  isLogged: false,
  pid: null,
  uid: null,
  token: null,
  location: null,
  memberId: null,
  payerName: null,
  payerHeaderInfo: {},
  searchLoc:null,
  initialLoc:null,
};

const appReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "IS_LOGGED_IN":
      return {
        ...state,
        isLogged: action.payload,
      };

    case "PREFERENCE_TYPE":
      return {
        ...state,
        pid: action.payload,
      };

    case "USER_TYPE":
      return {
        ...state,
        uid: action.payload,
      };

    case "TOKEN_TYPE":
      return {
        ...state,
        token: action.payload,
      };

    case "LOCATION":
      return {
        ...state,
        location: action.payload,
      };

    case "USER_NAME":
      return {
        ...state,
        userName: action.payload,
      };

    case "MEMBER_ID":
      return {
        ...state,
        memberId: action.payload,
      };

    case "SPECIALTY":
      return {
        ...state,
        specialty: action.payload,
      };

    case "PAYER_Name":
      return {
        ...state,
        payerName: action.payload,
      };

      case "INITIAL_LOCATION":
      return {
        ...state,
        initialLoc: action.payload,
      };

    case "SEARCH_LOCATION":
      return {
        ...state,
        searchLoc: action.payload,
      };

    case "SEARCH_PROCEDURE":
      return {
        ...state,
        scode: action.payload,
      };

    case "PAYER_HEADER_INFO":
      return {
        ...state,
        payerHeaderInfo: action.payload,
      };
    case "CLEAR_STORE":
      return {
        state: initialState,
      };

    default:
      return state;
  }
};

export default appReducer;
