import React, { useState } from "react";
import AppTitle from "../AppLayout/AppTitle";
import AppBar from "@mui/material/AppBar";
import AppFooter from "../AppLayout/AppFooter";
import About from "../../Assets/Static/About.jpg";
// import Overview from "../../Assets/Static/div.overview-image.png"; 
import Mission from "../../Assets/Static/Mission.png";
import OurStory from "../../Assets/Static/OurStory.png";
// import JoinOurTeam from "../../Assets/Static/joinOurTeam.png";
// import Frame from "../../Assets/Static/Frame.png";
// import Health from "../../Assets/Static/healthcare-health 1.png";
// import Solidarity from "../../Assets/Static/solidarity-solidarity 1.png";
// import Group from "../../Assets/Static/Group.png";
// import Group2 from "../../Assets/Static/Group 162918.png";
// import Hospital from "../../Assets/Static/hospital 2.png";
// import Doctor from "../../Assets/Static/doc 1.png";
// import Team1 from "../../Assets/Static/team-1.jpg.png";
// import Team2 from "../../Assets/Static/team-2.jpg.png";
// import Team3 from "../../Assets/Static/team-3.jpg.png";
// import Team4 from "../../Assets/Static/team-4.jpg.png";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import Typography from "@mui/material/Typography";
// import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
// import axios from "axios";
// import { apiUrl } from "../../utils/Services/Env";
import "../../Assets/css/About.css"

const AboutUs = () => {
  const [openAlert, setOpenAlert] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  // const inputFileRef = React.useRef(null);
  // const handleCameraClick = () => {
  //   inputFileRef.current.click();
  // };
  // const handleUploadClick = async (event) => {
  //   try {
  //     const file = event.target.files[0];
  //     const formData = new FormData();
  //     formData.append("resume", file);
  //     const results = await axios.post(`${apiUrl}user/resume`, formData);
  //     if (results.data.status === 200) {
  //       setOpenAlert(true);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //     // setMessage(error.response.data.message);
  //     // setOpenAlert(true);
  //     // setSuccess(false);
  //   }
  // };
  // const navigate = useNavigate();
  return (
    <>
      <AppTitle value={false} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ marginTop: "80px" }}
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={"success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {`Resume uploaded successfully`}
        </Alert>
      </Snackbar>
      <AppBar
        style={{
          backgroundImage: `url(${About})`,
          backgroundSize: "cover",
          width: "100%",
          height: "500px",
          padding: "0.5rem 0rem 0rem",
          position: "relative",
          boxShadow: "none",
          marginTop: "80px",
          zIndex: "-1",
          backgroundPosition: "center",
        }}
      >
        <Container className="container-common">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            height="500px"
            sx={{ fontWeight: "900", fontSize: "36px", letterSpacing: "2px" }}
          >
            ABOUT US
          </Grid>
        </Container>
      </AppBar>

      <Container className="container-common">
        <Box sx={{ flexGrow: 1, marginTop: "60px", marginBottom: "60px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <h2
                style={{
                  fontWeight: "900",
                  fontSize: "38px",
                  letterSpacing: "2px",
                }}
              >
                About Health Care Dollar
              </h2>
              <Grid container className="about-div">
              <Grid item lg={6} xs={12} md={12} >
              <h3
                style={{
                  fontWeight: "900",
                  fontSize: "38px",
                  letterSpacing: "2px",
                }}
              >
                Mission
              </h3>
              <p className="aboutUs-para">
              Our mission is to eliminate surprise healthcare charges for outpatient procedures within America. 
              </p>
              </Grid>
              <Grid item lg={6}>
              <img className="aboutUsImg" src={Mission} alt="mission" />
              </Grid>
              </Grid>

              <Grid container className="about-div">
             
              <Grid item lg={6}>
              <img className="aboutUsImg" src={OurStory} alt="OurStory" />
              </Grid>
              <Grid item lg={6} xs={12} md={12} className="about-our-story" >
              <h3
                style={{
                  fontWeight: "900",
                  fontSize: "38px",
                  letterSpacing: "2px",
                }}
              >
                Our Story
              </h3>
              <p className="aboutUs-para">
              The founder and CEO, Elena Givens has always been passionate about healthcare and its impact on people's lives. As a patient and a Monsanto scholar growing up in St. Louis, MO, she was exposed to the intricacies of the healthcare system at a very young age. As she pursued her education in Health Information Management at Saint Louis University and a Master's in Health System administration at Georgetown University, she became more aware of the gap that prevails in the healthcare industry - the lack of transparency and affordability. This realization sparked her interest in starting a company that could help people focus on the transparency and affordability
              </p>
              </Grid>
              </Grid>

              {/* <Grid container className="about-div">
              <Grid item lg={6} xs={12} md={12} >
              <h3
                style={{
                  fontWeight: "900",
                  fontSize: "38px",
                  letterSpacing: "2px",
                }}
              >
               Join Us
              </h3>
              
              <p className="aboutUs-para">
              At Healthcare Dollar, we are making people become aware of  healthcare services accessibility and affordability to  everyone, regardless of their financial status. We strive on providing opportunity to become a difference makers and improving  people's lives.
              </p>
              <p
                className="aboutUs-para"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Want to join our team,
              </p>
              <p
                className="aboutUs-para"
                style={{ display: "flex", justifyContent: "center" }}
              >
                Submit your Resume here
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  className="contactUs-btn"
                  style={{ cursor: "pointer" }}
                  onClick={handleCameraClick}
                >
                  Submit
                  <input
                    // accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                    ref={inputFileRef}
                  />
                </button>
              </div>
              </Grid>
              <Grid item lg={6}>
              <img className="aboutUsImg" src={JoinOurTeam} alt="JoinOurTeam"  />
              </Grid>
              </Grid> */}
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6}>
              <img
                src={Overview}
                alt="overview"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Grid> */}
          </Grid>
        </Box>

        {/* <h2 style={{ textAlign: "center" }}>Our Values</h2> */}
        {/* <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <img src={Frame} alt="x" />
                </Grid>
                <Grid item xs={9}>
                  <span className="grid-frames">DOCTOR YOU CHOOSE</span>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <img src={Health} alt="healthCare" />
                </Grid>
                <Grid item xs={9}>
                  <span className="grid-frames">YOUR HEALTHCARE</span>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <img src={Solidarity} alt="solidarity" />
                </Grid>
                <Grid item xs={9}>
                  <span className="grid-frames">ALWAYS THERE FOR YOU</span>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </p>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <img src={Group} alt="group" />
                </Grid>
                <Grid item xs={9}>
                  <span className="grid-frames">NURSING STAFF</span>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <img src={Group2} alt="group2" />
                </Grid>
                <Grid item xs={9}>
                  <span className="grid-frames">EMERGENCY SERVICE</span>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <img src={Hospital} alt="hospital" />
                </Grid>
                <Grid item xs={9}>
                  <span className="grid-frames">PREMIUM FACILITY</span>
                  <p>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box> */}
      </Container>

      {/* <Box sx={{ flexGrow: 1, backgroundColor: "#45A736" }}>
        <Container className="container-common" style={{ marginTop: "60px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={4} className="doctor-img-sec">
              <img
                src={Doctor}
                alt="doctor"
                style={{ width: "100%", height: "100%" }}
                className="doctor-img"
              />
            </Grid>
            <Grid item xs={6} md={6} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h1
                    style={{
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontSize: "50px",
                    }}
                  >
                    12
                  </h1>
                  <h3 style={{ color: "#FFFFFF", textAlign: "center" }}>
                    Years of service
                  </h3>
                  <p style={{ color: "#FFFFFF", textAlign: "center" }}>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h1
                    style={{
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontSize: "50px",
                    }}
                  >
                    99
                  </h1>
                  <h3 style={{ color: "#FFFFFF", textAlign: "center" }}>
                    Doctors
                  </h3>
                  <p style={{ color: "#FFFFFF", textAlign: "center" }}>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} md={6} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h1
                    style={{
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontSize: "50px",
                    }}
                  >
                    41
                  </h1>
                  <h3 style={{ color: "#FFFFFF", textAlign: "center" }}>
                    Awards
                  </h3>
                  <p style={{ color: "#FFFFFF", textAlign: "center" }}>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </p>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h1
                    style={{
                      color: "#FFFFFF",
                      textAlign: "center",
                      fontSize: "50px",
                    }}
                  >
                    800
                  </h1>
                  <h3 style={{ color: "#FFFFFF", textAlign: "center" }}>
                    Satisfied Clients
                  </h3>
                  <p style={{ color: "#FFFFFF", textAlign: "center" }}>
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box> */}

      {/* <h1
        style={{ textAlign: "center", marginTop: "40px", marginBottom: "0px" }}
      >
        Specialized Doctors
      </h1> */}
      {/* <Box sx={{ flexGrow: 1 }}>
        <Container className="container-common" style={{ marginTop: "0px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={2} md={2}></Grid>
            <Grid item xs={12} sm={8} md={8}>
              <p style={{ textAlign: "center", margin: "0px" }}>
                At Healthcare Dollar, healthcare services should be accessible
                and affordable to everyone, regardless of their financial
                status. However, rising healthcare costs pushed people towards
                financial uncertainty and even bankruptcy. Moreover, the lack of
                transparency in pricing and billing made it difficult for
                patients to make informed decisions about their healthcare. This
                is where Elena saw an opportunity to make a difference and
                improve people's lives through her business.
              </p>
            </Grid>
            <Grid item xs={12} sm={2} md={2}></Grid>
          </Grid>
        </Container>
      </Box> */}
      {/* <Container
        className="container-common"
        style={{ marginTop: "60px", marginBottom: "60px" }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Card
            style={{
              minWidth: 275,
              maxWidth: 300,
              borderRadius: 20,
              margin: "10px",
            }}
          >
            <img
              src={Team1}
              style={{ width: "100%", height: "auto" }}
              alt="Team 1"
            />
            <CardContent>
              <Typography variant="h5" component="div" textAlign="center">
                <p style={{ fontWeight: 600 }}>Dr. James Adult</p>
              </Typography>
              <Typography variant="body2" textAlign="center">
                <p style={{ color: "#45A736" }}>Cardiologist</p>
              </Typography>
            </CardContent>
          </Card>
          <Card
            style={{
              minWidth: 275,
              maxWidth: 300,
              borderRadius: 20,
              margin: "10px",
            }}
          >
            <img
              src={Team2}
              style={{ width: "100%", height: "auto" }}
              alt="Team 2"
            />
            <CardContent>
              <Typography variant="h5" component="div" textAlign="center">
                <p style={{ fontWeight: 600 }}>Dr. James Alison</p>
              </Typography>
              <Typography variant="body2" textAlign="center">
                <p style={{ color: "#45A736" }}>Medicine</p>
              </Typography>
            </CardContent>
          </Card>
          <Card
            style={{
              minWidth: 275,
              maxWidth: 300,
              borderRadius: 20,
              margin: "10px",
            }}
          >
            <img
              src={Team3}
              style={{ width: "100%", height: "auto" }}
              alt="Team 3"
            />
            <CardContent>
              <Typography variant="h5" component="div" textAlign="center">
                <p style={{ fontWeight: 600 }}>Dr. Peter Adlock</p>
              </Typography>
              <Typography variant="body2" textAlign="center">
                <p style={{ color: "#45A736" }}>Neurologist</p>
              </Typography>
            </CardContent>
          </Card>
          <Card
            style={{
              minWidth: 275,
              maxWidth: 300,
              borderRadius: 20,
              margin: "10px",
            }}
          >
            <img
              src={Team4}
              style={{ width: "100%", height: "auto" }}
              alt="Team 4"
            />
            <CardContent>
              <Typography variant="h5" component="div" textAlign="center">
                <p style={{ fontWeight: 600 }}>Dr. Jelin Alis</p>
              </Typography>
              <Typography variant="body2" textAlign="center">
                <p style={{ color: "#45A736" }}>Dermatology</p>
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Container> */}

      <AppFooter value={false} />
    </>
  );
};

export default AboutUs;
