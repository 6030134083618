import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container, Dialog } from "@mui/material";
import Mobile from "../../Assets/Static/get-phone.svg";
import Ellipse from "../../Assets/Static/Group 163355.png";
import Apple from "../../Assets/Static/apple.svg";
import Google from "../../Assets/Static/googleimg.svg";
import CloseIcon from "@mui/icons-material/Close";

const GetApp = ({ open, onClose }) => {
  const [maxWidth, setMaxWidth] = React.useState("xxl");
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={maxWidth}
      className="provide-popup getapp-popup"
    >
      <CloseIcon
        style={{ right: 20, top: 20, position: "absolute" }}
        onClick={onClose}
      />
      <Box sx={{ flexGrow: 1, margin: "60px" }}>
        <Grid container spacing={2} sx={{ justifyContent: "space-evenly" }}>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <h4
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: "30px",
              }}
            >
              <span style={{ color: "#45A736" }}>
                Empower Your Healthcare Choices
              </span>{" "}
              with Our App! Find Providers, Procedures, and Payers with Ease.
            </h4>
            <p
              style={{
                textAlign: "center",
                fontWeight: 400,
                fontSize: "48px",
                margin: 0,
              }}
            >
              DOWNLOAD
            </p>
            <p
              style={{
                textAlign: "center",
                fontWeight: 400,
                fontSize: "50px",
                color: "#45A736",
                margin: 0,
              }}
            >
              HEALTHCARE DOLLAR APP
            </p>
            <p
              style={{
                textAlign: "center",
                fontWeight: 400,
                fontSize: "18px",
                marginBottom: "80px",
              }}
            >
              This app is a one-stop shop that provides information on elective
              procedures. With a user-friendly design and intuitive features,
              you can quickly know your medical expenses, compare procedure
              prices and tests, and even receive personalized recommendations
              for costs based on insurance. You no longer have to spend hours
              deciphering complicated medical bills or worrying about hidden
              fees. With Healthcare Dollar, LLC, you can easily stay on top of
              your healthcare budget and make informed decisions about your
              medical expenses. Don’t let the rising healthcare costs weigh you
              down – download Healthcare Dollar, LLC today and take control of
              your healthcare spending!
            </p>
            <Box>
              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "space-evenly",
                  width: "100%",
                  margin: "0px",
                }}
              >
                <Grid item xs={12} sm={12} md={5} sx={{ textAlign: "center" }}>
                  <img src={Apple} alt="Apple" className="app-img" />
                </Grid>
                <Grid item xs={12} sm={12} md={5} sx={{ textAlign: "center" }}>
                  <img src={Google} alt="Google" className="app-img" />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <img src={Mobile} alt="Mobile" className="getapp-img" />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default GetApp;
