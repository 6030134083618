import React from "react";
import AppTitle from "../AppLayout/AppTitle";
import AppFooter from "../AppLayout/AppFooter";
import comingSoon from "../../Assets/Static/comingSoon.png";

const ComingSoon = () => {
  return (
    <>
      <AppTitle value={false} />
      <div className="coming-soon">
        <img src={comingSoon} alt="comingsoon" />
      </div>
      <AppFooter value={false} />
    </>
  );
};

export default ComingSoon;
