import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Router from "./components/Router/Router";
import "./App.css";
import { Provider } from "react-redux";
import { store, persistor } from "./components/reducer/configStore";
import { PersistGate } from "redux-persist/integration/react";

export const AppPlain = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <HelmetProvider>
        <Router />
      </HelmetProvider>
    </PersistGate>
  </Provider>
);

export const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AppPlain />
    </BrowserRouter>
  );
};
