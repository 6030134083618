import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import logo from "../../Assets/Static/Logo (2).png";
import { apiUrl } from "../../utils/Services/Env";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  isLogged,
  // userPreference,
  user,
  auth,
  userName,
  // userName,
} from "../reducer/action";
import { connect } from "react-redux";

const SignupPopup = ({ open, onClose, choose }) => {
  const dispatch = useDispatch();

  const [type, setType] = useState("login");
  const [forgetPassword, setForgetPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const [showLoginPassword, setShowloginPassword] = useState(false);
  const handleToggleLoginPassword = () => {
    setShowloginPassword(!showLoginPassword);
  };
  const [loginErrors, setLoginErrors] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    name: "",
  });
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loginFormData, setLoginFormData] = useState({
    email: "",
    password: "",
  });
  const [message, setMessage] = useState("");
  const [siginpopup, setSiginpopup] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [forgetPasswordErrors, setForgetPasswordErrors] = useState({
    email: "",
  });
  const [forgetPasswordFormData, setForgetPasswordFormData] = useState({
    email: "",
  });
  const handleChangeForgetPassword = (e) => {
    const { name, value } = e.target;
    setForgetPasswordErrors({});
    setForgetPasswordFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleForgotpassword = async (e) => {
    // e.preventDefault();

    let errors = {};
    if (!forgetPasswordFormData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(forgetPasswordFormData.email)) {
      errors.email = "Email is invalid";
    }

    setForgetPasswordErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const results = await axios.post(
          `${apiUrl}auth/forgotPassword`,
          forgetPasswordFormData
        );
        if (results.data.status === 200) {
          setMessage(results.data.message);
          setForgetPasswordFormData({ email: "" });
          setType("login");
          setSuccess(true);
          setForgetPassword(false);
          onClose(results.data.message);
        } else {
          setMessage(results.data.message);
          setForgetPasswordFormData({ email: "" });
          setType("login");
          setOpenAlert(true);
          setSuccess(false);
        }
      } catch (error) {
        setMessage(error.response.data.message);
        setOpenAlert(true);
        setSuccess(false);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (e.key === "Enter") {
      document.getElementById("myButton").click();
    }
    setErrors({});
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeLogin = (e) => {
    const { name, value } = e.target;
    setLoginErrors({});
    setLoginFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSignup = async (e) => {
    // e.preventDefault();

    let errors = {};
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }
    if (!formData.firstName) {
      errors.firstName = "First Name is required";
    }

    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const results = await axios.post(`${apiUrl}auth/signup`, formData);

        if (results.data.status === 200) {
          dispatch(isLogged(true));
          dispatch(user(results.data.user.uid));
          dispatch(auth(results.data.user.stsTokenManager.refreshToken));
          dispatch(userName(results.data.user.firstName));
          setMessage(results.data.message);
          setSuccess(true);
          setFormData({});
          onClose(results.data.message);
        } else {
          setMessage(results.data.message);
          setOpenAlert(true);
          setSuccess(false);
        }
      } catch (error) {
        setMessage(error.response.data.message);
        setOpenAlert(true);
        setSuccess(false);
      }
    }
  };

  const handleSignin = async (e) => {
    e.preventDefault();

    let errors = {};
    if (!loginFormData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(loginFormData.email)) {
      errors.email = "Email is invalid";
    }
    if (!loginFormData.password) {
      errors.password = "Password is required";
    } else if (loginFormData.password.length < 8) {
      errors.password = "Password must be at least 8 characters long";
    }

    setLoginErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const results = await axios.post(`${apiUrl}auth/signin`, loginFormData);
        if (results.data.status === 200) {
          dispatch(isLogged(true));
          dispatch(user(results.data.user.uid));
          dispatch(auth(results.data.user.stsTokenManager.refreshToken));
          // dispatch(userName(formData.name));
          setMessage(results.data.message);
          setSuccess(true);
          onClose(results.data.message);
          setLoginFormData({
            email: "",
            password: "",
          })
          handleSignup();
        } else {
          setMessage(results.data.message);
          setOpenAlert(true);
          setSuccess(false);
        }
      } catch (error) {
        setMessage(error.response.data.message);
        setOpenAlert(true);
        setSuccess(false);
      }
    }
  };

  const handleSignupButton = (e) => {
    if (e.key === "Enter") {
      document.getElementById("signup").click();
    }
  };
  const handleLoginButton = (e) => {
    if (e.key === "Enter") {
      document.getElementById("login").click();
    }
  };
  const handleForgetPasswordButton = (e) => {
    if (e.key === "Enter") {
      document.getElementById("forget-password").click();
    }
  };

  const close = () => {
    onClose();
    setErrors({});
    setFormData({});
    setLoginErrors({});
    setSiginpopup(false);
    setType("login");
    setForgetPasswordErrors({});
    setForgetPassword(false);
    setLoginFormData({
      email: "",
      password: "",
    })
  };
  return (
    <Dialog open={open} onClose={close} className="provide-popup signup-page">
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={success === true ? "success" : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {`${message}`}
        </Alert>
      </Snackbar>
      <div>
        {type === "signup" ? (
          <>
            <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
              <img src={logo} alt=""></img>
            </DialogTitle>
            <DialogContent>
              <DialogTitle style={{ padding: 0, fontSize: "12px" }}>
                First Name *
              </DialogTitle>
              <TextField
                required
                fullWidth
                margin="normal"
                size="small"
                name="firstName"
                placeholder="Enter First Name"
                value={formData.name}
                onKeyDown={handleSignupButton}
                onChange={handleChange}
                error={!!errors.firstName}
                helperText={errors.firstName}
                InputLabelProps={{ shrink: true }}
              />
              <DialogTitle style={{ padding: 0, fontSize: "12px" }}>
                Last Name
              </DialogTitle>
              <TextField
                fullWidth
                margin="normal"
                size="small"
                type="lastName"
                name="lastName"
                placeholder="Enter Last Name"
                value={formData.address}
                onKeyDown={handleSignupButton}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
              <DialogTitle style={{ padding: 0, fontSize: "12px" }}>
                Email *
              </DialogTitle>
              <TextField
                fullWidth
                margin="normal"
                size="small"
                name="email"
                type="email"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleChange}
                onKeyDown={handleSignupButton}
                error={!!errors.email}
                helperText={errors.email}
                InputLabelProps={{ shrink: true }}
              />
              <DialogTitle style={{ padding: 0, fontSize: "12px" }}>
                Password *
              </DialogTitle>
              <TextField
                fullWidth
                margin="normal"
                size="small"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                value={formData.password}
                onChange={handleChange}
                onKeyDown={handleSignupButton}
                error={!!errors.password}
                helperText={errors.password}
                InputProps={{
                  shrink: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                id="signup"
                fullWidth
                onClick={handleSignup}
                style={{
                  backgroundColor: "#45A736",
                  color: "White",
                  marginBottom: "10px",
                  marginTop: "2rem",
                  height: "45px",
                  textTransform: "capitalize",
                }}
              >
                Sign Up
              </Button>
              <br></br>
              <p
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Already have an account?{" "}
                <span
                  onClick={() => setType("login")}
                  style={{
                    textDecoration: "none",
                    marginLeft: "5px",
                    color: "#45A736",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  Login
                </span>
              </p>
            </DialogContent>{" "}
          </>
        ) : type === "login" ? (
          <>
            <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
              <img src={logo} alt=""></img>
            </DialogTitle>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "20px",
              }}
            >
              Log In to Your Account
            </span>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "12px",
              }}
            >
              Welcome back please enter your details
            </span>
            <DialogContent>
              <DialogTitle style={{ padding: 0, fontSize: "15px" }}>
                Email *
              </DialogTitle>
              <TextField
                fullWidth
                margin="normal"
                placeholder="Enter Email"
                size="small"
                name="email"
                type="email"
                value={loginFormData.email}
                onChange={handleChangeLogin}
                onKeyDown={handleLoginButton}
                error={!!loginErrors.email}
                helperText={loginErrors.email}
                InputLabelProps={{ shrink: true }}
              />
              <DialogTitle style={{ padding: 0, fontSize: "15px" }}>
                Password *
              </DialogTitle>
              <TextField
                fullWidth
                margin="normal"
                placeholder="Enter Password"
                size="small"
                type={showLoginPassword ? "text" : "password"}
                name="password"
                value={loginFormData.password}
                onChange={handleChangeLogin}
                onKeyDown={handleLoginButton}
                error={!!loginErrors.password}
                helperText={loginErrors.password}
                InputProps={{
                  shrink: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleToggleLoginPassword}
                        edge="end"
                      >
                        {showLoginPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <p
                onClick={() => {
                  setType("forget-password");
                }}
                style={{
                  display: "flex",
                  justifyContent: "right",
                  margin: 0,
                  fontSize: "12px",
                  textDecoration: "none",
                  color: "black",
                  cursor: "pointer",
                }}
              >
                Forgot Password?
              </p>
              <br></br>
              <Button
                id="login"
                fullWidth
                onClick={handleSignin}
                style={{
                  backgroundColor: "#45A736",
                  color: "White",
                  marginBottom: 0,
                  marginTop: "2rem",
                  height: "45px",
                  textTransform: "capitalize",
                }}
              >
                Log In
              </Button>
              <p
                style={{
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 0,
                }}
              >
                Don't have an account yet?
                <span
                  onClick={() => {
                    setType("signup");
                  }}
                  style={{
                    textDecoration: "none",
                    color: "#45A736",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  Register Now
                </span>
              </p>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
              <img src={logo} alt=""></img>
            </DialogTitle>

            <DialogContent>
              <DialogTitle style={{ padding: 0, fontSize: "15px" }}>
                Email *
              </DialogTitle>
              <TextField
                fullWidth
                margin="normal"
                placeholder="Enter Email"
                size="small"
                name="email"
                type="email"
                value={forgetPasswordFormData.email}
                onChange={handleChangeForgetPassword}
                onKeyDown={handleForgetPasswordButton}
                error={!!forgetPasswordErrors.email}
                helperText={forgetPasswordErrors.email}
                InputLabelProps={{ shrink: true }}
              />
              <Button
                id="forget-password"
                fullWidth
                onClick={handleForgotpassword}
                style={{
                  backgroundColor: "#45A736",
                  color: "White",
                  marginBottom: "10px",
                  marginTop: "2rem",
                  height: "45px",
                  textTransform: "capitalize",
                }}
              >
                Send
              </Button>
            </DialogContent>
          </>
        )}
      </div>
    </Dialog>
  );
};

function mapDispatchToProps(state) {
  return {
    uid: state.uid,
    isLogged: state.isLogged,
  };
}

export default connect(mapDispatchToProps)(SignupPopup);
