import React, { useEffect, useState } from "react";

import AppTitle from "../AppLayout/AppTitle";
import AppFooter from "../AppLayout/AppFooter";
import "../../Assets/css/ProductPrice.css";

import {
  Box,
  CircularProgress,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Env from "../../utils/Services/Env";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";

function ProcedurePrice() {
  const [content, setContent] = useState("rate");
  const [cashPrice, setCashPrice] = useState(null);
  const [planList, setPlanList] = useState("");
  const [planId, setPlanId] = useState("");
  const [insurancePrice, setInsurancePrice] = useState();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const procedureId = params.get("procedureId");
  const locationId = params.get("locationId");

  const [insureRate, setInsureRate] = useState(0);
  const [copay, setCopay] = useState(0);
  const [deductible, setDeductible] = useState(0);
  const [coinsurance, setCoinsurance] = useState(0);
  const [estimate, setEstimate] = useState(0);
  const [companyPay, setCompanyPay] = useState(0);

  useEffect(() => {
    setEstimate(copay + deductible + coinsurance);
  }, [copay, deductible, coinsurance]);

  useEffect(() => {
    setCompanyPay(insureRate - (deductible + coinsurance));
  }, [insureRate, deductible, coinsurance]);

  useEffect(() => {
    Env.get(
      `/provider/procedure-cash-price?procedureId=${procedureId}&locationId=${locationId}`
    )
      .then((res) => {
        setCashPrice(res.data);
      })
      .catch((err) => {
        setCashPrice(err.response);
      });
    Env.get(`/provider/insurance-plans?locationId=${locationId}`)
      .then((res) => {
        setPlanList(res.data);
      })
      .catch((err) => console.log(err));
  }, [procedureId, locationId]);

  if (!cashPrice) {
    return (
      <>
        <AppTitle value={false} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <CircularProgress color="success" />
        </Box>
      </>
    );
  }

  function handlePlanChange(event) {
    Env.get(
      `/provider/procedure-insurance-price?procedureId=${procedureId}&locationId=${locationId}&planId=${event.target.value}`
    )
      .then((res) => {
        setInsurancePrice(res.data);
        setInsureRate(res.data.costAvg);
        setCompanyPay(res.data.costAvg);
      })
      .catch((err) => console.log(err));

    setPlanId(event.target.value);
  }

  function handleCopay(event) {
    if (+event.target.value < 0) {
      setCopay((pv) => {
        return 0;
      });
    } else {
      setCopay((pv) => {
        return +event.target.value;
      });
    }
  }

  function handleDeductible(event) {
   
    if (+event.target.value < 0) {
      setDeductible(0);
    } else if (+event.target.value <= insurancePrice.costAvg) {
      setDeductible(+event.target.value);
    } else {
      setDeductible(insurancePrice.costAvg);
    }
  }

  function handleCoinsurance(event) {
    if (+event.target.value < 0 || +event.target.value > 100) {
      setCoinsurance((prevValue) => {
        return 0;
      });
    } else {
      setCoinsurance((pv) => {
        return (+event.target.value / 100) * (insureRate - deductible);
      });
    }
  }

  return (
    <>
      <AppTitle value={false} />

      <Box sx={{ backgroundColor: "#F5F5F5" }}>
        <Container
          className="container-common"
          style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
        >
          {cashPrice.status !== 404 ? (
            <>
              <Typography className="ray-title">
                {cashPrice?.procedureName}
              </Typography>
              <Typography display="block" className="ray-subtitle">
                Hospital Reported Average Price for {cashPrice?.name}
              </Typography>
              <p className="sub-ray">
                The Average price that {cashPrice?.name} has reported for{" "}
                {cashPrice?.procedureName} varies depending on if you would be
                paying in cash or if you are part of an insurance plan that has
                a pre-negotiated rate. Choose whether to view cash prices or
                insurance prices to get started.
              </p>
            </>
          ) : (
            <h4>Data Not Found</h4>
          )}

          <Box sx={{ display: { xs: "block", md: "flex" } }}>
            {cashPrice.status !== 404 && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => setContent("rate")}
                  sx={{
                    fontSize: "14px",
                    border: "1px solid #45A736",
                    padding: "0px 10px 0px 0px",
                    color: content === "rate" ? "white" : "#45A736",
                    borderRadius: "10px",
                    textTransform: "none",
                    marginBottom: "1rem",
                    marginRight: "1rem",
                    backgroundColor: content === "rate" ? "#45A736" : "white",
                    "&:hover": {
                      border: "1px solid #45A736",
                      color: "#45A736",
                    },
                  }}
                >
                  <Checkbox
                    inputProps={{ "aria-label": "checkbox" }}
                    checked={content === "rate"}
                    sx={{
                      color: content !== "rate" ? "#45A736" : "#fff !important",
                    }}
                    size="small"
                  />
                  View cash rate
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => setContent("insurance")}
                  sx={{
                    fontSize: "14px",
                    border: "1px solid #45A736",
                    padding: "0px 10px 0px 0px",
                    color: content === "insurance" ? "white" : "#45A736",
                    borderRadius: "10px",
                    textTransform: "none",
                    marginBottom: "1rem",
                    backgroundColor:
                      content === "insurance" ? "#45A736" : "white",
                    "&:hover": {
                      border: "1px solid #45A736",
                      color: "#45A736",
                    },
                  }}
                >
                  <Checkbox
                    inputProps={{ "aria-label": "checkbox" }}
                    checked={content === "insurance"}
                    sx={{
                      color:
                        content !== "insurance" ? "#45A736" : "#fff !important",
                    }}
                    size="small"
                  />
                  View Insurance Rates
                </Button>
              </>
            )}
          </Box>
          {content === "insurance" && planList && (
            <>
              <Grid container spacing={2} className="userData__handle">
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-data">
                      Select Insurance Plan
                    </InputLabel>
                    <Select
                      value={planId}
                      id="demo-simple-select"
                      MenuProps={{
                        disableScrollLock: true,
                        marginThreshold: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                      className="select-userdata border_handler"
                      sx={{ width: "100%" }}
                      onChange={handlePlanChange}
                      labelId="select-data"
                    >
                      {planList?.insurancePlans.map((item, i) => (
                        <MenuItem key={i} value={item.uuid}>
                          {item.planName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <div>
                    <TextField
                      id="outlined-number1"
                      label="Remaining deductible"
                      type="number"
                      className="border_handler"
                      disabled={insurancePrice ? false : true}
                      sx={{
                        backgroundColor: "white",
                        width: "100%",
                        "&:focus": {
                          borderColor: "green",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      onChange={handleDeductible}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <div>
                    <TextField
                      id="outlined-number2"
                      label="Copay Amount"
                      type="number"
                      className="border_handler"
                      disabled={insurancePrice ? false : true}
                      sx={{ backgroundColor: "white", width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      onChange={handleCopay}
                    />
                  </div>
                </Grid>
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <div>
                    <TextField
                      id="outlined-number3"
                      label="Coinsurance Percentage"
                      type="number"
                      className="border_handler"
                      disabled={insurancePrice ? false : true}
                      sx={{ backgroundColor: "white", width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      onChange={handleCoinsurance}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </Box>

      <Box sx={{ padding: "24px 0" }}>
        <Container className="container-common">
          <div
            style={{
              borderRadius: "16px",
              background: "#FFF",
              boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.15)",
              padding: "2rem 1rem",
              textAlign: "center",
            }}
          >
            <Typography className="report-cash">
              Hospital Reported Avg. Cash Price
            </Typography>
            {content === "rate" && (
              <>
                <p
                  style={{
                    color: "#45A736",
                    fontSize: "50px",
                    margin: "20px 0px",
                    fontWeight: "500",
                  }}
                >
                  {!cashPrice.cashPriceAvailable
                    ? "Price not Available"
                    : `$${cashPrice.costAvg}`}
                </p>
              </>
            )}
            {!insurancePrice && content === "insurance" && (
              <Box
                sx={{ padding: "14px", fontSize: "18px", fontWeight: "bold" }}
              >
                Select a search plan for insurance rate
              </Box>
            )}
            {insurancePrice && content === "insurance" && (
              <>
                <table className="priceTable">
                  <tbody>
                    <tr>
                      <td style={{ width: "90%" }}>
                        Hospital Reported Insurer Rate
                      </td>
                      <td style={{ textAlign: "right", paddingRight: "15px" }}>
                        ${insureRate.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Your Deductible</td>
                      <td style={{ textAlign: "right", paddingRight: "15px" }}>
                        ${deductible.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Your Copay</td>
                      <td style={{ textAlign: "right", paddingRight: "15px" }}>
                        ${copay.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Your Coinsurance</td>
                      <td style={{ textAlign: "right", paddingRight: "15px" }}>
                        ${coinsurance.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Insurance Company Pays</td>
                      <td style={{ textAlign: "right", paddingRight: "15px" }}>
                        ${companyPay.toFixed(2)}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#F3F8F2" }}>
                      <td>Your Estimated Charge</td>
                      <td style={{ textAlign: "right", paddingRight: "15px" }}>
                        ${estimate.toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}

            <Button
              variant="filled"
              sx={{
                fontSize: "13px",
                border: "1px solid #45A736",
                backgroundColor: "#45A736",
                padding: "2px 15px",
                color: "white",
                borderRadius: "10px",
                textTransform: "none",
                letterSpacing: "0.3px",
                height: "38px",
                "&:hover": {
                  backgroundColor: "#45A736 !important",
                  color: "white",
                },
              }}
            >
              Verify Hospital Reported Avg. Cash Price
            </Button>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <InfoOutlinedIcon sx={{ color: "#45A736", fontSize: "15px" }} />{" "}
              <p
                style={{
                  fontSize: "14px",
                  marginLeft: "5px",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                Where does this price come from?
              </p>
            </Box>
          </div>
        </Container>
      </Box>

      <AppFooter value={false} />
    </>
  );
}

export default ProcedurePrice;
