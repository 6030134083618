import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";
import { List, ListItem, ListItemText, Select, MenuItem } from "@mui/material";
import axios from "axios";
// import { useDispatch } from 'react-redux';
import {
  userPreference,
  location,
  memberId,
  specialtySearch,
  payerSearch,
} from "../components/reducer/action";
import Env from "../utils/Services/Env";
import { connect } from "react-redux";
// import { apiUrl } from "../utils/Services/Env";
// import useDebounce from "./Hooks/useDebounce";

const UserPreference = ({ open, onClose, onIndicator }) => {

  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [providerList, setProviderList] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [payerOptions, setPayerOptions] = useState([]);
  const [payerList, setPayerList] = useState([]);
  // const [service, setService] = useState("All services");
  const [specialities, setSpecialities] = useState([]);
  const [profile, setProfile] = useState({
    Specialty: "All services",
    // City: "",
    // PayerName: "",
    // MemberId: "",
  });
  const [errors, setErrors] = useState({
    MemberId: "",
  });

  const [type, setType] = useState("createPreference");
  // const debouncedSearchLocation = useDebounce(searchLocation, 600);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const getPreference = async (userId) => {
    const userPreferenceData = await Env.get(`/userpreference/${userId}`);

    if (userPreferenceData.data.status === 404) {
      setType("createPreference");
    } else {
      setTimeout(() => {
        dispatch(userPreference(userPreferenceData.data.data.id));
        dispatch(location(userPreferenceData.data.data.location));
        dispatch(specialtySearch(userPreferenceData.data.data.serviceName));
        dispatch(memberId(userPreferenceData.data.data.memberId));
        dispatch(payerSearch(userPreferenceData.data.data.payerName));
      }, 1000);
      setProfile({
        Specialty: userPreferenceData.data.data.serviceName,
        City: userPreferenceData.data.data.location,
        PayerName: userPreferenceData.data.data.payerName,
        MemberId: userPreferenceData.data.data.memberId,
      });
      // setService(userPreference.data.data.serviceName);
      setType("updatePreference");
    }
  };

  const uid = useSelector((state) => state.uid);
  React.useEffect(() => {
    // const uid = localStorage.getItem("uid");

    if (uid) {
      // setUid(uid);
      getPreference(uid);
    }
    getServiceData();
  }, [uid]);

  const handleCreatePreference = async () => {
    const data = {
      serviceName: profile.Specialty,
      payerName: profile.PayerName,
      // uid: uid,
      memberId: profile.MemberId,
      location: profile.City,
    };
    Object.keys(data).forEach((key) => {
      if (data[key] === undefined) {
        delete data[key];
      }
    });
    if (Object.keys(data).length === 0) {
      setMessage("Please fill in any of the fields.");
      setOpenAlert(true);
      setSuccess(false);
    } else {
      data.uid = uid;
      const results = await Env.post(`userpreference`, data);
      if (results.data.status === 200) {
        setProfile(profile);
        setTimeout(() => {
          dispatch(userPreference(results.data.data.id));
          dispatch(location(results.data.data.location));
          dispatch(specialtySearch(results.data.data.specialty));
          dispatch(memberId(results.data.data.memberId));
          dispatch(payerSearch(results.data.data.payerName));
        }, 1000);
        setType("updatePreference");
        onClose(results.data.message);
      } else {
        setMessage(results.data.message);
        setOpenAlert(true);
        setSuccess(false);
      }
    }
  };

  const handleDeletePreference = async () => {
    const results = await Env.delete(`userpreference/${uid}`);
    if (results.data.status === 200) {
      setProfile({ Specialty: "All services" });
      setTimeout(() => {
        dispatch(location(null));
        dispatch(specialtySearch(null));
        dispatch(memberId(null));
        dispatch(payerSearch(null));
      }, 1000);
      // setService("All services");
      setType("createPreference");
      onClose(results.data.message);
    }
  };

  const handleUpdatePreference = async () => {
    const data = {
      serviceName: profile.Specialty,
      payerName: profile.PayerName,
      memberId: profile.MemberId,
      location: profile.City,
    };
    Object.keys(data).forEach((key) => {
      if (data[key] === null) {
        delete data[key];
      }
    });
    const results = await Env.put(`userpreference/${uid}`, data);
    if (results.data.status === 200) {
      // setMessage(results.data.message);
      // setOpenAlert(true);
      setProfile(profile);
      setTimeout(() => {
        dispatch(location(data.location));
        dispatch(specialtySearch(data.serviceName));
        dispatch(memberId(data.memberId));
        dispatch(payerSearch(data.payerName));
      }, 1000);
      onClose(results.data.message);
    } else {
      setMessage(results.data.message);
      setOpenAlert(true);
      setSuccess(false);
    }
  };

  const handleChange = (e) => {
    setErrors({});
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const serviceChange = (event) => {
    // setService(event.target.value);
    setProfile({ ...profile, Specialty: event.target.value });
  };

  const providerValue = (e) => {
    const locValue = {
      location: e.target.value,
    };
    const getSearchProvider = Env.post(`location/getLocation`, locValue);

    getSearchProvider.then(
      (response) => {
        setProviderList(response.data.data);
      },
      (error) => {
        console.error(error);
      }
    );
    setProfile({ ...profile, City: e.target.value });
    setProviderOptions(e.target.value.length > 2 ? true : false);
  };

  const payerValue = (e) => {

    const stateVal = profile.City === "" || profile.City === undefined ? '' : profile.City
    // if (e.target.value.length > 2) {
      const getSearchPayer = Env.get(`payer/autocomplete?q=${e.target.value}&state=${stateVal}`);

      getSearchPayer.then(
        (response) => {
          setPayerList(response.data.suggession);
        },
        (error) => {
          console.error(error);
        }
      );
    // }
    setProfile({ ...profile, PayerName: e.target.value });
    setPayerOptions(e.target.value.length > 2 ? true : false);
  };

  const handleProviderOptionClick = (option) => {
    setProfile({ ...profile, City: option.label });
    setProviderOptions(false);
  };

  const getServiceData = async (e) => {
    const getServiceData = await Env.get(`/speciality/list`);
    setSpecialities(getServiceData.data.data);
    // this.setState({
    //   specialities: getServiceData.data.data,
    // });
  };

  const handlePayerOptionClick = (option) => {
    setProfile({ ...profile, PayerName: option.data });
    setPayerOptions(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          getPreference(uid);
          setProviderList([]);
          // setService("All services");
          setPayerList([]);
          setErrors({});
          setProfile({});
          onClose();
        }}
        style={{ height: "calc(100vh - 6vh)" }}
        className="pofile-popup"
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            getPreference(uid);
            setProviderList([]);
            // setService("All services");
            setPayerList([]);
            setErrors({});
            setProfile({});
            onClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openAlert}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={success === true ? "success" : "error"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {`${message}`}
          </Alert>
        </Snackbar>
        <div>
          <DialogContent>
            <DialogTitle style={{ padding: 0, fontSize: "12px" }}>
              MemberId
            </DialogTitle>
            <TextField
              fullWidth
              margin="normal"
              size="small"
              type="MemberId"
              name="MemberId"
              onChange={handleChange}
              defaultValue={profile.MemberId || ""}
              error={!!errors.MemberId}
              helperText={errors.MemberId}
              InputProps={{
                sx: {
                  color: "rgba(79, 116, 89, 1)", // Text color
                  borderColor: "transparent",
                  background: "rgba(69, 167, 54, 0.05)", // Background color
                  //   "&::placeholder": {
                  //     color: "#4F7459", // Placeholder color
                  //   },
                },
              }}
            />
            <DialogTitle style={{ padding: 0, fontSize: "12px" }}>
              Specialty
            </DialogTitle>
            {/* <TextField
              fullWidth
              margin="normal"
              size="small"
              onChange={handleChange}
              defaultValue={profile.Specialty}
              name="Specialty"
              InputProps={{
                sx: {
                  color: "rgba(79, 116, 89, 1)", // Text color
                  borderColor: "transparent",
                  background: "rgba(69, 167, 54, 0.05)", // Background color
                  //   "&::placeholder": {
                  //     color: "#4F7459", // Placeholder color
                  //   },
                },
              }}
            /> */}
            <Select
              labelId="select-ul"
              className="filter-input  modal-select"
              id="modal-select"
              value={
                profile.Specialty === "All services" ? 1 : profile.Specialty
              }
              onChange={serviceChange}
              MenuProps={{
                disableScrollLock: true,
                marginThreshold: null,
              }}
              InputProps={{
                sx: {
                  color: "rgba(79, 116, 89, 1)", // Text color
                  borderColor: "transparent",
                  background: "rgba(69, 167, 54, 0.05)", // Background color
                  //   "&::placeholder": {
                  //     color: "#4F7459", // Placeholder color
                  //   },
                },
              }}
            >
              <MenuItem value={1} id="modal-select-drop">
                All Services
              </MenuItem>
              {specialities.length > 0 &&
                specialities.map((obj) => (
                  <MenuItem
                    value={obj.name}
                    key={obj.uuid}
                    id="modal-select-drop"
                  >
                    {obj.name}
                  </MenuItem>
                ))}
              {/*this.ServiceData.map(obj=><MenuItem value= {2}>All Services</MenuItem>)*/}
            </Select>
            <div style={{ position: "relative" }}>
              <DialogTitle style={{ padding: 0, fontSize: "12px" }}>
                City
              </DialogTitle>
              <TextField
                fullWidth
                margin="normal"
                size="small"
                autoComplete="off"
                onChange={(e) => providerValue(e)}
                // defaultValue={profile.City}
                value={profile.City}
                name="City"
                InputProps={{
                  sx: {
                    color: "rgba(79, 116, 89, 1)", // Text color
                    borderColor: "transparent",
                    background: "rgba(69, 167, 54, 0.05)", // Background color
                    //   "&::placeholder": {
                    //     color: "#4F7459", // Placeholder color
                    //   },
                  },
                }}
              />
              {providerOptions &&
                // showByAddress &&
                providerList?.length > 0 && (
                  <List className="city-drop">
                    {providerList?.map((option) => (
                      <ListItem
                        key={option.id}
                        button
                        onClick={() => handleProviderOptionClick(option)}
                      >
                        <ListItemText primary={option.label} />
                      </ListItem>
                    ))}
                  </List>
                )}
            </div>
            <div style={{ position: "relative" }}>
              <DialogTitle
                style={{ padding: 0, fontSize: "12px", fontWeight: "1000px" }}
              >
                Payer Name
              </DialogTitle>
              <TextField
                fullWidth
                margin="normal"
                size="small"
                name="PayerName"
                autoComplete="off"
                onChange={(e) => payerValue(e)}
                value={profile.PayerName || ""}
                InputProps={{
                  sx: {
                    color: "rgba(79, 116, 89, 1)", // Text color
                    borderColor: "transparent",
                    background: "rgba(69, 167, 54, 0.05)", // Background color
                    //   "&::placeholder": {
                    //     color: "#4F7459", // Placeholder color
                    //   },
                  },
                }}
              />
              {payerList.length > 0 && payerOptions ? (
                <List className="city-drop">
                  {payerList?.map((option, index) => (
                    <ListItem
                      key={index}
                      button
                      onClick={() => handlePayerOptionClick(option)}
                    >
                      <ListItemText primary={option.data} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                ""
              )}
            </div>
            {type === "createPreference" ? (
              <>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#45A736",
                    color: "White",
                    marginBottom: "10px",
                    marginTop: "30px",
                    borderRadius: "10px",
                    height: "50px",
                  }}
                  onClick={handleCreatePreference}
                >
                  Create your Preference
                </Button>
              </>
            ) : (
              <>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#45A736",
                    color: "White",
                    marginBottom: "10px",
                    marginTop: "30px",
                    borderRadius: "10px",
                    height: "50px",
                  }}
                  onClick={handleUpdatePreference}
                >
                  Save
                </Button>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "white",
                    color: "#45A736",
                    marginBottom: "10px",
                    marginTop: "5px",
                    border: "1px solid #45A736",
                    borderRadius: "10px",
                    height: "50px",
                  }}
                  onClick={handleDeletePreference}
                >
                  Delete Your Preferences
                </Button>{" "}
              </>
            )}
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

function mapDispatchToProps(state) {
  return {
    location: state.location,
    searchLoc: state.searchLoc,
  };
}

export default connect(mapDispatchToProps)(UserPreference);
