import * as React from "react";

import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Procedures from "../pages/Procedures";
import SearchPage from "../Search/SearchPage";
import ProcedureSearch from "../Search/ProcedureSearch";
import ProcedurePrice from "../pages/ProcedurePrice";
import Providers from "../pages/Providers";
import Payers from "../pages/Payers";
import PageNotFound from "../pages/PageNotFound";
import ComingSoon from "../pages/ComingSoon";
import ProviderInfo from "../pages/ProviderInfo";
// import ContactUs from "../pages/ContactUsSample";
import ContactUs from "../pages/ContactUs";
import PayersInfo from "../pages/PayersInfo";
import AboutUs from "../pages/AboutUs";
import UserTestimonials from "../pages/UserTestimonials";
import HealthcareDollarApp from "../pages/ContactUs";
import PartnersWithUs from "../pages/PartnersWithUs";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/provider/providerListing" element={<SearchPage />} />
      <Route path="procedure/searchs" element={<ProcedureSearch />} />
      <Route path="/procedures" element={<Procedures />} />
      <Route path="/providers" element={<Providers />} />
      <Route path="/payers" element={<Payers />} />
      <Route path="/ProcedureRate" element={<ProcedurePrice />} />
      <Route path="/comingsoon" element={<ComingSoon />} />
      <Route path="/providerinfo/:id" element={<ProviderInfo />} />
      <Route path="/payer/info/:name" element={<PayersInfo />} />
      <Route path="/Testimonials" element={<UserTestimonials />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/contact-us" element={<HealthcareDollarApp />} />
      <Route path="/partners" element={<PartnersWithUs />} />
      <Route
        path="/provider/procedure-cash-price"
        element={<ProcedurePrice />}
      />
      <Route
        path="/procedure-cash-price"
        element={<ProcedurePrice />}
      />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
