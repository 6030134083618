import * as React from "react";
import logo from "../../Assets/Static/logo1.png";
import HomeBanner from "../../Assets/Static/HomeBanner.png";
import { Grid, Container, Box, Button, IconButton, Divider, List, ListItem, ListItemButton, ListItemText, AppBar, Toolbar, Snackbar, Alert , Tooltip,Avatar} from "@mui/material";
import HomeSearch from "../Search/HomeSearch";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Popover from "@mui/material/Popover";
import NewBanner from "../../Assets/Static/New banner.png";
import SignupPopup from "../pages/Signup";
import CustomizedDialogs from "../pages/HealthcareDollarApp";
import ProvidersNearBy from "../pages/ProvidersNearBy";
import GetApp from "../pages/GetApp";
// import { alpha, styled } from "@mui/material/styles";
import UserProfile from "../UserProfile";
import UserPreference from "../UserPreference";
import axios from "axios";
import { apiUrl } from "../../utils/Services/Env";
import _ from "lodash";
import "../../Assets/css/AppHeader.css";
import { useSelector, useDispatch } from "react-redux";
import {  clearStore } from "../reducer/action";
import {  ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
//   [theme.breakpoints.up("md")]: {
//     marginRight: theme.spacing(5),
//     width: "auto",
//   },
// }));

const AppTitle = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [message, setMessage] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);
  const [providersNearby, setProvidrsNearBy] = React.useState(false);
  const [getApp, setGetApp] = React.useState(false);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [openPreference, setOpenPreference] = React.useState(false);
  const [header, setHeader] = React.useState(false);
  const [list, setList] = React.useState(false);
  const [openSignup, setOpenSignup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [chooseSignup, setChooseSignup] = React.useState(false);
  const [name, setName] = React.useState("");
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [location, setLocation] = React.useState(null);

  const isLoggedIn = useSelector((state) => state.isLogged);
  const uid = useSelector((state) => state.uid);
  const token = useSelector((state) => state.token);
  const uName = useSelector((state) => state.userName);
  const handleClosealert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };
  const handleClickProfile = (event) => {
    setIsDivActive(false);
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setAnchorElUser(null);
  };

  const openPopover = Boolean(anchorElUser);
  const pid = openPopover ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openPopup = Boolean(anchorEl);

  const id = openPopup ? "left-side-dialog" : undefined;

  const handleSigninOpen = () => {
    setAnchorElUser(false);
    setOpenSignup(true);
    setProvidrsNearBy(false);
    setChooseSignup(true);
  };

  const handleSignupClose = (message) => {
    setOpenSignup(false);
    setProvidrsNearBy(false);
    if (message) {
      setMessage(message);
      setOpenAlert(true);
    }
  };

  const handleProviderOpen = () => {
    setProvidrsNearBy(true);
    setGetApp(false);
    setOpenSignup(false);
    handleGetCurrentLocation();
  };

  const handleProviderClose = () => {
    setProvidrsNearBy(false);
  };

  const handleGetAppOpen = () => {
    setGetApp(true);
    setProvidrsNearBy(false);
    setOpenSignup(false);
  };

  const handleGetAppClose = () => {
    setGetApp(false);
  };

  React.useEffect(() => {
    const head = window.location.href.split("/")[3];

    if (head === "" || props?.value !== false) {
      setHeader(true);
    } else {
      setHeader(false);
    }

    if (uid) {
      axios.get(`${apiUrl}user/${uid}`).then((res) => {
        if (res.data?.status === 200 && res.data?.userStatus !== 2) {
          setName(res.data.data.firstName);
          if (res.data.data.profilePicture) {
            setImg(
              `https://healthcaredollar.tech/api/images/${res.data.data.profilePicture}`
              // `https://hcddemo.site/api/images/${res.data.data.profilePicture}`
            );
          }
        } else {
          setTimeout(() => {
            dispatch(clearStore());
          }, 1000);
        }
      });
    }
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 300); 
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [props, isLoggedIn, uName]);
  const [img, setImg] = React.useState("");
  const handleLogout = () => {
    dispatch(clearStore());
    setName("User");
    setImg("");
    navigate("/");
  };
  const handleOpenProfile = (message, reload) => {
    setOpenProfile(false);

    if (reload === 200) {
      setTimeout(() => {
        dispatch(clearStore());
      }, 1000);
      setImg("");
      setName(null);
    }
    if (_.isString(message)) {
      setMessage(message);
      setOpenAlert(true);
    }
  };
  const handleOpenPreference = (message, reload) => {
    setOpenPreference(false);

    if (reload === 200) {
      setTimeout(() => {
        dispatch(clearStore());
      }, 1000);
      setImg("");
      setName(null);
    }
    if (_.isString(message)) {
      setMessage(message);
      setOpenAlert(true);
    }
  };
  const [isSticky, setIsSticky] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setIsSticky(true);
      } else {
        setIsDivActive(false);
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isDivActive, setIsDivActive] = React.useState(false);

  const toggleDivClass = () => {
    setIsDivActive(!isDivActive);
  };

  const open = Boolean(anchorEl);
  const handleMyProfile = () => {
    setAnchorElUser(null);
    setOpenProfile(true);
  };
  const handleMyPreference = () => {
    setAnchorElUser(null);
    setOpenPreference(true);
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude }); 
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  return (
    <Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {header ? (
        <>
          <AppBar
            className={isSticky ? "headernew sticky" : "headernew"}
            style={{
              backgroundColor: "#45A736",
              padding: "0.6rem 2rem",
              boxShadow: "none",
            }}
          >
            <Box>
              <Toolbar
                sx={{
                  padding: "0px 0px !important",
                  justifyContent: "space-between",
                  display: { xs: "block", sm: "flex" },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  sx={{ padding: "0px !important" }}
                >
                  <div className="header-menu bar-img">
                    <MenuIcon
                      className={`menicon-change ${
                        isDivActive ? "active" : ""
                      }`}
                      onClick={toggleDivClass}
                      sx={{ color: "white", fontSize: "45px" }}
                    />
                    <Divider
                      sx={{
                        marginLeft: "1rem !important",
                        marginRight: "1rem !important",
                        color: "white",
                        borderColor: "white",
                      }}
                      orientation="vertical"
                      flexItem
                    />
                  </div>

                  <img onClick={() => navigate("/")} src={logo} alt="logo" />
                </IconButton>

                <div className="header-menu">
                  <div>
                    <Button
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      sx={{ padding: "0px !important" }}
                    >
                      <Box
                        sx={{ flexGrow: 0, color: "#fff", fontSize: "18px" }}
                      >
                        {!isLoggedIn ? (
                          <>
                            <Button
                              aria-describedby={pid}
                              style={{
                                color: "black !important",
                                backgroundColor: "white",
                                border: "none",
                              }}
                              onClick={handleSigninOpen}
                            >
                              Log in
                            </Button>
                          </>
                        ) : (
                          <>
                            Welcome {name && name !== null ? name : "user"} !
                            <Tooltip sx={{ marginLeft: "1rem" }}>
                              <IconButton
                                aria-describedby={pid}
                                // variant="contained"
                                onClick={handleClickProfile}
                              >
                                <Avatar src={img} />
                              </IconButton>
                              <Popover
                                className="basic-menu"
                                id={pid}
                                open={openPopover}
                                anchorEl={anchorElUser}
                                onClose={handleCloseProfile}
                                sx={{
                                  color: "white",
                                  border: "none",
                                }}
                                disableScrollLock={true}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <List>
                                  <ListItem
                                    sx={{
                                      borderBottom: "1px solid",
                                      borderBottomColor: "grey",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        color: "black !important",
                                        backgroundColor: "white",
                                        border: "none",
                                      }}
                                      onClick={handleMyProfile}
                                    >
                                      My Profile
                                    </Button>
                                  </ListItem>
                                  <ListItem
                                    sx={{
                                      borderBottom: "1px solid",
                                      borderBottomColor: "grey",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        color: "black !important",
                                        backgroundColor: "white",
                                        border: "none",
                                      }}
                                      onClick={handleMyPreference}
                                    >
                                      My Preferences
                                    </Button>
                                  </ListItem>
                                  <ListItem>
                                    <Button
                                      style={{
                                        color: "black !important",
                                        backgroundColor: "white",
                                        border: "none",
                                      }}
                                      onClick={handleLogout}
                                    >
                                      Logout
                                    </Button>
                                  </ListItem>
                                </List>
                              </Popover>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    </Button>
                  </div>
                 
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    sx={{ marginTop: "80px" }}
                    open={openAlert}
                    autoHideDuration={2000}
                    onClose={handleClosealert}
                  >
                    <Alert
                      onClose={handleClosealert}
                      severity="success"
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      {`${message}`}
                    </Alert>
                  </Snackbar>
                  <UserProfile
                    open={openProfile}
                    onClose={handleOpenProfile}
                    onIndicator={(img) => {
                      setImg(`https://healthcaredollar.tech/api/images/${img}`);
                      // setImg(`https://hcddemo.site/api/images/${img}`);
                    }}
                  />
                  <SignupPopup
                    open={openSignup}
                    onClose={handleSignupClose}
                    choose={chooseSignup}
                  />
                  <UserPreference
                    open={openPreference}
                    onClose={handleOpenPreference}
                  />

                  <ProvidersNearBy
                    open={providersNearby}
                    onClose={handleProviderClose}
                    location={location}
                  />
                  <GetApp open={getApp} onClose={handleGetAppClose} />
                </div>
              </Toolbar>
            </Box>
            {isSticky && (
              <Box className={`toggle-div ${isDivActive ? "active" : ""}`}>
                <List className="linear-menu">
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/")}>
                      <ListItemText>Home</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate("/aboutus");
                      }}
                    >
                      <ListItemText>About us</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/testimonials")}>
                      <ListItemText>Testimonials</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleProviderOpen}>
                      <ListItemText>Providers Near You</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/contactus")}>
                      <ListItemText>Contact Us</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/partners")}>
                      <ListItemText>Partner With Us</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleGetAppOpen}>
                      <ListItemText>Get the Healthcare Dollar App</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            )}
          </AppBar>

          <Box
            className="main-top"
            style={{
              backgroundImage: `url(${HomeBanner})`,
              backgroundSize: "cover",
              width: "100%",
              backgroundColor: header ? "#45a736" : "#45a736",
              padding: "0.5rem 0rem 0rem",
              boxShadow: "none",
              marginTop: "80px",
              position: "relative",
            }}
          >
            
            <Grid container sx={{ position: "relative" }}>
              <CustomizedDialogs />
              <Container
                className="container-common banner-seaction"
                sx={{
                  display: "flex",
                  paddingTop: "3rem",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <div>
                  <p className="your-path">
                    Increasing consumer knowledge.  Enhancing consumer control.
                  </p>
                  <p className="Commited">
                    Consumer Knowledge = Consumer Control
                  </p>
                </div>
                <div style={{ height: "580px" }}>
                  <img
                    src={NewBanner}
                    alt="logo"
                    className="banner-img"
                    style={{ height: "100%" }}
                  />
                </div>
                <div>
                  <p
                    className="your-path path-right"
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <span>Healthcare Dollar is</span>
                    <br />
                    <span>cost estimation</span>
                    <br />
                    <span>made easy!</span>
                  </p>
                </div>
              </Container>
              <Container className="container-common">
                <div
                  className="banner-search-inner"
                  style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bottom: "5rem",
                  }}
                >
                  <HomeSearch />
                </div>
              </Container>
            </Grid>
          </Box>
        </>
      ) : (
        <Box className="main-top" sx={{ marginTop: "83px" }}>
          <AppBar
            className={isSticky ? "headernew sticky" : "headernew"}
            style={{
              backgroundColor: "#45A736",
              padding: "0.6rem 2rem",
              boxShadow: "none",
            }}
          >
            <Box>
              <Toolbar
                sx={{
                  padding: "0px !important",
                  justifyContent: "space-between",
                  display: { xs: "block", sm: "flex" },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                  sx={{ padding: "0px !important" }}
                >
                  <div className="header-menu bar-img">
                    <MenuIcon
                      className={`menicon-change ${
                        isDivActive ? "active" : ""
                      }`}
                      onClick={toggleDivClass}
                      sx={{ color: "white", fontSize: "45px" }}
                    />
                    <Divider
                      sx={{
                        marginLeft: "1rem !important",
                        marginRight: "1rem !important",
                        color: "white",
                        borderColor: "white",
                      }}
                      orientation="vertical"
                      flexItem
                    />
                  </div>

                  <img onClick={() => navigate("/")} src={logo} alt="logo" />
                </IconButton>
                <div className="header-menu">
                  <Box sx={{ flexGrow: 0, color: "#fff", fontSize: "18px" }}>
                    {isLoggedIn === false && (
                      <>
                        <Button
                          aria-describedby={pid}
                          style={{
                            color: "black !important",
                            backgroundColor: "white",
                            border: "none",
                          }}
                          onClick={handleSigninOpen}
                        >
                          Log in
                        </Button>
                      </>
                    )}
                    {isLoggedIn === true && (
                      <>
                        Welcome {name && name !== null ? name : "user"} !
                        <Tooltip sx={{ marginLeft: "1rem" }}>
                          <IconButton
                            aria-describedby={pid}
                            // variant="contained"
                            onClick={handleClickProfile}
                          >
                            <Avatar src={img} />
                          </IconButton>
                          <Popover
                            className="basic-menu"
                            id={pid}
                            open={openPopover}
                            anchorEl={anchorElUser}
                            onClose={handleCloseProfile}
                            sx={{
                              color: "white",
                              border: "none",
                            }}
                            disableScrollLock={true}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <List>
                              <ListItem
                                sx={{
                                  borderBottom: "1px solid",
                                  borderBottomColor: "grey",
                                }}
                              >
                                <Button
                                  style={{
                                    color: "black !important",
                                    backgroundColor: "white",
                                    border: "none",
                                  }}
                                  onClick={handleMyProfile}
                                >
                                  My Profile
                                </Button>
                              </ListItem>
                              <ListItem
                                sx={{
                                  borderBottom: "1px solid",
                                  borderBottomColor: "grey",
                                }}
                              >
                                <Button
                                  style={{
                                    color: "black !important",
                                    backgroundColor: "white",
                                    border: "none",
                                  }}
                                  onClick={handleMyPreference}
                                >
                                  My Preferences
                                </Button>
                              </ListItem>
                              <ListItem>
                                <Button
                                  style={{
                                    color: "black !important",
                                    backgroundColor: "white",
                                    border: "none",
                                  }}
                                  onClick={handleLogout}
                                >
                                  Logout
                                </Button>
                              </ListItem>
                            </List>
                          </Popover>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    sx={{ marginTop: "70px" }}
                    open={openAlert}
                    autoHideDuration={2000}
                    onClose={handleClosealert}
                  >
                    <Alert
                      onClose={handleClosealert}
                      severity="success"
                      variant="filled"
                      sx={{ width: "100%" }}
                    >
                      {`${message}`}
                    </Alert>
                  </Snackbar>
                  <UserProfile
                    open={openProfile}
                    onClose={handleOpenProfile}
                    onIndicator={(img) => {
                      setImg(`https://healthcaredollar.tech/api/images/${img}`);
                      // setImg(`https://hcddemo.site/api/images/${img}`);
                    }}
                  />
                  <SignupPopup
                    open={openSignup}
                    onClose={handleSignupClose}
                    choose={chooseSignup}
                  />
                  <UserPreference
                    open={openPreference}
                    onClose={handleOpenPreference}
                  />
                  <ProvidersNearBy
                    open={providersNearby}
                    onClose={handleProviderClose}
                    location={location}
                  />
                  <GetApp open={getApp} onClose={handleGetAppClose} />
                </div>
              </Toolbar>
            </Box>

            {isSticky && (
              <Box className={`toggle-div ${isDivActive ? "active" : ""}`}>
                <List className="linear-menu">
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/")}>
                      <ListItemText>Home</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate("/aboutus");
                      }}
                    >
                      <ListItemText>About us</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/testimonials")}>
                      <ListItemText>Testimonials</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleProviderOpen}>
                      <ListItemText>Providers Near You</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/contactus")}>
                      <ListItemText>Contact Us</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate("/partners")}>
                      <ListItemText>Partner With Us</ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleGetAppOpen}>
                      <ListItemText>Get the Healthcare Dollar App</ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            )}
          </AppBar>
        </Box>
      )}
    </Box>
  );
};

export default AppTitle;
