import { Grid } from "@mui/material";
import React from "react";
import AppTitle from "../AppLayout/AppTitle";
import {
  Box,
  Input,
  Divider,
  Button,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import AppFooter from "../AppLayout/AppFooter";
import "../../Assets/css/Search.css";
import Env from "../../utils/Services/Env";
// import Pagination from "@mui/material/Pagination";
import "../../Assets/css/PaginationStyle.css";
import Container from "@mui/material/Container";
import Searchimg from "../../Assets/Static/banner-search.svg";
import { searchLocation } from "../reducer/action";
import withRouter from "../Router/withRouter";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      procedureName: "",
      procedureClick: false,
      locationName: "",
      locationClick: false,
      procedureList: [],
      locationList: [],
      specialities: [],
      procedureOptions: false,
      providerOptions: false,
      option: ["dummy"],
      age: "",
      service: "All Services",
      medicareQuality: "",
      sortBy: "",
      verified: "both",
      providersData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      isLoading: true,
      cptCode: "",
      moreData: false,
      procedureID:0,
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    // // this.getData1();
    this.getServiceData();
    const { location, searchLoc, scode } = this.props;

    // window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("mousedown", this.handleClickOutside);
    // const location = params.get("location");
    this.setState(
      {
        locationName: location === null ? searchLoc : location,
        locationClick: true,
        procedureName: scode !== "" ? scode : "",
        procedureClick: scode ? true : false,
      },
      () => this.searchValue()
      // () => this.handlePageChange()
    );
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
    // window.removeEventListener("scroll", this.handleScroll); 
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current?.contains(event.target)) {
      this.state.procedureOptions &&
        this.setState({
          procedureOptions: false,
          procedureName: "",
          procedureClick: false,
        });
      this.state.providerOptions &&
        this.setState({
          providerOptions: false,
          locationName: "",
          locationClick: false,
        });
    }
  };
  getServiceData = async (e) => {
    const getServiceData = await Env.get(`/speciality/list`);
    this.setState({
      specialities: getServiceData.data.data,
    });
  };

  procedureValue = (e) => {
    if (e.target.value.length > 2) {
      const getSearchProcedure = Env.get(
        `procedure/procedureautocomplete?q=${e.target.value}`
      );
      getSearchProcedure.then(
        (response) => {
          this.setState({
            procedureList: response.data.results,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
    this.setState({
      procedureName: e.target.value,
      procedureOptions: e.target.value.length > 2 ? true : false,
      procedureClick: false,
    });
  };
  handleProcedureOptionClick = (option) => {
    this.setState({
      currentPage:1,
      providersData:[],
      isLoading:true,
      procedureName: option.display,
      procedureOptions: false,
      procedureClick: true,
      moreData:false,
    }, 
      () => this.handlePageChange()
  )
}

  handleLocation = (e) => {
    if (e.target.value.length > 2) {
      // const getSearchProvider = Env.get(
      //   `provider/autocomplete?q=${e.target.value}`
      // );

      const locValue = {
        location: e.target.value,
      };

      const getSearchProvider = Env.post(`location/getLocation`, locValue);
      // const val = e.target.value;
      // if (typeof val === "string" && val.length > 5) {
      //   const { dispatch } = this.props;
      //   dispatch(searchLocation(val));
      //   this.searchValue();
      // }
      getSearchProvider.then(
        (response) => {
          this.setState({
            locationList: response.data.data,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
    this.setState({
      locationName: e.target.value,
      providerOptions: e.target.value.length > 2 ? true : false,
      locationClick: false,
    });
  };

  handleLocationOptionClick = (option) => {
    const { dispatch } = this.props;
    dispatch(searchLocation(option.label));
    this.setState({
      currentPage:1,
      providersData:[],
      isLoading: true,
      locationName: option.label,
      providerOptions: false,
      locationClick: true,
      moreData:false,
    },
    () => this.handlePageChange()
  );
  };

  searchValue = () => {
    // this.setState({
    //   isLoading: true,
    // })
    // let url = `provider/providerListing?procedureKey=${service_name}&addressKey=${location === '' ? this.props.searchLoc: location}&page=1&pageSize=1&specialty=${service}&medicareQuality=${medicareQuality}`;
    // console.log("this.state.service", this.state.service);
    
    if (this.state.procedureClick && this.state.procedureName !== "") {
      let searchUrl = "provider/providerListing";
      if (this.state.procedureName !== "" && this.state.locationName !== "") {
        if(this.state.locationClick){
          searchUrl = `${searchUrl}?procedureKey=${this.state.procedureName}&addressKey=${this.state.locationName}&specialty=${this.state.service}&rating=${this.state.medicareQuality}&page=1&pageSize=${this.state.pageSize}&verified=${this.state.verified}&sortBy=${this.state.sortBy}`;
        }else{
          toast.error("Please select Location..");
          this.setState({
            locationName: "",
            providerOptions: false,
            locationClick:false,
          });
        }
      } else if (
        this.state.procedureName === "" &&
        this.state.locationName !== ""
      ) {
        toast.error("Please select Service..");
      } else if (
        this.state.procedureName !== "" &&
        this.state.locationName === ""
      ) {
        toast.error("Please select Location..");
      } else {
        toast.error("Please select both Location & Service..");
      }
      // this.props.navigate(searchUrl);
  
      const getSearchProvider = Env.get(`${searchUrl}`);
  
      getSearchProvider.then(
        (response) => {
          this.setState({
            isLoading: false,
            moreData: response.data.totalCount < 10 ? false : true,
            providersData: response.data.providers,
            procedureID: response.data.procedureId,
            cptCode: response.data.cptCode,
            total: response.data.totalCount,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }else {
      toast.error("Please select Service..");
      this.setState({
        procedureName: "",
        procedureOptions: false,
        procedureClick:false,
      });
    }
  };

  serviceChange = (event) => {
    this.setState(
      {
        providersData:[],
        isLoading:true,
        moreData: false,
        currentPage: 1,
        service: event.target.value === 1 ? "All Services" : event.target.value,
      },
      () => this.handlePageChange()
    );
  };

  ratingChange = (event) => {
    this.setState(
      {
        providersData:[],
        isLoading:true,
        moreData: false,
        currentPage: 1,
        medicareQuality: event.target.value === 0 ? "" : event.target.value,
      },
      () => this.handlePageChange()
    );
  };

  verifyChange = (event) => {
    this.setState({  providersData:[],
      isLoading:true,
      moreData: false,currentPage: 1, verified: event.target.value }, () =>
      this.handlePageChange()
    );
  };

  
  // handleScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop ===
  //       document.documentElement.offsetHeight &&
  //     this.state.moreData
  //   ) {
  //     this.setState(
  //       {
  //         isLoading: true,
  //         currentPage: this.state.currentPage + 1,
  //         moreData: false,
  //       },
  //       () => this.handlePageChange("pagination")
  //     );
  //   }
  // };

  handlePageChange = (type) => {
    let searchUrl = "provider/providerListing?";

    if (this.state.procedureName !== "") {
      searchUrl = searchUrl + `procedureKey=${this.state.procedureName}`;
    }
    if (this.state.locationName !== "") {
      searchUrl = searchUrl + `&addressKey=${this.state.locationName}`;
    }
    if (this.state.service !== "") {
      searchUrl = searchUrl + `&specialty=${this.state.service}`;
    }
    if (this.state.medicareQuality !== "") {
      searchUrl = searchUrl + `&rating=${this.state.medicareQuality}`;
    }
    searchUrl =
      searchUrl +
      `&page=${this.state.currentPage}&pageSize=${this.state.pageSize}&verified=${this.state.verified}&sortBy=${this.state.sortBy}`;

    const getSearchProvider = Env.get(`${searchUrl}`);

    getSearchProvider.then(
      (response) => {
        let providersData =
          type === "pagination"
            ? [...this.state.providersData, ...response.data.providers]
            : response.data.providers;
        this.setState({
          isLoading: false,
          moreData: response.data.providers.length > 0 ? response.data.totalCount < 10 ? false : true : false,
          providersData: providersData,
          cptCode: response.data.cptCode,
          total: response.data.totalCount,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  render() {
    return (
      <Grid>
        <AppTitle value={false} />
        <Grid container sx={{ backgroundColor: "#F5F5F5" }}>
          <Container className="container-common">
            <Grid item sm={12}>
              <Box
                className="input-container__search"
                sx={{
                  margin: "50px 0px",
                  height: "auto",
                  position: "relative",
                }}
                ref={this.dropdownRef}
              >
                <div
                  className="dropdown-list pro-search"
                  style={{ position: "relative", width: "80%" }}
                >
                  <Input
                    id="banner-input"
                    sx={{
                      marginTop: "0px",
                      border: "none",
                      color: "#000",
                      width: "600px",
                      display: "flex",
                    }}
                    disableUnderline
                    autoComplete="off"
                    value={this.state.procedureName}
                    onChange={(e) => this.procedureValue(e)}
                    placeholder="Enter service name or Code..."
                    startAdornment={
                      <InputAdornment
                        position="start"
                        sx={{ color: "#000", marginLeft: "10px" }}
                      >
                        <img
                          src={Searchimg}
                          alt="search"
                          style={{ width: "100%" }}
                        />
                      </InputAdornment>
                    }
                  />
                  {this.state.procedureOptions &&
                    this.state.procedureList?.length > 0 && (
                      <List className="procedure-list">
                        {this.state.procedureList?.map((option) => (
                          <ListItem
                            key={option.uuid}
                            button
                            onClick={() =>
                              this.handleProcedureOptionClick(option)
                            }
                          >
                            <ListItemText primary={option.display} />
                          </ListItem>
                        ))}
                      </List>
                    )}
                </div>
                <Divider
                  orientation="vertical"
                  sx={{ height: "30px", color: "#000" }}
                />
                <Input
                  id="input-with-icon-textfield"
                  sx={{
                    marginTop: "2px",
                    paddingLeft: "34px",
                    border: "none !important",
                    color: "#000",
                    width: "350px",
                    fontWeight: "500",
                  }}
                  disableUnderline
                  autoComplete="off"
                  value={
                    this.state.locationName?.length > 25
                      ? this.state.locationName.slice(0, 25) + "..."
                      : this.state.locationName
                  }
                  onChange={(e) => this.handleLocation(e)}
                  placeholder="Location"
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: "#000" }}>
                      <FmdGoodOutlinedIcon sx={{ color: "#000" }} />
                    </InputAdornment>
                  }
                />
                {this.state.providerOptions &&
                  this.state.locationList?.length > 0 && (
                    <List
                      className="locationProcSearch"
                      sx={{
                        position: "absolute",
                        top: "100%",
                        right: "0px",
                        transform: "translateX(-25%)",
                        zIndex: "2",
                        backgroundColor: "#f5f5f5",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        border: "1px solid #ccc",
                        borderTop: "none",
                        // borderRadius: "0 0 5px 5px",
                        borderRadius: "22px",
                        marginLeft: "10px",
                        maxHeight: "150px",
                        overflowY: "auto",
                        color: "#000",
                        width: "362px",
                      }}
                    >
                      {this.state.locationList?.map((option) => (
                        <ListItem
                          key={option.id}
                          button
                          onClick={() => this.handleLocationOptionClick(option)}
                        >
                          <ListItemText primary={option.label} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                <Button
                  className="pro-button"
                  sx={{
                    //marginLeft: "20px",
                    width: "201px",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#212220 !important",
                    },
                    textTransform: "none",
                    fontSize: "23px",
                    borderRadius: "0px 50px 50px 0px",
                    background: "#212220",
                    fontWeight: "600",
                  }}
                  onClick={this.searchValue}
                >
                  Search
                </Button>
              </Box>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "50px",
              }}
            >
              <Grid item lg={0.5} md={12} sm={12} xs={12}>
                <p className="filter-text">Filter</p>
              </Grid>
              <Grid item lg={3.7} md={3.7} sm={3.7} xs={12}>
                <Select
                  className="filter-input"
                  // value={this.state.service === "" ? 1 : this.state.service}
                  value={this.state.service}
                  onChange={this.serviceChange}
                  MenuProps={{
                    disableScrollLock: true,
                    marginThreshold: null,
                  }}
                >
                  <MenuItem value={'All Services'}>All Services</MenuItem>
                  {this.state.specialities?.length > 0 &&
                    this.state.specialities.map((obj, index) => (
                      <MenuItem value={obj.name} key={obj.uuid}>
                        {obj.name}
                      </MenuItem>
                    ))}
                  {/*this.ServiceData.map(obj=><MenuItem value= {2}>All Services</MenuItem>)*/}
                </Select>
              </Grid>
              <Grid item lg={3.7} md={3.7} sm={3.7} xs={12}>
                <Select
                  className="filter-input"
                  value={
                    this.state.medicareQuality === ""
                      ? 0
                      : this.state.medicareQuality
                  }
                  onChange={this.ratingChange}
                  MenuProps={{
                    disableScrollLock: true,
                    marginThreshold: null,
                  }}
                >
                  <MenuItem value={6} style={{ display: "none" }}>
                    Medicare Care Quality
                  </MenuItem>
                  <MenuItem value={0}>Any Rating</MenuItem>
                  <MenuItem value={5}>5-stars</MenuItem>
                  <MenuItem value={4}>4-stars and above</MenuItem>
                  <MenuItem value={3}>3-stars and above</MenuItem>
                  <MenuItem value={2}>2-stars and above</MenuItem>
                  <MenuItem value={1}>1-star and above</MenuItem>
                </Select>
              </Grid>
              <Grid item lg={3.7} md={3.7} sm={3.7} xs={12}>
                <Select
                  className="filter-input"
                  // value={this.state.verified === "" ? 'both' : this.state.verified}
                  value={this.state.verified}
                  onChange={this.verifyChange}
                  MenuProps={{
                    disableScrollLock: true,
                    marginThreshold: null,
                  }}
                >
                  {/* <MenuItem value={1} style={{ display: "none" }}>
                    Verified 
                  </MenuItem> */}
                  <MenuItem value="both">Any Status</MenuItem>
                  <MenuItem value={true}>Verified</MenuItem>
                  <MenuItem value={false}>Not Verified</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Container className="container-common">
          {this.state.providersData.length > 0 ? (
            <>
              <Grid container>
                <Grid item sm={8} xs={12}>
                  <p className="results-text">
                    {/* {this.state.providersData.length} Results of HCPCS {"dummy"} | Periodic primary re-evaluation for
                an established patient 65 and older */}
                    {this.state.total} Results
                  </p>
                </Grid>

                <Grid
                  item
                  sm={4}
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {/* <Select
                    className="sort-pricing"
                    MenuProps={{
                      disableScrollLock: true,
                      marginThreshold: null,
                    }}
                    placeholder="Sort by Pricing"
                    value={this.state.sortBy === "" ? 1 : this.state.sortBy}
                    onChange={this.sortingChange}
                    labelId="sort-pricing"
                  >
                    <MenuItem value={1} style={{ display: "none" }}>
                      Sort by Pricing
                    </MenuItem>
                    <MenuItem value="highToLow">High to Low</MenuItem>
                    <MenuItem value="lowToHigh">Low to High</MenuItem>
                  </Select> */}
                </Grid>

                {this.state.providersData?.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    sm={12}
                    sx={{
                      marginTop: index === 0 ? "0px" : "15px",
                      marginBottom:
                        this.state.providersData?.length - 1 === index
                          ? "20px"
                          : "0",
                      backgroundColor: "#F5FAF5",
                      borderRadius: "9px",
                      border: "1px solid #E3E3E3",
                      transition: "box-shadow 0.3s ease-in-out",
                      "&:hover": {
                        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <Grid container key={index} sx={{ padding: "1rem" }}>
                      <Grid
                        item
                        sm={10}
                        xs={12}
                        sx={{
                          marginBottom: "1rem",
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            // this.props.navigate(
                            //   `provider/procedure-cash-price?procedureId=${this.state.procedureID}&locationId=${item.locationUuid}`
                            // );
                            window.open(`/procedure-cash-price?procedureId=${this.state.procedureID}&locationId=${item.locationUuid}`)
                          }}
                        >
                          <p className="hcps-text">{this.state.cptCode}</p>
                          <p className="service-hcpcs">{item.procedureName}</p>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        xs={12}
                        sx={{ textAlign: { xs: "center", sm: "right" } }}
                      >
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            // this.props.navigate(
                            //   `provider/procedure-cash-price?procedureId=${this.state.procedureID}&locationId=${item.locationUuid}`
                            // );
                            window.open(
                              `/procedure-cash-price?procedureId=${this.state.procedureID}&locationId=${item.locationUuid}`
                            )
                          }}
                        >
                          {/* <p className="cash-price-value">${item.procedureName}</p> */}
                          <p className="cash-price-value">-</p>
                          <p className="cash-price-text">Avg. Cash Price</p>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        sm={12}
                        xs={12}
                        sx={{
                          backgroundColor: "#fff",
                          border: "1px solid #E3E3E3",
                          borderRadius: "11px",
                          padding: "1rem",
                        }}
                      >
                        <Grid container>
                          <Grid item sm={9} xs={12}>
                            <div style={{ display: "flex" }}>
                              <div>
                                <p className="location-name">
                                  <u
                                    style={{ cursor: "pointer" }}
                                    // onClick={() =>
                                    //   this.props.navigate(
                                    //     `/providerinfo/${item.locationUuid}`
                                    //   )
                                    // }
                                    onClick={() =>
                                      window.open(`/providerinfo/${item.locationUuid}`, '_blank')
                                    }
                                  >
                                    {item.providerName}
                                  </u>
                                </p>
                                <p className="loc-address">
                                  {item.address.street}
                                </p>
                                <p className="loc-address">
                                  {/* {item.location.address
                                .split(",")
                                .splice(1, 2)
                                .join(",")} */}
                                  {item.address.city},{item.address.state},{" "}
                                  {item.address.zip}
                                </p>
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            item
                            sm={3}
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: { xs: "center", sm: "end" },
                              alignItems: "center",
                            }}
                          >
                            {/* <Button
                              className="loc-view-profile"
                              onClick={() => {
                                this.props.navigate(
                                  `/providerinfo/${item.locationUuid}`
                                );
                              }}
                            >
                              View Profile
                            </Button> */}
                             {item.providerName.endsWith("-Individual Doctor") ? null : (
                              <Button
                                className="loc-view-profile"
                                onClick={() => {
                                  this.props.navigate(`/providerinfo/${item.locationUuid}`);
                                }}
                              >
                                View Profile
                              </Button>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {/* {this.state.providersData?.length === 0 ? null : (
              <Pagination
                count={Math.ceil(this.state.total / this.state.pageSize)}
                page={this.state.currentPage}
                onChange={this.handlePageChange}
              />
            )} */}
            </>
          ) : (
            <>
              {!this.state.isLoading && (
                <h4 style={{ textAlign: "center", width: "100%" }}>
                  No Data Found
                </h4>
              )}
            </>
          )}
          {this.state.moreData && (
            <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px' // Optional: Adds some spacing above the button
            }}
          >
            <Button
              variant="contained"
              className="loc-view-profile"
              sx={{marginBottom: '20px'}}
              onClick={() => {
                this.setState(
                  () => ({
                    // currentPage: prevState.currentPage + 1,
                    isLoading: true,
                    // isLoading: true,
                    currentPage: this.state.currentPage + 1,
                    moreData: false,
                  }),
                  () => this.handlePageChange("pagination")
                );
              }}
            >
              View More
            </Button>
            </Box>
          )}
        </Container>

        {this.state.isLoading && (
          <div
            style={{
              height: this.state.currentPage === 1 ? "40vh" : "10vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
            }}
          >
            <CircularProgress color="success" />
          </div>
        )}
        <AppFooter value={false} />
      </Grid>
    );
  }
}

export default withRouter(
  connect((state) => {
    return {
      location: state.location,
      searchLoc: state.searchLoc,
      scode: state.scode,
    };
  })(SearchPage)
);
