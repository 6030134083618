/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React from "react";
import {
  Box,
  Input,
  Divider,
  Button,
  List,
  ListItem,
  ListItemText,
  // Snackbar, Alert
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { connect } from "react-redux";
import Searchimg from "../../Assets/Static/banner-search.svg";
import "../../Assets/css/Search.css";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import GeolocationInfo from "../../components/GeoLocation";
import withRouter from "../Router/withRouter";
import Env from "../../utils/Services/Env";
import { searchLocation, scode, initialLocation } from "../reducer/action";
import { toast } from "react-toastify";

function debounce(func, delay) {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

class HomeSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      procedureName: "",
      procedureClick: false,
      locationName: "",
      locationClick: false,
      procedureList: [],
      locationList: [],
      procedureOptions: false,
      providerOptions: false,
      showList: false,
      showByAddress: false,
      location: props.location,
    };
    this.dropdownRef = React.createRef();
    this.debouncedHandleLocation = debounce(this.fetchLocation.bind(this), 600);
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.location !== this.props.location) {
  //     this.setState({
  //       location: nextProps.location
  //     }, () => this.getAddress()
  //     );
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState(
        {
          location: this.props.location,
        },
        () => this.getAddress()
      );
    }
  }

  componentDidMount() {
    this.getAddress();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current?.contains(event.target)) {
      this.state.procedureOptions &&
        this.setState({
          procedureOptions: false,
          procedureName: "",
        });
      this.state.providerOptions &&
        this.setState({
          providerOptions: false,
          locationName: "",
        });
    }
  };

  // handleClickOutside = (event) => {
  //   if (
  //     this.dropdownRef &&
  //     !this.dropdownRef.current?.contains(event.target) &&
  //     event.target.id !== 'banner-input' // Exclude clicks on the input field
  //   ) {
  //     this.setState({
  //       providerOptions: false,
  //     });
  //   }
  // }

  getAddress = async () => {
    const { dispatch } = this.props;

    if (this.state.location && this.state.location !== null) {
      this.setState({
        locationName: this.state.location,
      });
      dispatch(searchLocation(this.state.location));
    } else {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const data = await GeolocationInfo(latitude, longitude);
          if (data.status === 200) {
            // this.setState({
            //   locationName: `${data.city}, ${data.state}, ${data.country}`,
            // });
            dispatch(
              initialLocation(`${data.city}, ${data.state}, ${data.country}`)
            );
            // dispatch(
            //   initialLocation(`San Jose, CA, USA`)
            // );
            // this.debouncedHandleLocation(
            //     `San Jose, CA, USA`
            //   );
            // dispatch(
            //   searchLocation(`${data.city}, ${data.state}, ${data.country}`)
            // );
            this.debouncedHandleLocation(
              `${data.city}, ${data.state}, ${data.country}`
            );
          }
        },
        (error) => {
          console.log("Error getting current location:", error);
          this.handleLocationError();
        }
      );
    }
  };

  handleLocationError = () => {
    const defaultLocation = "New York, NY, USA";
    this.setState({
      locationName: defaultLocation,
    });
    const { dispatch } = this.props;
    dispatch(searchLocation(defaultLocation));
    this.debouncedHandleLocation(defaultLocation);

    toast.error(
      "Unable to retrieve your location. Please enter your location manually if needed."
    );
  };

  procedure = () => {
    const getProcedure = Env.get("procedure/procedureautocomplete");
    getProcedure.then(
      (response) => {
        this.setState({
          procedureList: response.data.results,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // provider = () => {
  //   const getProvider = Env.get("provider/autocomplete");

  //   getProvider.then(
  //     (response) => {
  //       this.setState({
  //         locationList: response.data.suggession,
  //       });
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // };

  procedureValue = (e) => {
    // if (e.target.value.length > 4) {
    const getSearchProcedure = Env.get(
      `procedure/procedureautocomplete?q=${e.target.value}`
    );

    getSearchProcedure.then(
      (response) => {
        this.setState({
          procedureList: response.data.results,
        });
      },
      (error) => {
        console.error(error);
      }
    );
    // }
    this.setState({
      procedureName: e.target.value,
      // procedureOptions: e.target.value.length > 0 ? true : false,
      procedureOptions: true,
      procedureClick: false,
    });

    const { dispatch } = this.props;
    dispatch(scode(e.target.value));
  };

  handleProcedureOptionClick = (option) => {
    this.setState({
      procedureName: option.display,
      procedureOptions: false,
      procedureClick: true,
    });
    const { dispatch } = this.props;
    dispatch(scode(option.display));
  };

  fetchLocation = async (val) => {
    const { dispatch } = this.props;

    try {
      const locValue = { location: val };
      const response = await Env.post(`location/getLocation`, locValue);
      if (response.data.data.length === 0) {
        dispatch(searchLocation(`New York, NY, USA`));
        dispatch(initialLocation(''))
        this.setState({
          locationName: "New York, NY, USA",
        });
        this.debouncedHandleLocation(
          `New York, NY, USA`
        );
      } else {
        dispatch(searchLocation(val));
        this.setState({
          locationName: val,
        });

        this.setState({
          locationList: response.data.data,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleLocation = (e) => {
    const val = e.target.value;
    this.setState({
      locationName: val,
      providerOptions: val.length > 0,
      locationClick: false,
    });
    if (val.length > 0) {
      this.debouncedHandleLocation(val);
    }
  };

  handleLocationOptionClick = (option) => {
    const { dispatch } = this.props;

    this.setState({
      locationName: option.label,
      providerOptions: false,
      locationClick: true,
    });
    dispatch(searchLocation(option.label));
  };

  // searchValue = () => {
  //   if(this.state.procedureName !== '' && this.state.locationName !== ''){
  //     let searchUrl = `provider/providerListing?`;

  //     if (this.state.procedureName !== "" && this.state.locationName === "") {
  //       searchUrl = `${searchUrl}q=${this.state.procedureName
  //         }&location=${decodeURIComponent("")}`;
  //     } else if (
  //       this.state.procedureName === "" &&
  //       this.state.locationName !== ""
  //     ) {
  //       searchUrl = `${searchUrl}q=${decodeURIComponent("")}&location=${this.state.locationName
  //         }`;
  //     } else {
  //       searchUrl = `${searchUrl}q=${this.state.procedureName}&location=${this.state.locationName}`;
  //     }
  //     this.props.navigate(searchUrl);
  //   } else if(this.state.procedureName === '' && this.state.locationName !== ''){
  //     toast.error('Please select Service name or type code..')
  //   }else{
  //     toast.error('Please select both Location & Service name or type code..')
  //   }
  // };

  searchValue = () => {
    // if (
    //   this.state.locationClick &&
    //   this.state.locationName === this.props.searchLoc &&
    //   this.state.locationName !== ""
    // ) {
    if (this.state.procedureClick && this.state.procedureName !== "") {
      if (this.state.procedureName !== "" && this.state.locationName !== "") {
        let searchUrl = `provider/providerListing?`;

        if (this.state.procedureName !== "" && this.state.locationName === "") {
          searchUrl = `${searchUrl}procedureKey=${
            this.state.procedureName
          }&addressKey=${decodeURIComponent("")}`;
        } else if (
          this.state.procedureName === "" &&
          this.state.locationName !== ""
        ) {
          searchUrl = `${searchUrl}procedureKey=${decodeURIComponent(
            ""
          )}&addressKey=${this.state.locationName}`;
        } else {
          searchUrl = `${searchUrl}procedureKey=${this.state.procedureName}&addressKey=${this.state.locationName}`;
        }
        this.props.navigate(searchUrl);
      } else if (
        this.state.procedureName === "" &&
        this.state.locationName !== ""
      ) {
        toast.error("Please select Service..");
      } else if (
        this.state.procedureName !== "" &&
        this.state.locationName === ""
      ) {
        toast.error("Please select Location..");
      } else {
        toast.error("Please select both Location & Service..");
      }
    } else {
      toast.error("Please select Service..");
      this.setState({
        procedureName: "",
        procedureOptions: false,
      });
    }
    // } else {
    //   toast.error("Please select Location..");
    //   this.setState({
    //     locationName: "",
    //     providerOptions: false,
    //   });
    // }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({
      openAlert: false,
    });
  };

  render() {
    return (
      <>
        <Box
          className="banner-search"
          sx={{
            margin: "auto",
            backgroundColor: "#fff",
            width: "900px",
            height: "60px",
            borderRadius: "41px",
            display: "flex",
          }}
          ref={this.dropdownRef}
        >
          <div
            className="dropdown-list proc-search"
            style={{ position: "relative", width: "490px" }}
          >
            <Input
              id="banner-input"
              sx={{
                marginTop: "0px",
                paddingLeft: "34px",
                border: "none",
                color: "#000",
                width: "600px",
                display: "flex",
                height: "100%",
              }}
              disableUnderline
              autoComplete="off"
              value={this.state.procedureName}
              onFocus={() => {
                this.setState({
                  showList: true,
                  showByAddress: false,
                });
              }}
              onChange={(e) => this.procedureValue(e)}
              placeholder="Enter service name or Code..."
              startAdornment={
                <InputAdornment position="start" sx={{ color: "#000" }}>
                  <img src={Searchimg} alt="search" style={{ width: "100%" }} />
                </InputAdornment>
              }
            />
            {this.state.procedureOptions &&
              this.state.showList &&
              this.state.procedureList?.length > 0 && (
                <List className="procedure-list">
                  {this.state.procedureList?.map((option) => (
                    <ListItem
                      key={option.uuid}
                      button
                      onClick={() => this.handleProcedureOptionClick(option)}
                    >
                      <ListItemText primary={option.display} />
                    </ListItem>
                  ))}
                </List>
              )}
          </div>
          <Divider
            orientation="vertical"
            sx={{ marginTop: "15px", height: "30px", color: "#000" }}
          />
          <div style={{ position: "relative" }}>
            <Input
              id="input-with-icon-textfield"
              sx={{
                marginTop: "2px",
                paddingLeft: "34px",
                border: "none !important",
                color: "#000",
                width: "350px",
                fontWeight: "500",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                height: "100%",
              }}
              disableUnderline
              autoComplete="off"
              // value={
              //   this.state.locationName.length > 20
              //     ? this.state.locationName.slice(0, 20) + "..."
              //     : this.state.locationName
              // }
              value={this.state.locationName}
              onFocus={() => {
                this.setState({
                  showList: false,
                  showByAddress: true,
                });
              }}
              onChange={(e) => this.handleLocation(e)}
              // onChange={(e) => this.debouncedHandleLocation(e)}
              placeholder="Location"
              startAdornment={
                <InputAdornment position="start" sx={{ color: "#000" }}>
                  <FmdGoodOutlinedIcon sx={{ color: "#000" }} />
                </InputAdornment>
              }
            />
            {this.state.providerOptions &&
              this.state.showByAddress &&
              this.state.locationList?.length > 0 && (
                <List
                  className="locationSearch"
                  sx={{
                    position: "absolute",
                    top: "100%",
                    left: "0px",
                    backgroundColor: "#f5f5f5",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #ccc",
                    borderTop: "none",
                    borderRadius: "22px",
                    marginLeft: "10px",
                    maxHeight: "141px",
                    overflowY: "auto",
                    color: "#000",
                    width: "362px",
                  }}
                >
                  {this.state.locationList?.map((option) => (
                    <ListItem
                      key={option.id}
                      button
                      onClick={this.handleLocationOptionClick.bind(this,option)}
                    >
                      <ListItemText primary={option.label} />
                    </ListItem>
                  ))}
                </List>
              )}
          </div>

          <Button
          onClick={this.searchValue}
            sx={{
              width: "201px",
              minWidth: "130px",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#212220 !important",
              },
              textTransform: "none",
              fontSize: "23px",
              borderRadius: "0px 50px 50px 0px",
              background: "#212220",
              fontWeight: "600",
            }}
          >
            Search
          </Button>
        </Box>
      </>
    );
  }
}

export default withRouter(
  connect((state) => {
    return {
      location: state.location,
      searchLoc: state.searchLoc,
      initialLoc: state.initialLoc
    };
  })(HomeSearch)
);
