import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Divider,
  Tooltip,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import React from "react";
import "../Assets/css/AddressMap.css";
import shareIcon from "../Assets/Static/shareIcon.svg";
import GoogleMapReact from 'google-map-react';
import { Link } from "react-router-dom";
import withRouter from "./Router/withRouter";
import Container from "@mui/material/Container";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { REACT_APP_API_GOOGLE_MAP_KEY } from "../utils/Services/Env";



const LocationMarker = () => (
  <div style={{ color: 'red', fontSize: '18px' }}>
    
    <LocationOnIcon />
  </div>
);

class AddressMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: "AIzaSyCDi6B-TsEAJr25xrC2-UlGfwvTnUSIm9g",
      option: false,
      tooltipOpen: false,
    };
  }


  handleCopyClick = () => {
    const currentUrl = window.location.href;
    const tempInput = document.createElement("textarea");
    tempInput.value = currentUrl;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    this.setState({
      tooltipOpen: true,
    });
    setTimeout(() => {
      this.setState({
        tooltipOpen: false,
      });
    }, 400);
  };

  nearbyHandler(id) {
    this.props.navigate(`/providerinfo/${id}`);
  }

  render() {
    return (
      <>
        <Container className="container-common" sx={{ padding: "20px 16px" }}>
          <Box
            sx={{
              display: { xs: "block", lg: "flex" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h2" gutterBottom className="title-provider">
              {this.props.totalData.providerName}
            </Typography>
            <Box sx={{ position: "relative", width: "fit-content" }}>
              <Button
                onMouseEnter={() => this.setState({ option: true })}
                onMouseLeave={() => this.setState({ option: false })}
                variant="outlined"
                sx={{
                  border: "1px solid #45A736",
                  color: "#45A736",
                  padding: "5px 16px",
                  borderRadius: "40px",
                  fontSize: "16px",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "14px",

                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "inherit",
                    border: "1px solid #45A736",
                  },
                }}
              >
                Share {<img src={shareIcon} alt="icon" />}
              </Button>
              {this.state.option && (
                <Paper
                  sx={{
                    position: "absolute",
                    right: "0px",
                    top: "100%",
                    zIndex: "3",
                    width: "160px",
                    padding: "5px",
                    height: "max-content",
                  }}
                  onMouseEnter={() => this.setState({ option: true })}
                  onMouseLeave={() => this.setState({ option: false })}
                >
                  <Tooltip
                    open={this.state.tooltipOpen}
                    title="Copied!"
                    placement="top"
                    arrow
                  >
                    <Button
                      variant="text"
                      sx={{ color: "#45A736" }}
                      onClick={this.handleCopyClick}
                    >
                      <LinkIcon sx={{ marginRight: "10px" }} /> Copy Link
                    </Button>
                  </Tooltip>
                  <Divider></Divider>
                </Paper>
              )}
            </Box>
          </Box>

          <Grid container sx={{ marginTop: "18px" }}>
            <Grid item xs={12} lg={4} className="location-css-handle">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "50px",
                  width: "75%",
                  marginBottom: "4px",
                }}
              >
                <p className="addres-get">Address</p>
                <Link
                  to={this.props.totalData.google_maps_link}
                  target="_blank"
                  className="get-dir"
                >
                  Get Directions
                </Link>
              </Box>
              <p
                style={{
                  marginBottom: "4px",
                  color: "#02363D",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {this.props.totalData.address}
              </p>
              <Box
                sx={{
                  display: "flex",
                  gap: "50px",
                  width: "75%",
                  marginBottom: "4px",
                  alignItems: "center",
                }}
              >
                <p className="addres-get">Phone</p>
                <p
                  className="get-dir"
                  style={{
                    color: this.props.totalData.phone ? "#45A736" : null,
                    textDecoration: this.props.totalData.phone
                      ? "underline"
                      : null,
                  }}
                >
                  {this.props.totalData.phone
                    ? this.props.totalData.phone
                    : "Not Available"}
                </p>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "50px",
                  width: "75%",
                  alignItems: "center",
                }}
              >
                <p className="addres-get">Website</p>
                {!this.props.totalData.website ? (
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    Not Available
                  </p>
                ) : (
                  <Link
                    to={this.props.totalData.website}
                    target="_blank"
                    className="get-dir"
                  >
                    Visit
                  </Link>
                )}
              </Box>
              <p className="addres-get" style={{ width: "100%" }}>
                Nearby Providers
              </p>
              {this.props.nearByProviders.map((data, i) => (
                <Typography
                  sx={{
                    color: "#45A736",
                    marginRight: "2px",
                    fontSize: "12px",
                    letterSpacing: "0.02px",
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  onClick={() => this.nearbyHandler(data.uuid)}
                  key={i}
                  variant="caption"
                  display="inline"
                >
                  {data.name} &nbsp;
                  <span style={{ color: "black" }}>
                    {" "}
                    ({data.distance}&nbsp;miles),
                  </span>
                  &nbsp;
                </Typography>
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              lg={8}
              sx={{
                paddingLeft: { xs: "0px", lg: "36px" },
                paddingTop: { xs: "1rem", lg: "0rem" },
              }}
            >
              <GoogleMapReact
        bootstrapURLKeys={{ key: REACT_APP_API_GOOGLE_MAP_KEY}}
        
        center={{
          lat: this.props.totalData.latitude,
          lng: this.props.totalData.longitude,
        }}
        defaultZoom={12}>
          <LocationMarker lat={this.props.totalData.latitude} lng={this.props.totalData.longitude} text="Location" />

        </GoogleMapReact>


              
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default withRouter(AddressMap);
