import React from "react";
import AppFooter from "../AppLayout/AppFooter";
import AppTitle from "../AppLayout/AppTitle";
import Browse from "../Browse";
import Env from "../../utils/Services/Env";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Input,
  InputAdornment,
  Card,
  CardContent,
  Pagination,
  Box,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../Assets/css/Search.css";
import "../../Assets/css/AlphabetFilter.css";
import "../../Assets/css/Pagination.css";
import "../../Assets/css/PaginationStyle.css";
import Container from "@mui/material/Container";
import Searchimg from "../../Assets/Static/banner-search.svg";
import withRouter from "../Router/withRouter";
import { scode } from "../reducer/action";

class Procedures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        count: 50,
        speciality: "",
        filterbyalphabet: "",
        page: 0,
        pageSize: 15,
      },
      procedureDatas: [],
      procedureOptions: [],
      specialtyData: [],
      alphabets: [],
      procedureName: "",
      selectedAlphabet: "All",
      searchClose: false,
      optionDisplay: false,
      selectedItem: "",
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    this.getAllProcedureData();
    window.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current?.contains(event.target)) {
      // this.state.procedureOptions &&
      //   this.setState({
      //     procedureOptions: false,
      //     procedureName: "",
      //   });
      this.state.providerOptions &&
        this.setState({
          providerOptions: false,
          locationName: "",
        });
    }
  };

  allSpecialties = () => {
    const getSpecialities = Env.get("speciality/list");

    getSpecialities.then(
      (res) => {
        const specialtiesData = res.data.specialities;
        const DefaultVal = {
          uuid: "ffbc61e5-92dd-405f-8c38-c26b0sdjhjjk",
          display: "All Specialities",
        };

        const specialties = [DefaultVal, ...specialtiesData];

        this.setState({
          specialtyData: specialties,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  selectedAlphabet = (data) => {
    this.setState(
      {
        selectedAlphabet: data,
      },
      () => {
        this.getAllProcedureData("", this.state.selectedAlphabet);
      }
    );
  };

  handleProcedureItemClick = (item) => {
    this.props.dispatch(scode(item.display));
    this.props.navigate(`/provider/providerListing`);
  };

  handlePageChange = (event, value) => {
    this.getAllProcedureData(value);
  };

  getAllProcedureData = (pageNo, alphabet) => {
    if (this.state.optionDisplay) {
      this.setState({
        optionDisplay: false,
      });
    }

    alphabet =
      this.state.selectedAlphabet !== "All" && alphabet !== undefined
        ? this.state.selectedAlphabet
        : alphabet === undefined
        ? this.state.selectedAlphabet
        : alphabet;

    let url = `procedure/list?`;

    if (this.state.procedureName !== "" && alphabet === "" && pageNo !== "") {
      url = `${url}q=${this.state.procedureName}&filterbyalphabet=${
        alphabet === "" ? "all" : alphabet
      }&page=${pageNo === undefined ? 1 : pageNo}&pageSize=30`;
    } else if (
      this.state.procedureName === "" &&
      alphabet !== "" &&
      pageNo !== "" && pageNo !== undefined &&
      alphabet !== "All"
    ) {
      url = `${url}q=&filterbyalphabet=${alphabet}&page=${pageNo}&pageSize=30`;
    } else if (
      this.state.procedureName === "" &&
      alphabet === "All" &&
      pageNo === undefined
    ) {
      url = `${url}q=&filterbyalphabet=null&page=${
        pageNo === undefined ? "1" : pageNo
      }&pageSize=30`;
    } else if (
      this.state.procedureName === "" &&
      alphabet === "All" &&
      pageNo !== "" 
    ) {
      url = `${url}q=&filterbyalphabet=all&page=${
        pageNo === undefined ? "1" : pageNo
      }&pageSize=30`;
    } else if (
      this.state.procedureName === "" &&
      alphabet !== "All" &&
      alphabet !== "" &&
      pageNo === ""
    ) {
      url = `${url}q=&filterbyalphabet=${alphabet}&page=1&pageSize=30`;
    } else if (
      this.state.procedureName !== "" &&
      alphabet !== "All" &&
      alphabet !== "" &&
      pageNo === ""
    ) {
      url = `${url}q=${this.state.procedureName}&filterbyalphabet=${alphabet}&page=1&pageSize=30`;
    } else if (
      this.state.procedureName !== "" &&
      alphabet !== "" &&
      pageNo !== ""
    ) {
      url = `${url}q=${this.state.procedureName}&filterbyalphabet=all&page=${
        pageNo === undefined ? "1" : pageNo
      }&pageSize=30`;
    } else if (
      this.state.procedureName !== "" &&
      alphabet !== "All" &&
      pageNo === ""
    ) {
      url = `${url}q=${this.state.procedureName}&filterbyalphabet=${
        alphabet !== "All" ? "all" : alphabet
      }&page=1&pageSize=30`;
    } else if (
      this.state.procedureName !== "" &&
      alphabet === "All" &&
      pageNo === ""
    ) {
      url = `${url}q=${this.state.procedureName}&filterbyalphabet=all&page=1&pageSize=30`;
    } else {
      url = `${url}q=&page=1&pageSize=30`;
    }

    const getProcedureData = Env.get(url);

    getProcedureData.then(
      (res) => {
        const data = res.data;
        this.setState({
          alphabets: data.parameters.alphabets,
          parameters: data.parameters,
          procedureDatas: data.results,
          selectedAlphabet:
            data.parameters.filterbyalphabet === "all"
              ? "All"
              : data.parameters.filterbyalphabet,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  procedureValue = (e) => {
    this.setState({
      procedureName: e.target.value,
      optionDisplay: e.target.value.length > 2 ? true : false,
    });
    if (e.target.value.length > 0) {
      this.setState({
        searchClose: true,
      });
    } else {
      this.setState({
        searchClose: false,
      });
    }

    if (e.target.value.length > 2) {
      const getSearchProcedure = Env.get(
        `procedure/procedureautocomplete?q=${e.target.value}`
      );

      getSearchProcedure.then(
        (response) => {
          this.setState({
            procedureOptions: response.data.results,
            optionDisplay: true,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  handleProcedureOptionClick = (option) => {
    this.setState({
      procedureName: option.display,
      optionDisplay: false,
    });
  };

  clearAll = () => {
    this.setState(
      {
        procedureName: "",
        searchClose: false,
        optionDisplay: false,
        selectedAlphabet: "All",
      },
      () => {
        this.getAllProcedureData("1", "All");
      }
    );
  };

  render() {
    return (
      <>
        <AppTitle value={false} />
        <div style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
          <Container className="container-common">
            <Browse selectTab={0}></Browse>
            <div className="input-container__search">
              <div
                className="dropdown-list prod-search"
                style={{ position: "relative", width: "100%" }}
                ref={this.dropdownRef}
              >
                <Input
                  id="banner-input"
                  className="inputField"
                  disableUnderline
                  autoComplete="off"
                  placeholder="Enter procedure or code..."
                  value={this.state.procedureName}
                  onChange={(e) => this.procedureValue(e)}
                  startAdornment={
                    <InputAdornment
                      sx={{ paddingLeft: "6px" }}
                      position="start"
                    >
                      <img
                        src={Searchimg}
                        alt="search"
                        style={{ width: "100%" }}
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    this.state.searchClose ? (
                      <Button
                        onClick={() => this.clearAll()}
                        type="submit"
                        style={{ position: "absolute", right: "0px" }}
                      >
                        <CloseIcon
                          sx={{ color: "#59B04B", fontStyle: "none" }}
                        />
                      </Button>
                    ) : (
                      ""
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      this.getAllProcedureData();
                    }
                  }}
                />

                {this.state.procedureOptions.length > 0 &&
                this.state.optionDisplay ? (
                  <List className="procedure-list">
                    {this.state.procedureOptions?.map((option) => (
                      <ListItem
                        key={option.uuid}
                        button
                        onClick={() => this.handleProcedureOptionClick(option)}
                      >
                        <ListItemText primary={option.display} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  ""
                )}
              </div>

              <Button
                className="search-ppp-pages"
                onClick={() => this.getAllProcedureData()}
              >
                Search
              </Button>
            </div>
            <div className="search-result__content">
              <div className="alphabet-search">
                <Card
                  variant="outlined"
                  className="card-listing__bottomHandler"
                >
                  <CardContent sx={{ paddingBottom: "18px !important" }}>
                    <button
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                      onClick={() => this.selectedAlphabet("All")}
                      className={
                        this.state.selectedAlphabet === "All" ? "active" : ""
                      }
                    >
                      ALL
                    </button>
                    {this.state.alphabets.map((letter) => (
                      <button
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          fontStyle: "normal",
                        }}
                        key={letter.letter}
                        onClick={() => this.selectedAlphabet(letter.letter)}
                        className={
                          this.state.selectedAlphabet === letter.letter
                            ? "active"
                            : ""
                        }
                      >
                        {letter.letter}
                      </button>
                    ))}
                  </CardContent>
                </Card>
              </div>
            </div>
          </Container>
          <Container className="container-common">
            <Card variant="outlined" className="card-listing__topHandler">
              {this.state.alphabets.length < 0 && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "center",
                    color: "#59B04B",
                    height: "123px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  NO DATA FOUND
                </Grid>
              )}
              {this.state.procedureDatas.length <= 0 && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#45A736",
                    padding: "15px",
                  }}
                >
                  View the procedures by clicking above alphabets
                </div>
              )}
            </Card>
          </Container>
          {this.state.procedureDatas.length > 0 && (
            <div style={{ background: "#fff", marginBottom: "2.5rem" }}>
              <Container className="container-common">
                <Card variant="outlined" className="card-listing__topHandler">
                  <CardContent>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        {this.state.procedureDatas.map((item, index) => (
                          <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            key={index}
                            sx={{ paddingBottom: "16px" }}
                          >
                            <div
                              key={index}
                              onClick={() =>
                                this.handleProcedureItemClick(item)
                              }
                              className="all-inner"
                            >
                              <div>{item.display}</div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </CardContent>
                  <Pagination
                    count={Math.ceil(
                      this.state.parameters?.count /
                        this.state.parameters?.pageSize
                    )}
                    page={this.state.parameters?.page}
                    onChange={this.handlePageChange}
                  />
                </Card>
              </Container>
            </div>
          )}
        </div>
        <AppFooter value={false} />
      </>
    );
  }
}

export default withRouter(Procedures);
