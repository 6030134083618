import axios from "axios";
import { REACT_APP_API_GOOGLE_MAP_KEY } from "../utils/Services/Env";

const GeolocationInfo = async (latitude, longitude) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${REACT_APP_API_GOOGLE_MAP_KEY}`
    );
    if (response.data.results.length > 0) {
      const addressComponents = response.data.results[0].address_components;
      const city = addressComponents.find((component) =>
        component.types.includes("locality")
      );
      const state = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const country = addressComponents.find((component) =>
        component.types.includes("country")
      );
      return {
        status: 200,
        city: city ? city.long_name : "",
        state: state ? state.short_name : "",
        country: country ? country.short_name : "",
      };
    }
    return { status: 400, error: "Error fetching geolocation data" };
  } catch (error) {
    return { status: 500, error: "Error fetching geolocation data" };
  }
};

export default GeolocationInfo;
