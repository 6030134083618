import { Box, Button, Paper } from "@mui/material";
import React, { useState, useEffect } from "react";
import "../Assets/css/PayerInfoTable.css";
import Env from "../utils/Services/Env";
import { useDispatch, useSelector } from "react-redux";
import { payerHeaderInfo } from "./reducer/action";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function PayerInfoTable({ title, name }) {
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [table_Data, setTableData] = useState();
  const [payerPerm, setPayerPerm] = useState();
  const [payerContractP, setPayerContractP] = useState(1);
  const [pageLength, setPageLength] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const stateInfo = useSelector((state) => state.searchLoc);

  useEffect(() => {
    const getData = Env.get(
      `payer/${
        title === "Insurance Products" ? "plans" : "providers"
      }/${name}?page=${currentPage}&state=${stateInfo}&page_size=${itemsPerPage}`
    );

    getData
      .then((res) => {
        const date = {
          healthSystems: res.data.parameters.healthSystems,
          providers: res.data.parameters.total_count,
        };
        if (title !== "Insurance Products") {
          dispatch(payerHeaderInfo(date));
          setPayerContractP(res.data.parameters.page);
          setPageLength(res.data.parameters.total_count);
        } else {
          setPayerPerm(res.data.parameters);
        }
        setTableData(res.data);
        setIsNextDisabled(res.data.data.length < itemsPerPage);
      })
      .catch((err) => console.error(err));
  }, [name, currentPage, itemsPerPage, title, stateInfo, dispatch]);

  const handleContractPageChange = (type) => {
    const newPage = type === "next" ? payerContractP + 1 : payerContractP - 1;

    const fetchPageData = (page) => {
      return Env.get(
        `payer/providers/${name}?page=${page}&state=${stateInfo}&page_size=${itemsPerPage}`
      );
    };

    fetchPageData(newPage)
      .then((res) => {
        if (res.data.data.length === 0 && newPage > 1) {
          setIsNextDisabled(true);
          fetchPageData(payerContractP)
            .then((prevRes) => {
              const prevData = prevRes.data.data;
              const prevParameters = prevRes.data.parameters;
              const headerInfo = {
                healthSystems: prevParameters.healthSystems,
                providers: prevParameters.total_count,
              };

              setTableData(prevRes.data);
              setPayerContractP(payerContractP);
              setPageLength(prevParameters.total_count);
              dispatch(payerHeaderInfo(headerInfo));
              setIsNextDisabled(prevData.length < itemsPerPage);
            })
            .catch((err) => console.error(err));
        } else {
          const data = res.data.data;
          const parameters = res.data.parameters;
          const headerInfo = {
            healthSystems: parameters.healthSystems,
            providers: parameters.total_count,
          };

          setTableData(res.data);
          setPayerContractP(newPage);
          setPageLength(parameters.total_count);
          dispatch(payerHeaderInfo(headerInfo));
          setIsNextDisabled(data.length < itemsPerPage || newPage * itemsPerPage >= pageLength);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleInsurancePageChange = (type) => {
    let payerPerms =
      type === "next" ? payerPerm["next"] : payerPerm["previous"];

    const additionalParams = new URLSearchParams({
      [`${type}`]: payerPerms,
    });

    const getData = Env.get(
      `payer/plans/${name}?page=${currentPage}&state=${stateInfo}&page_size=${itemsPerPage}&${additionalParams.toString()}`
    );

    getData
      .then((res) => {
        setPayerPerm(res.data.parameters);
        setTableData(res.data);
      })
      .catch((err) => console.error(err));
  };

  if (!table_Data) {
    return null;
  }

  return (
    <>
      <Box sx={{ marginBottom: "50px" }}>
        <Box>
          <h2 className="table-title">{title}</h2>
          {table_Data.data.length > 0 && (table_Data.parameters?.page > 0 || table_Data.parameters?.page === undefined) ? (
            <>
              <Paper
                elevation={6}
                sx={{ borderRadius: "16px", overflow: "hidden" }}
              >
                <div className="table-responsive">
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody className="payerInfoTable">
                      <tr
                        style={{
                          backgroundColor: "#6AB95E",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        <th style={{ paddingLeft: "40px" }}>
                          {title === "Insurance Products"
                            ? "INSURANCE PRODUCT"
                            : "PROVIDER"}
                        </th>
                        <th>
                          {title === "Insurance Products"
                            ? "LINE OF BUSINESS"
                            : "LOCATION"}
                        </th>
                        <th>
                          {title === "Insurance Products"
                            ? "RATES AVAILABLE"
                            : "HEALTH SYSTEM"}
                        </th>
                      </tr>
                      {title !== "Insurance Products" &&
                        table_Data.data.map((data, i) => (
                          <tr key={i}>
                            <td>
                              <a
                                className="view-more"
                                href={`https://healthcaredollar.tech/providerinfo/${data.uuid}`}
                                // href={`https://hcddemo.site/providerinfo/${data.uuid}`}
                              >
                                {title === "Insurance Products"
                                  ? data.insuranceProduct
                                  : data.providerName}
                              </a>
                            </td>
                            <td>
                              {title === "Insurance Products"
                                ? data.category
                                : stateInfo
                                ? stateInfo
                                : data.location}
                            </td>
                            <td>
                              {title === "Insurance Products"
                                ? data.rate
                                : data.healthSystemName}
                            </td>
                          </tr>
                        ))}
                      {title === "Insurance Products" &&
                        table_Data.data.map((data, index) => (
                          <tr key={index}>
                            <td>{data.insuranceProduct}</td>
                            <td>{data.category}</td>
                            <td>{data.rate}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {title === "Insurance Products" && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px",
                      }}
                    >
                      <Button
                        disabled={payerPerm.previous === null}
                        style={{
                          color:
                            payerPerm.previous !== null
                              ? "#45A736"
                              : "rgba(0, 0, 0, 0.26)",
                        }}
                        onClick={() => handleInsurancePageChange("previous")}
                      >
                        <ArrowBackIosNewIcon />
                      </Button>
                      <Button
                        disabled={payerPerm.next === null}
                        style={{
                          color:
                            payerPerm.next !== null
                              ? "#45A736"
                              : "rgba(0, 0, 0, 0.26)",
                        }}
                        onClick={() => handleInsurancePageChange("next")}
                      >
                        <ArrowForwardIosIcon />
                      </Button>
                    </Box>
                  )}

                  {title === "Contracted Providers" && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px",
                      }}
                    >
                      <Button
                        disabled={payerContractP <= 1}
                        style={{
                          color: payerContractP > 1
                            ? "#45A736"
                            : "rgba(0, 0, 0, 0.26)",
                        }}
                        onClick={() => handleContractPageChange("previous")}
                      >
                        <ArrowBackIosNewIcon />
                      </Button>
                      <Button
                        disabled={isNextDisabled}
                        style={{
                          color: !isNextDisabled
                            ? "#45A736"
                            : "rgba(0, 0, 0, 0.26)",
                        }}
                        onClick={() => handleContractPageChange("next")}
                      >
                        <ArrowForwardIosIcon />
                      </Button>
                    </Box>
                  )}
                </div>
              </Paper>
            </>
          ) : table_Data.data.length === 0 && payerContractP > 1 ? (
            <>
              <Paper
                elevation={6}
                sx={{ borderRadius: "16px", overflow: "hidden" }}
              >
                <div className="table-responsive">
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody className="payerInfoTable">
                      <tr
                        style={{
                          backgroundColor: "#6AB95E",
                          color: "white",
                          textAlign: "left",
                        }}
                      >
                        <th style={{ paddingLeft: "40px" }}>
                          {title === "Insurance Products"
                            ? "INSURANCE PRODUCT"
                            : "PROVIDER"}
                        </th>
                        <th>
                          {title === "Insurance Products"
                            ? "LINE OF BUSINESS"
                            : "LOCATION"}
                        </th>
                        <th>
                          {title === "Insurance Products"
                            ? "RATES AVAILABLE"
                            : "HEALTH SYSTEM"}
                        </th>
                      </tr>
                      {title !== "Insurance Products" &&
                        table_Data.data.map((data, i) => (
                          <tr key={i}>
                            <td>
                              <a
                                className="view-more"
                                href={`https://healthcaredollar.tech/providerinfo/${data.uuid}`}
                                // href={`https://hcddemo.site/providerinfo/${data.uuid}`}
                              >
                                {title === "Insurance Products"
                                  ? data.insuranceProduct
                                  : data.providerName}
                              </a>
                            </td>
                            <td>
                              {title === "Insurance Products"
                                ? data.category
                                : stateInfo
                                ? stateInfo
                                : data.location}
                            </td>
                            <td>
                              {title === "Insurance Products"
                                ? data.rate
                                : data.healthSystemName}
                            </td>
                          </tr>
                        ))}
                      {title === "Insurance Products" &&
                        table_Data.data.map((data, index) => (
                          <tr key={index}>
                            <td>{data.insuranceProduct}</td>
                            <td>{data.category}</td>
                            <td>{data.rate}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {title === "Insurance Products" && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px",
                      }}
                    >
                      <Button
                        disabled={payerPerm.previous === null}
                        style={{
                          color:
                            payerPerm.previous !== null
                              ? "#45A736"
                              : "rgba(0, 0, 0, 0.26)",
                        }}
                        onClick={() => handleInsurancePageChange("previous")}
                      >
                        <ArrowBackIosNewIcon />
                      </Button>
                      <Button
                        disabled={payerPerm.next === null}
                        style={{
                          color:
                            payerPerm.next !== null
                              ? "#45A736"
                              : "rgba(0, 0, 0, 0.26)",
                        }}
                        onClick={() => handleInsurancePageChange("next")}
                      >
                        <ArrowForwardIosIcon />
                      </Button>
                    </Box>
                  )}

                  {title === "Contracted Providers" && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "10px",
                      }}
                    >
                      <Button
                        disabled={payerContractP <= 1}
                        style={{
                          color: payerContractP > 1
                            ? "#45A736"
                            : "rgba(0, 0, 0, 0.26)",
                        }}
                        onClick={() => handleContractPageChange("previous")}
                      >
                        <ArrowBackIosNewIcon />
                      </Button>
                      <Button
                        disabled={isNextDisabled}
                        style={{
                          color: !isNextDisabled
                            ? "#45A736"
                            : "rgba(0, 0, 0, 0.26)",
                        }}
                        onClick={() => handleContractPageChange("next")}
                      >
                        <ArrowForwardIosIcon />
                      </Button>
                    </Box>
                  )}
                </div>
              </Paper>
            </>
          ) : (
            <p className="not-available">{title} are not available</p>
          )}
        </Box>
      </Box>
    </>
  );
}

export default PayerInfoTable;
