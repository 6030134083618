import AxiosInstance from "./Interceptor";

// export const apiUrl = "https://hcddemo.site/api/v1/";
export const apiUrl = "https://healthcaredollar.tech/api/v1/";
// export const apiUrl = 'http://localhost:3000/v1/';

export const REACT_APP_API_GOOGLE_MAP_KEY =
  "AIzaSyCDi6B-TsEAJr25xrC2-UlGfwvTnUSIm9g";

const Env = {
  async get(path, header) {
    const api = await AxiosInstance.get(`${apiUrl}${path}`, {});
    return api;
  },

  async post(path, payload, header) {
    const api = await AxiosInstance.post(`${apiUrl}${path}`, payload, {});
    return api;
  },

  async put(path, payload, header) {
    const api = await AxiosInstance.put(`${apiUrl}${path}`, payload, {});
    return api;
  },

  async delete(path, header) {
    const api = await AxiosInstance.delete(`${apiUrl}${path}`, {});
    return api;
  },
};

export default Env;
