import React, { useEffect, useState } from "react";
import AppTitle from "../AppLayout/AppTitle";
import AppFooter from "../AppLayout/AppFooter";

import Box from "@mui/material/Box";
import { Avatar, Divider, Paper, Typography } from "@mui/material";

import PayerInfoTable from "../PayerInfoTable";
import idCard from "../../Assets/Static/id 1.svg";
import building from "../../Assets/Static/building 1.svg";
import provider from "../../Assets/Static/hand-holding-medical 1.svg";
import locationIcon from "../../Assets/Static/loc1.svg";

import business from "../../Assets/Static/doctor 1.svg";
import Env from "../../utils/Services/Env";
import { useParams, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

// blue-cross-blue-shield-of-michigan
function PayersInfo() {
  const { name } = useParams();
  const navigate = useNavigate();

  const [payerInfo, setPayerInfo] = useState();

  const payerHeaderInfo = useSelector((state) => state.payerHeaderInfo);
  const stateInfo = useSelector((state) => state.searchLoc);

  useEffect(() => {
    const getPayerInfo = Env.get(`/payer/info/${name}?state=${stateInfo}`);
    getPayerInfo
      .then((res) => setPayerInfo(res.data.data))
      .catch((err) => navigate("*"));
  }, [name, navigate]);

  if (!payerInfo) {
    return null;
  }

  return (
    <>
      <AppTitle value={false} />

      <Box sx={{ backgroundColor: "#F5F5F5" }}>
        <Container className="container-common">
          <Box
            sx={{
              backgroundColor: "#F5F5F5",
            }}
          >
            <h4 className="payer-title">Payer Info</h4>
            <div
              style={{
                display: "flex",
                gap: "30px",
                marginBottom: "1.5rem",
              }}
              className="avatar-text"
            >
              <Paper
                sx={{
                  borderRadius: "50%",
                  width: "90px",
                  height: "90px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                }}
                className="avatar-center"
              >
                <Avatar
                  sx={{
                    backgroundColor: "#45A736",
                    width: "60px",
                    height: "60px",
                    fontSize: "35px",
                  }}
                >
                  {payerInfo.name.slice(0, 1).toUpperCase()}
                </Avatar>
              </Paper>{" "}
              <Box sx={{ paddingTop: "15px" }}>
                <Typography className="payer-name">
                  {!payerInfo.state
                    ? payerInfo.name
                    : `${payerInfo.name} of ${payerInfo.state}`}
                </Typography>
              </Box>
            </div>
            <Divider
              sx={{
                borderColor: "#AFC3C8",
                marginBottom: "1rem",
                borderWidth: "1px",
              }}
            />

            <Grid
              container
              spacing={2}
              sx={{
                width: "100%",
                margin: "0px",
                justifyContent: "space-between",
                paddingBottom: "1.5rem",
              }}
            >
              <Grid
                item
                lg={2.2}
                md={4}
                sm={6}
                xs={12}
                sx={{ paddingLeft: "0px !important" }}
              >
                <div className="payer-hios">
                  <img src={idCard} alt="Address icon" width={30} height={30} />
                  <Box>
                    <Typography gutterBottom className="hios-text">
                      HIOS ISSUER ID
                    </Typography>
                    <Typography gutterBottom className="hios-value">
                      -
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                lg={2.5}
                md={4}
                sm={6}
                xs={12}
                sx={{ paddingLeft: "0px !important" }}
              >
                <div className="payer-hios">
                  <img
                    src={building}
                    alt="Address icon"
                    width={30}
                    height={30}
                  />
                  <Box>
                    <Typography gutterBottom className="hios-text">
                      HEALTH SYSTEM
                    </Typography>
                    <Typography gutterBottom className="hios-value">
                      {payerHeaderInfo?.healthSystems}
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                lg={1.8}
                md={4}
                sm={6}
                xs={12}
                sx={{ paddingLeft: "0px !important" }}
              >
                <div className="payer-hios">
                  <img
                    src={locationIcon}
                    alt="Address icon"
                    width={30}
                    height={30}
                  />
                  <Box>
                    <Typography gutterBottom className="hios-text">
                      MARKET
                    </Typography>
                    <Typography gutterBottom className="hios-value">
                      {payerInfo.market}
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                lg={2.2}
                md={4}
                sm={6}
                xs={12}
                sx={{ paddingLeft: "0px !important" }}
              >
                <div className="payer-hios">
                  <img
                    src={provider}
                    alt="Address icon"
                    width={30}
                    height={30}
                  />
                  <Box>
                    <Typography gutterBottom className="hios-text">
                      PROVIDERS
                    </Typography>
                    <Typography gutterBottom className="hios-value">
                      {payerHeaderInfo?.providers}
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                lg={2.2}
                md={8}
                sm={6}
                xs={12}
                sx={{ paddingLeft: "0px !important" }}
              >
                <div className="payer-hios" style={{ borderRight: "0px" }}>
                  <img
                    src={business}
                    alt="Address icon"
                    width={30}
                    height={30}
                  />
                  <Box>
                    <Typography gutterBottom className="hios-text">
                      LINE OF BUSINESS
                    </Typography>

                    {payerInfo.lineOfBusiness.slice(0,3).map((data, i) => (<>
                      <Typography key={i} className="hios-valu" gutterBottom>
                        {data}
                      </Typography>
                      {i === 2 && <a href="#Insurance-Products">more...</a>}</>
                    ))}
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Container className="container-common">
              <PayerInfoTable
          title="Contracted Providers"
          name={encodeURIComponent(name.toLowerCase())}
        />
        <Divider
          sx={{
            marginTop: "50px",
            borderColor: "#AFC3C8",
            marginBottom: "1rem",
            borderWidth: "1px",
          }}
        />
     <Box id='Insurance-Products'> <PayerInfoTable
          title="Insurance Products"
          name={encodeURIComponent(name.toLowerCase())}
        /></Box>
      </Container>
      <AppFooter value={false} />
    </>
  );
}

export default PayersInfo;
