import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import NewBanner from "../../src/Assets/Static/Doctor.png";
import { apiUrl } from "../utils/Services/Env";
import { clearStore, userName } from "../components/reducer/action";
import { connect } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const UserProfile = ({ open, onClose, onIndicator }) => {
  const [openPopup, setOpen] = React.useState(false);
  const uid = useSelector((state) => state.uid);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseDelete = () => {
    setOpen(false);
  };

  // const uid = localStorage.getItem("uid");
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    profilePicture: "",
  });
  const [errors, setErrors] = useState({
    phoneNumber: "",
  });
  // Ref for the hidden file input
  const inputFileRef = React.useRef(null);

  // Function to trigger file input click
  const handleCameraClick = () => {
    inputFileRef.current.click();
  };
  const [message, setMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [success, setSuccess] = useState(false);
  const [imageUploaded, setImageUploaded] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    if (uid) {
      getUserProfile();
    }
  }, []);

  const getUserProfile = async () => {
    try {
      const res = await axios.get(`${apiUrl}user/${uid}`);
      if (res.data?.status === 200 && res.data?.userStatus !== 2) {
        setProfile(res.data.data);
        dispatch(userName(res.data.data.firstName));
        if (res.data.data.profilePicture) {
          setImageUploaded(res.data.data.profilePicture);
        } else {
          setImageUploaded("");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (uid) {
      getUserProfile();
    }
  }, [uid]);

  const handleSave = async () => {
    try {
      let errors = {};
      if (profile.phoneNumber) {
        if (isNaN(profile.phoneNumber)) {
          errors.phoneNumber = "mobile number must be a number";
        } else if (profile.phoneNumber.length !== 10) {
          errors.phoneNumber =
            "mobile number must be exactly 10 characters long";
        }
      }
      // Set errors
      setErrors(errors);
      if (Object.keys(errors).length === 0) {
        const data = {
          firstName: profile.firstName,
          lastName: profile.lastName,
          // email: profile.email1 ,
          phoneNumber: profile.phoneNumber,
          // profilePicture: profile.profilePicture,
        };
        Object.keys(data).forEach((key) => {
          if (data[key] === null) {
            delete data[key];
          }
        });

        const results = await axios.put(`${apiUrl}user/${uid}`, data);
        if (results.data.status === 200) {
          setMessage(results.data.message);
          dispatch(userName(profile.firstName));
          getUserProfile();
          // setOpenAlert(true);
          setProfile(profile);
          setSuccess(true);
          // window.location.reload();
          onClose(results.data.message);
        } else {
          setMessage(results.data.message);
          setOpenAlert(true);
          setSuccess(false);
        }
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setOpenAlert(true);
      setSuccess(false);
    }
  };

  const handleChange = (e) => {
    setErrors({});
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleUploadClick = async (event) => {
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("profilePicture", file);
      const results = await axios.post(`${apiUrl}user/image/${uid}`, formData);
      if (results.data.profilePicture) {
        setImageUploaded(results.data.profilePicture);
        onIndicator(results.data.profilePicture);
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setOpenAlert(true);
      setSuccess(false);
    }
  };

  const handleDelete = async () => {
    try {
      const results = await axios.delete(`${apiUrl}user/${uid}`);
      if (results.data.status === 200) {
        setTimeout(() => {
          dispatch(clearStore());
        }, 1000);
        // localStorage.clear();
        setMessage(results.data.message);
        // setOpenAlert(true);
        setSuccess(false);
        setOpen(false);
        // setImageUploaded("");
        // window.location.reload();
        onClose("Account deleted successfully", 200);
      } else {
        setMessage(results.data.message);
        setOpenAlert(true);
        setSuccess(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <>
      <Dialog
        onClose={handleCloseDelete}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
        // className="provide-popup"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle
          sx={{ m: 0, p: 2, display: "flex", justifyContent: "center" }}
          id="customized-dialog-title"
        >
          Account Delete
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDelete}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
            }}
          >
            Are you sure you want to delete?
          </span>
          <br />
          <div style={{ marginTop: "50px" }}>
            <Button
              style={{
                backgroundColor: "white",
                color: "#45A736",
                paddingLeft: "30px",
                paddingRight: "30px",
                border: "1px solid #45A736",
                borderRadius: "10px",
                height: "50px",
                position: "absolute",
                left: 8,
                bottom: 8,
              }}
              onClick={handleCloseDelete}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#45A736",
                color: "White",
                borderRadius: "10px",
                height: "50px",
                position: "absolute",
                paddingLeft: "30px",
                paddingRight: "30px",
                right: 8,
                bottom: 8,
              }}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </DialogContent>
        {/* <DialogActions>
          
        </DialogActions> */}
      </Dialog>
      <Dialog
        open={open}
        onClose={() => {
          getUserProfile();
          setErrors({});
          onClose();
        }}
        className="pofile-popup"
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            getUserProfile();
            setErrors({});
            onClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openAlert}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={success === true ? "success" : "error"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {`${message}`}
          </Alert>
        </Snackbar>
        <div>
          <DialogContent>
            <DialogTitle style={{ display: "flex", justifyContent: "center" }}>
              <div className="photo-camera">
                <Avatar
                  src={`https://healthcaredollar.tech/api/images/${imageUploaded}`}
                  // src={`https://hcddemo.site/api/images/${imageUploaded}`}
                  crossOrigin="anonymous"
                  sx={{ height: "150px", width: "150px" }}
                />
                {/* <Avatar alt="person_missing" src={person} variant="circle" /> */}

                <IconButton
                  aria-label="upload picture"
                  component="span"
                  multiple
                  type="file"
                  onClick={handleCameraClick}
                  // onClick={handleUploadClick}
                  className="photo-camera-green"
                >
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleUploadClick}
                    ref={inputFileRef}
                  />
                  <PhotoCameraIcon fontSize="large" />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogTitle style={{ padding: 0, fontSize: "12px" }}>
              First Name
            </DialogTitle>
            <TextField
              fullWidth
              margin="normal"
              size="small"
              onChange={handleChange}
              defaultValue={profile.firstName}
              name="firstName"
              InputProps={{
                sx: {
                  color: "rgba(79, 116, 89, 1)", // Text color
                  borderColor: "transparent",
                  background: "rgba(69, 167, 54, 0.05)", // Background color
                  //   "&::placeholder": {
                  //     color: "#4F7459", // Placeholder color
                  //   },
                },
              }}
            />
            <DialogTitle style={{ padding: 0, fontSize: "12px" }}>
              Last Name
            </DialogTitle>
            <TextField
              fullWidth
              margin="normal"
              size="small"
              onChange={handleChange}
              defaultValue={profile.lastName}
              name="lastName"
              InputProps={{
                sx: {
                  color: "rgba(79, 116, 89, 1)", // Text color
                  borderColor: "transparent",
                  background: "rgba(69, 167, 54, 0.05)", // Background color
                  //   "&::placeholder": {
                  //     color: "#4F7459", // Placeholder color
                  //   },
                },
              }}
            />
            <DialogTitle
              style={{ padding: 0, fontSize: "12px", fontWeight: "1000px" }}
            >
              Email
            </DialogTitle>
            <TextField
              fullWidth
              disabled
              margin="normal"
              size="small"
              name="email1"
              onChange={handleChange}
              defaultValue={profile.email || ""}
              InputProps={{
                sx: {
                  color: "rgba(79, 116, 89, 1)", // Text color
                  borderColor: "transparent",
                  background: "rgba(69, 167, 54, 0.05)", // Background color
                  //   "&::placeholder": {
                  //     color: "#4F7459", // Placeholder color
                  //   },
                },
              }}
            />
            <DialogTitle style={{ padding: 0, fontSize: "12px" }}>
              Mobile Number
            </DialogTitle>
            <TextField
              fullWidth
              margin="normal"
              size="small"
              type="phoneNumber"
              name="phoneNumber"
              onChange={handleChange}
              defaultValue={profile.phoneNumber || ""}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              InputProps={{
                sx: {
                  color: "rgba(79, 116, 89, 1)", // Text color
                  borderColor: "transparent",
                  background: "rgba(69, 167, 54, 0.05)", // Background color
                  //   "&::placeholder": {
                  //     color: "#4F7459", // Placeholder color
                  //   },
                },
              }}
            />
            <Button
              fullWidth
              style={{
                backgroundColor: "#45A736",
                color: "White",
                marginBottom: "10px",
                marginTop: "30px",
                borderRadius: "10px",
                height: "50px",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              fullWidth
              style={{
                backgroundColor: "white",
                color: "#45A736",
                marginBottom: "10px",
                marginTop: "5px",
                border: "1px solid #45A736",
                borderRadius: "10px",
                height: "50px",
              }}
              onClick={handleClickOpen}
            >
              Delete Account
            </Button>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

function mapDispatchToProps(state) {
  return {
    uName: state.userName,
  };
}

export default connect(mapDispatchToProps)(UserProfile);
