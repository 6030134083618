import React from "react";
// import ComingSoon from "../../components/pages/ComingSoon";
import {Grid, Container} from "@mui/material";
import AppTitle from "../AppLayout/AppTitle";
import AppFooter from "../AppLayout/AppFooter";
import partner from '../../Assets/Static/partner.png'

const partnersWithUs = () => {
  return (
    <>
      {/* <ComingSoon /> */}
      <AppTitle value={false} />
      <Container className="container-common">
      <Grid item xs={12} sm={12} md={12}>
              <h2
                style={{
                  fontWeight: "900",
                  fontSize: "38px",
                  letterSpacing: "2px",
                }}
              >
                Partner With Us
              </h2>
              {/* <Grid container className="about-div">
              <Grid item lg={6} xs={12} md={12} > */}
               <img className="aboutUsImg" src={partner} alt="partner" />
              <p className="aboutUs-para">
              If you looking for ways to provide your employees with benefits that can help leverage healthcare costs you should think about partnering with Healthcare Dollar.  We aim at empowering consumers by offering information on costs related to elective medical procedures. Whether you’re considering cosmetic surgery, dental work, or other elective treatments, Healthcare Dollar’s app can help you make informed decisions by providing transparent pricing information.   Please reach out to our sales team at sales@healthcaredollar.tech to see how you can provide healthcare options for your team.
              </p>
              {/* </Grid> */}
              {/* <Grid item lg={6}> */}
             
              {/* </Grid> */}
              {/* </Grid> */}
            </Grid>
            </Container>
            <AppFooter value={false} />
    </>
  );
};

export default partnersWithUs;
