import React from "react";
import AppTitle from "../AppLayout/AppTitle";
import Browse from "../Browse";
import AppFooter from "../AppLayout/AppFooter";
import Env from "../../utils/Services/Env";
import { IconButton } from "@mui/material";
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Input,
  InputAdornment,
  // Select,
  // MenuItem,
  Card,
  CardContent,
  Divider,
  // Pagination,
  Box,
  Grid, CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../Assets/css/Search.css";
import "../../Assets/css/AlphabetFilter.css";
import "../../Assets/css/Pagination.css";
import "../../Assets/css/PaginationStyle.css";
import Container from "@mui/material/Container";
import Searchimg from "../../Assets/Static/banner-search.svg";
import withRouter from "../Router/withRouter";
import { connect } from "react-redux";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { searchLocation } from "../reducer/action";
import { toast } from "react-toastify";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

class Providers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        total_count: 0,
        speciality: "",
        page: 0,
        page_size: 15,
      },
      stateList: [],
      providerData: [],
      providerOptions: [],
      //alphabets: [],
      stateName: "",
      providerName: "",
      hospitalName: "",
      selectedAlphabet: "All",
      searchClose: false,
      optionDisplay: false,
      selectedItem: "",
      locationOptions: false,
      showByAddress: false,
      showList: false,
      totalCount: 0,
      isLoading: false,
      providerPage: 1,
      isNextDisabled: false,
      isPrevDisabled: true,
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    const { location, searchLoc } = this.props;
    if (location !== null) {
      this.handleLocation("", location === undefined ? searchLoc : location);

      this.setState(
        {
          stateName: location === undefined ? searchLoc : location,
        },
        () => {

          if (location !== null) {
            this.getAllProviderData(
              1,
              location === undefined ? searchLoc : location
            );
          }
        }
      );
    } else {
      const local = searchLoc === "" || searchLoc === null ? location : searchLoc
      this.handleLocation("", local);

      this.getAllProviderData("1", location === undefined ? searchLoc : location)
    }
    window.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.dropdownRef && !this.dropdownRef.current?.contains(event.target)) {
      // this.state.procedureOptions &&
      //   this.setState({
      //     procedureOptions: false,
      //     procedureName: "",
      //   });
      this.state.locationOptions &&
        this.setState({
          locationOptions: false,
          stateName: "",
        });
    }
  };

  providerValue = (e) => {
    this.setState({
      providerName: e.target.value,
      optionDisplay: e.target.value?.length > 2 ? true : false,
      isLoading: true,
    });
    if (e.target.value?.length > 0) {
      this.setState({
        searchClose: true,
      });
    } else {
      this.setState({
        searchClose: false,
      });
    }

    // if (e.target.value?.length > 2) {

    let stateVal
    if (this.state.stateName !== "" || this.state.stateName !== undefined || this.state.stateName || null) {
      stateVal = this.state.stateName
    } else {
      stateVal = this.props.location === undefined || this.props.location === "" || this.props.location === null ? this.props.searchLoc : this.props.location;

    }

    const getSearchProvider = Env.get(
      `provider/providerlist-from-ribbon?name=${e.target.value}&address=${stateVal}`
    );

    getSearchProvider.then(
      (response) => {
        this.setState({
          providerOptions: response.data.result,
          optionDisplay: true,
          isLoading: false,
        });
      },
      (error) => {
        console.error(error);
      }
    );
    // }
  };

  getAllProviderData = (pageNo, state) => {
    this.setState({
      isLoading: true,
    })
    if (this.state.optionDisplay) {
      this.setState({
        optionDisplay: false,
      });
    }
    state =
      this.state.stateName !== "" && this.state.stateName !== undefined
        ? this.state.stateName === "" ? this.props.location === undefined || this.props.location === null || this.props.location === '' ? this.props.searchLoc : this.props.location : this.state.stateName
        : state === undefined || state === "" || state === null
          ? this.state.stateName === "" ? this.props.location === undefined || this.props.location === null || this.props.location === '' ? this.props.searchLoc : this.props.location : state : state

    if (state === "") {
      toast.error("Select location..");
      return;
    }
    pageNo = this.state.totalCount === 1 ? "1" : pageNo;
    let url = `provider/providerlist-from-ribbon?`;


    if (
      this.state.providerName !== "" &&
      state !== "" &&
      pageNo === undefined
    ) {
      url = `${url}name=${this.state.providerName}&address=${state}&page=${pageNo === undefined ? 1 : pageNo
        }&pageSize=30`;
    } else if (
      this.state.providerName === "" &&
      state !== "" &&
      pageNo === undefined
    ) {
      url = `${url}&address=${state}&page=${pageNo === undefined ? 1 : pageNo
        }&pageSize=30`;
    } else if (
      this.state.providerName === "" &&
      pageNo !== "" &&
      state !== ""
    ) {
      url = `${url}address=${state}&page=${pageNo}&pageSize=30`;
    }

    const getProviderData = Env.get(url);

    getProviderData.then((response) => {
      const getData = response.data;

      this.setState({
        providerData: getData.result,
        parameters: getData.parameters,
        totalCount: getData.parameters?.total_count,
        isLoading: false,
        providerPage: getData.parameters?.page,
        isNextDisabled: getData.parameters?.total_count <= getData.parameters?.page * 30,
        isPrevDisabled: getData.parameters?.page === 1,
      });
    });
  };

  handleStateChange = (e) => {
    this.setState(
      {
        stateName: e.target.value,
      },
      () => {
        this.getAllProviderData("", this.state.stateName);
      }
    );
    this.handleLocation(e.target.value, "");
  };

  handleProviderOptionClick = (option) => {
    this.setState({
      providerName: option.name,
      optionDisplay: false,
    });
  };

  handleProviderItemClick = (item) => {
    this.props.navigate(`/providerinfo/${item.uuid}`);
  };

  selectedProviderAlphabet = (data) => {
    this.setState(
      {
        selectedAlphabet: data,
      },
      () => {
        this.getAllProviderData("", this.state.selectedAlphabet);
      }
    );
  };

  handlePageChange = (value) => {
    const { providerPage } = this.state;
    const newPage = value === "next" ? providerPage + 1 : providerPage - 1;
    // this.getAllProviderDataPage(newPage);
    this.getAllProviderData(newPage)
  };

  clearAll = () => {
    const location = this.props.location;
    const searchLoc = this.props.searchLoc;

    let textAfterComma = "";

    if (location !== null && location !== undefined) {
      textAfterComma = location;
    } else if (searchLoc !== null && searchLoc !== undefined) {
      textAfterComma = searchLoc;
    }

    this.setState(
      {
        providerName: "",
        searchClose: false,
        optionDisplay: false,
        selectedAlphabet: "All",
        stateName: textAfterComma,
      },
      () => {
        this.getAllProviderData("1", "All");
      }
    );
  };

  handleLocation = (e, loc) => {
    let val;
    if (loc === "" || loc === undefined) {
      val = e.target.value;

      // if (typeof val === "string" && val !== undefined) {
      //   const { dispatch } = this.props;
      //   dispatch(searchLocation(val));
      // }
    }

    const locValue = {
      location: val === undefined ? loc : val,
    };
    const getSearchProvider = Env.post(`location/getLocation`, locValue);

    getSearchProvider.then(
      (response) => {
        this.setState(
          {
            stateList: response.data.data,
          },
          // () => this.getAllProviderData(1, response.data.data)
        );
      },
      (error) => {
        console.error(error);
      }
    );
    this.setState({
      stateName: val === undefined ? loc : val,
      locationOptions: val === undefined ? false : true,
    });
  };

  handleLocationOptionClick = (option) => {
    const { dispatch } = this.props;

    this.setState({
      stateName: option.label,
      locationOptions: false,
    });
    dispatch(searchLocation(option.label));
  };

  render() {
    return (
      <>
        <AppTitle value={false} />
        <div style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
          <Container className="container-common">
            <Browse selectTab={1} />
            <div className="input-container__search" ref={this.dropdownRef}>
              <div
                className="dropdown-list prod-search"
                style={{ position: "relative", width: "60%" }}
              >
                <Input
                  id="banner-input"
                  sx={{ width: "100%" }}
                  disableUnderline
                  autoComplete="off"
                  placeholder="Enter a provider or hospital name..."
                  value={this.state.providerName}
                  onChange={(e) => this.providerValue(e)}
                  startAdornment={
                    <InputAdornment
                      sx={{ paddingLeft: "6px" }}
                      position="start"
                    >
                      <img
                        src={Searchimg}
                        alt="search"
                        style={{ width: "100%" }}
                      />
                    </InputAdornment>
                  }
                  endAdornment={
                    this.state.searchClose ? (
                      /*    <Button
                        onClick={() => this.clearAll()}
                        type="submit"
                        style={{ position: "absolute", right: "0px" }}
                      >
                        <CloseIcon
                          sx={{ color: "#59B04B", fontStyle: "none" }}
                        />
                      </Button> */
                      <IconButton
                        onClick={this.clearAll} // Map onClick event to clearAll function
                        style={{ position: "absolute", right: "0px" }}
                        size="small"
                      >
                        <CloseIcon sx={{ color: "#59B04B" }} />
                      </IconButton>
                    ) : (
                      ""
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      this.getAllProviderData();
                    }
                  }}
                />
                {this.state.providerOptions?.length > 0 &&
                  this.state.optionDisplay ? (
                  <List className="procedure-list">
                    {this.state.providerOptions?.map((option, index) => (
                      <ListItem
                        key={index}
                        button
                        onClick={() => this.handleProviderOptionClick(option)}
                      >
                        <ListItemText primary={option.name} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  ""
                )}
              </div>

              <Divider
                orientation="vertical"
                sx={{ marginTop: "3px", height: "30px", color: "#000" }}
              />

              <div className="provider-search">
                <Input
                  id="input-with-icon-textfield"
                  sx={{
                    marginTop: "2px",
                    paddingLeft: "34px",
                    border: "none !important",
                    color: "#000",
                    width: "350px",
                    fontWeight: "500",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  disableUnderline
                  autoComplete="off"
                  value={this.state.stateName}
                  onFocus={() => {
                    this.setState({
                      showList: false,
                      showByAddress: true,
                    });
                  }}
                  onChange={(e) => this.handleLocation(e)}
                  placeholder="Location"
                  startAdornment={
                    <InputAdornment position="start" sx={{ color: "#000" }}>
                      <FmdGoodOutlinedIcon sx={{ color: "#000" }} />
                    </InputAdornment>
                  }
                />
                {this.state.locationOptions &&
                  this.state.stateList?.length > 0 && (
                    <List
                      sx={{
                        position: "absolute",
                        top: "228px",
                        // left: "1133px",
                        backgroundColor: "#f5f5f5",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        border: "1px solid #ccc",
                        borderTop: "none",
                        borderRadius: "22px",
                        marginLeft: "10px",
                        maxHeight: "150px",
                        overflowY: "auto",
                        color: "#000",
                        width: "362px",
                      }}
                    >
                      {this.state.stateList?.map((option) => (
                        <ListItem
                          key={option.id}
                          button
                          onClick={() => this.handleLocationOptionClick(option)}
                        >
                          <ListItemText primary={option.label} />
                        </ListItem>
                      ))}
                    </List>
                  )}
              </div>
              <Button
                className="search-ppp-pages"
                onClick={() => this.getAllProviderData()}
              >
                Search
              </Button>
            </div>
          </Container>
          {this.state.providerData?.length <= 0 && (
            <Container className="container-common">
              <Card variant="outlined" className="card-listing__topHandler">
                {this.state.isLoading ? (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ paddingBottom: "16px", height: '600px' }}
                  >
                    <CircularProgress color="success" />
                  </Grid>
                ) : (<Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "center",
                    color: "#59B04B",
                    height: "123px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  NO DATA FOUND
                </Grid>)}
              </Card>
            </Container>
          )}
          {this.state.providerData?.length > 0 && (
            <div style={{ background: "#fff", marginBottom: "2.5rem" }}>
              <Container className="container-common">
                <Card variant="outlined" className="card-listing__topHandler">
                  <CardContent>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        {this.state.providerData?.length > 0 ? (
                          this.state.isLoading ? (
                            <Grid
                              item
                              container
                              justifyContent="center"
                              alignItems="center"
                              sx={{ paddingBottom: "16px", height: '600px' }}
                            >
                              <CircularProgress color="success" />
                            </Grid>
                          ) :
                            (!this.state.isLoading && this.state.providerData.map((item, index) => (
                              <Grid
                                item
                                md={4}
                                sm={6}
                                xs={12}
                                key={index}
                                sx={{ paddingBottom: "16px" }}
                              >
                                <div
                                  key={item.uuid}
                                  onClick={() =>
                                    this.handleProviderItemClick(item)
                                  }
                                  className="all-inner"
                                >
                                  <div>
                                    {item.name === null ? "NULL" : item.name}
                                  </div>
                                </div>
                              </Grid>
                            )))
                        ) : (
                          <>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                color: "#59B04B",
                                height: "123px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              NO DATA FOUND
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Box>
                  </CardContent>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                    }}
                  >
                    <Button
                      disabled={this.state.isPrevDisabled}
                      style={{
                        color: this.state.isPrevDisabled
                          ? "rgba(0, 0, 0, 0.26)" : "#45A736"
                      }}
                      onClick={() => this.handlePageChange("previous")}
                    >
                      <ArrowBackIosNewIcon />
                    </Button>
                    <Button
                      disabled={this.state.isNextDisabled}
                      style={{
                        color: !this.state.isNextDisabled
                          ? "#45A736"
                          : "rgba(0, 0, 0, 0.26)",

                      }}
                      onClick={() => this.handlePageChange("next")}
                    >
                      <ArrowForwardIosIcon />
                    </Button>
                  </Box>
                </Card>
              </Container>
            </div>
          )}
        </div>
        <AppFooter value={false} />
      </>
    );
  }
}

export default withRouter(
  connect((state) => {
    return {
      payerName: state.payerName,
      location: state.location,
      searchLoc: state.searchLoc,
    };
  })(Providers)
);
