/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Input,
  Dialog,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircularProgress from "@mui/material/CircularProgress";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { REACT_APP_API_GOOGLE_MAP_KEY } from "../../utils/Services/Env";
import GoogleMapReact from "google-map-react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { apiUrl } from "../../utils/Services/Env";
import GeolocationInfo from "../../components/GeoLocation";
import Env from "../../utils/Services/Env";
import { connect } from "react-redux";
import { searchLocation } from "../reducer/action";

const LocationMarker = ({ lat, lng, name }) => (
  <Tooltip
    title={name ? name : "Individual/Clinic"}
    arrow
    placement="top-start"
  >
    <div style={{ color: "red", fontSize: "18px", display: "flex" }}>
      <LocationOnIcon />
    </div>
  </Tooltip>
);

const CurrentLocationMarker = ({ lat, lng }) => (
  <div style={{ color: "blue", fontSize: "18px" }}>
    <LocationOnIcon />
  </div>
);

let DEFAULT_LATITUDE;
let DEFAULT_LONGITUDE;

const ProvidersNearBy = ({ open, onClose, location, ...otherprops }) => {
  const dispatch = useDispatch();

  const userLocation = useSelector((state) => state.location);

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [nearbyProviders, setNearbyProviders] = useState([]);
  const [providerTypes, setProviderTypes] = useState([]);
  const [selectedProviderType, setSelectedProviderType] = useState("");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mapCenter, setMapCenter] = useState(
    currentLocation
      ? { lat: currentLocation.lat, lng: currentLocation.lng }
      : { lat: DEFAULT_LATITUDE, lng: DEFAULT_LONGITUDE }
  );
  const [stateResults, setStateResults] = useState([]);
  const [listVisible, setListVisible] = useState(false);
  const [providersList, setProvidersList] = useState(false);
  const [providers, setProviders] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [selectedProviderLocation, setSelectedProviderLocation] =
    useState(null);
  const [defaultLatitude, setDefaultLatitude] = useState(DEFAULT_LATITUDE);
  const [defaultLongitude, setDefaultLongitude] = useState(DEFAULT_LONGITUDE);
  const uid = useSelector((state) => state.uid);
  const [inputValue, setInputValue] = useState(null);
  const [latLongLoca, setLatLongLoca] = useState({ lat: "", long: "" });
  const [providerLoc, setProviderLoc] = useState("")

  useEffect(() => {
    getAddress();
    fetchProviderTypes();

    if (location && location.lat) {
      fetchNearByProviders(location && location.lat, location && location.lng);
      setLatLongLoca({
        lat: location && location.lat,
        long: location && location.lng,
      });
    }

    if (searchInputValue === "") {
      fetchNearByProviders();
    }
  }, [location, latLongLoca]);


  // useEffect(() => {
  //   if (location && location.lat) {
  //     fetchNearByProviders(location && location.lat, location && location.lng, searchInputValue,inputValue);
  //     setLatLongLoca({
  //       lat: location && location.lat,
  //       long: location && location.lng,
  //     });
  //   }

  // }, [searchInputValue]);

  useEffect(() => {
    if (otherprops.searchLoc) {
      setInputValue(otherprops.searchLoc);
      handleCityStateClick(otherprops.searchLoc);
    }
  }, [otherprops.searchLoc]);

  const getAddress = async () => {
    if (userLocation && userLocation !== null) {
      handleCityStateClick(userLocation);
      setInputValue(userLocation);
    } else if (otherprops.searchLoc && otherprops.searchLoc !== null) {
      handleCityStateClick(otherprops.searchLoc);
      setInputValue(otherprops.searchLoc);
    } else {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const data = await GeolocationInfo(latitude, longitude);
          if (data.status === 200) {
            handleCityStateClick(
              `${data.city}, ${data.state}, ${data.country}`
            );
            setInputValue(`${data.city}, ${data.state}, ${data.country}`);
          }
        },
        (error) => {
          console.log("Error getting current location:", error);
        }
      );
    }
  };

  const fetchStates = async (query) => {
    try {
      const locValue = { location: query };
      const response = await Env.post(`location/getLocation`, locValue);
      setListVisible(true);
      setStateResults(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNearByProviders = async (
    latitude,
    longitude,
    providerName,
    address
  ) => {
    try {
      setIsLoading(true);
      let val;
      if (address !== "" && address !== undefined) {
        if (
          providerName !== "" &&
          selectedProviderType !== "" &&
          latitude === "" &&
          longitude === ""
        ) {
          val = {
            latitude: latLongLoca.lat === "" ? undefined : latLongLoca.lat,
            longitude: latLongLoca.long === "" ? undefined : latLongLoca.long,
            address: address,
            distance: 10,
            providerName: providerName === "" ? undefined : providerName,
            providerType:
              selectedProviderType === "All Providers"
                ? undefined
                : selectedProviderType,
          };
        } else if (
          typeof latitude === "number" &&
          typeof longitude === "number"
        ) {
          val = {
            latitude: latitude,
            longitude: longitude,
            address: address,
            distance: 10,
            providerName: providerName === "" ? undefined : providerName,
            // providerType:selectedProviderType === 'All Providers' ? undefined : selectedProviderType
          };
        } else {
          val = {
            latitude: latLongLoca.lat === "" ? undefined : latLongLoca.lat,
            longitude: latLongLoca.long === "" ? undefined : latLongLoca.long,
            distance: 10,
            providerName: providerName === "" ? undefined : providerName,
            address: address,
          };
        }

        const response = await axios.post(
          `${apiUrl}provider/providers-nearby-your-location`,
          val
        );
        setIsLoading(false);
        setNearbyProviders(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching nearby providers:", error);
    }
  };

  const fetchProviderTypes = async () => {
    try {
      const response = await axios.get(`${apiUrl}location/location-type`);
      setProviderTypes(response.data.states);
    } catch (error) {
      console.error("Error fetching provider types:", error);
    }
  };

  const fetchProvidersByType = async (
    latitude,
    longitude,
    distance,
    selectedType
  ) => {
    try {
      const dataVal = {
        latitude: latLongLoca.lat === "" ? undefined : latLongLoca.lat,
        longitude: latLongLoca.long === "" ? undefined : latLongLoca.long,
        distance: distance,
        address:
          userLocation === null
            ? otherprops.searchLoc
            : userLocation !== otherprops.searchLoc
            ? otherprops.searchLoc
            : userLocation,
        providerType:
          selectedType === "All Providers" ? undefined : selectedType,
      };

      const response = await axios.post(
        `${apiUrl}provider/providers-nearby-your-location`,
        dataVal
      );
      setNearbyProviders(response.data.data);
    } catch (error) {
      console.error("Error fetching providers by type:", error);
    }
  };

  const fetchProviders = async (query) => {
    try {
      const response = await axios.get(
        `${apiUrl}provider/providerlist-from-ribbon?name=${query}&address=${inputValue}&pageSize=10`
      );

      const result = await response.data.result.map((obj) => ({
        data: obj.name,
      }));
      setListVisible(true);
      setProviders(result);
    } catch (error) {
      console.error("Error fetching providers by type:", error);
    }
  };

  const handleChange = async (event) => {
    setSearchInputValue("");
    let latitude;
    let longitude;

    latitude = latLongLoca.lat;
    longitude = latLongLoca.long;

    const selectedType = event.target.value;
    setSelectedProviderType(selectedType);
    setIsLoading(true);

    if (selectedType === "") {
      fetchNearByProviders(latitude, longitude, "");
    } else {
      const distance = 10;
      await fetchProvidersByType(latitude, longitude, distance, selectedType);
    }
    setIsLoading(false);
  };

  const handleShowAllProviders = () => {
    setSelectedProviderType("");

    const allProviders = nearbyProviders.flatMap((provider) => provider);

    const sortedProviders = allProviders.sort((a, b) =>
      a.name?.localeCompare(b.name)
    );

    setNearbyProviders(sortedProviders);

    setMapCenter({
      lat:
        sortedProviders.length > 0
          ? sortedProviders[0].latitude
          : defaultLatitude,
      lng:
        sortedProviders.length > 0
          ? sortedProviders[0].longitude
          : defaultLongitude,
    });
  };

  const handleProviderSelect = (provider) => {
    setSelectedProvider(provider);
    setSelectedProviderLocation({
      lat: provider.latitude,
      lng: provider.longitude,
    });
    setMapCenter({
      lat: provider.latitude,
      lng: provider.longitude,
    });

    window.open(`/providerinfo/${provider.uuid}`, "_blank");
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
          fetchNearByProviders(latitude, longitude, "");
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting current location:", error);
          setMapCenter({ lat: DEFAULT_LATITUDE, lng: DEFAULT_LONGITUDE });
          fetchNearByProviders(DEFAULT_LATITUDE, DEFAULT_LONGITUDE, "");
          setCurrentLocation(null);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setMapCenter({ lat: DEFAULT_LATITUDE, lng: DEFAULT_LONGITUDE });
      fetchNearByProviders(DEFAULT_LATITUDE, DEFAULT_LONGITUDE, "");
      setCurrentLocation(null);
    }
  };

  const getCoordinatesByCityAndState = async (city, state) => {
    try {
      const address = `${city}, ${state}`;
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${REACT_APP_API_GOOGLE_MAP_KEY}`
      );
      const { results } = response.data;
      if (results && results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        return { latitude: lat, longitude: lng };
      } else {
        console.error("No results found");
        return null;
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return null;
    }
  };

  const handleInputChange = async (event) => {
    const query = event.target.value;
    setInputValue(query);
    if (query.trim() !== "") {
      await fetchStates(query);
    } else {
      setStateResults([]);
      setListVisible(false);
    }
  };

  const handleSearchInput = async (event) => {
    const query = event.target.value;
    setSearchInputValue(query);
    if (query !== "") {
      await fetchProviders(query);
      setProvidersList(true);
    } else {
      setProviders([])
      setProvidersList(false);
      fetchNearByProviders(
        "","",
        "", providerLoc
      );
    }
  };

  const handleCityStateClick = async (cityState, type) => {
    const [city, state] = cityState?.split(", ");
    const coordinates = await getCoordinatesByCityAndState(city, state);
    setStateResults([]);
    if (coordinates) {
      const { latitude, longitude } = coordinates;
      setMapCenter({ lat: latitude, lng: longitude });
      setIsLoading(true);
      await fetchNearByProviders(latitude, longitude, "", cityState);
      setProviderLoc(cityState)
      setIsLoading(false);
      setDefaultLatitude(latitude);
      setDefaultLongitude(longitude);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      fullWidth
      maxWidth="xl"
      className="provide-popup"
    >
      <Grid
        container
        spacing={2}
        sx={{ padding: "2rem", width: "100%", margin: "0px" }}
      >
        <Grid item xs={12}>
          <CloseIcon
            style={{
              right: "5px",
              position: "absolute",
              margin: "10px",
              top: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              onClose();
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ marginTop: 0 }}>
          <div className="provide-left">
            <h1 style={{ marginBottom: "1rem", marginTop: "0rem" }}>
              Providers Near By
            </h1>
            <div style={{ position: "relative" }}>
              <Input
                id="input-with-icon-textfield"
                className="loc-imge cur-loc"
                placeholder="Current Location"
                value={inputValue}
                startAdornment={
                  <InputAdornment position="start" sx={{ color: "red" }}>
                    <LocationOnIcon
                      sx={{ color: "#000" }}
                      onClick={handleGetCurrentLocation}
                    />
                  </InputAdornment>
                }
                onChange={handleInputChange}
              />
              {listVisible && stateResults && stateResults.length > 0 && (
                <List
                  sx={{
                    position: "absolute",
                    marginTop: "0px",
                    left: "0px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "22px",
                    marginLeft: "0px",
                    maxHeight: "150px",
                    overflowY: "auto",
                    color: "#000",
                    width: "100%",
                    zIndex: 9999,
                    cursor: "pointer",
                  }}
                >
                  {stateResults.map((option) => (
                    <ListItem
                      key={option.id}
                      onClick={() => {
                        setListVisible(false);
                        dispatch(searchLocation(option.label));
                        setInputValue(option.label);
                        setSearchInputValue("")
                        setProviderLoc(option.label)
                        handleCityStateClick(option.label, "click");
                      }}
                    >
                      <ListItemText primary={option.label} />
                    </ListItem>
                  ))}
                </List>
              )}
              <FormControl fullWidth sx={{ mt: 1 }}>
                <div>
                  <InputLabel id="provider-type-label">
                    Provider Type
                  </InputLabel>
                </div>
                <Select
                  labelId="provider-type-label"
                  id="provider-type"
                  value={selectedProviderType}
                  onChange={handleChange}
                  input={<OutlinedInput label="Provider Type" />}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224,
                        width: 250,
                      },
                    },
                    marginThreshold: null,
                  }}
                >
                  <MenuItem
                    value="All Providers"
                    onClick={handleShowAllProviders}
                  >
                    All Providers
                  </MenuItem>
                  {providerTypes.map((type) => (
                    <MenuItem key={type.displayName} value={type.displayName}>
                      {type.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                className="serchh-imge"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    // <InputAdornment position="start" sx={{ color: "red" }}>
                    //   <LocationOnIcon
                    //     sx={{ color: "#000" }}
                    //     onClick={handleGetCurrentLocation}
                    //   />
                    // </InputAdornment>
                    <Button
                      sx={{ textDecoration: "none" }}
                      onClick={() => {
                        if (searchInputValue) {
                          fetchNearByProviders(
                            "","",
                            searchInputValue, providerLoc
                          );
                        }else{
                          return
                        }
                      }}
                    >
                      Search
                    </Button>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                value={searchInputValue}
                margin="normal"
                placeholder="Search Nearby Providers"
                size="small"
                name="name"
                //onChange={(e) => handleSearchNearbyProviders(e.target.value)}
                onChange={handleSearchInput}
                // onClick={() => {
                //   if (searchInputValue) {
                //     fetchNearByProviders(
                //       location && location.lat
                //         ? location.lat
                //         : DEFAULT_LATITUDE,
                //       location && location.lng
                //         ? location.lng
                //         : DEFAULT_LONGITUDE,
                //       searchInputValue
                //     );
                //   }
                // }}
              />
              {providersList && providers && providers.length > 0 && (
                <List
                  sx={{
                    position: "absolute",
                    marginTop: "0px",
                    left: "0px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "22px",
                    marginLeft: "0px",
                    maxHeight: "150px",
                    overflowY: "auto",
                    color: "#000",
                    width: "100%",
                    zIndex: 9999,
                    cursor: "pointer",
                  }}
                >
                  {providers.map((provider, index) => (
                    <ListItem
                      key={index}
                      onClick={() => {
                        setProvidersList(false);
                        setSearchInputValue(provider.data);
                        // fetchNearByProviders(
                        //         location && location.lat
                        //           ? location.lat
                        //           : DEFAULT_LATITUDE,
                        //         location && location.lng
                        //           ? location.lng
                        //           : DEFAULT_LONGITUDE,
                        //           provider.data
                        //       );
                      }}
                    >
                      <ListItemText primary={provider.data} />
                    </ListItem>
                  ))}
                </List>
              )}
              <h2 style={{ marginBottom: "0px" }}>Providers Near You</h2>
              <Grid
                container
                spacing={2}
                sx={{
                  width: "100%",
                  margin: "0px",
                  height: "100%",
                  maxHeight: "373px",
                  overflowY: "auto",
                  paddingRight: "1rem",
                }}
              >
                {nearbyProviders && nearbyProviders.length > 0 ? (
                  nearbyProviders.map((provider, index) => (
                    <Grid item xs={12} key={index}>
                      <Tooltip title={provider.name} arrow placement="top">
                        <div
                          style={{
                            backgroundColor: "#F2F2F2",
                            borderRadius: "5px",
                            padding: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleProviderSelect(provider)}
                        >
                          <p
                            style={{
                              color: "#45A736",
                              fontSize: "20px",
                              margin: 0,
                              fontWeight: "600",
                            }}
                          >
                            {provider.name
                              ? provider.name.length > 30
                                ? provider.name.substring(0, 30) + "..."
                                : provider.name
                              : "Individual/Clinic"}
                          </p>
                          <span style={{ color: "#02363D", fontSize: "14px" }}>
                            {provider.distance
                              ? `${provider.distance} miles away`
                              : ""}
                          </span>
                        </div>
                      </Tooltip>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <div style={{ color: "green" }}>No Providers Near You</div>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div
            className="map-left"
            style={{ position: "relative", height: "80vh" }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{ key: REACT_APP_API_GOOGLE_MAP_KEY }}
              center={mapCenter}
              defaultZoom={15}
            >
              {nearbyProviders.map((provider, index) => (
                <LocationMarker
                  key={index}
                  lat={provider.latitude}
                  lng={provider.longitude}
                  name={provider.name}
                  onClick={() => handleProviderSelect(provider)}
                />
              ))}
              {selectedProviderLocation && (
                <LocationMarker
                  lat={selectedProviderLocation.lat}
                  lng={selectedProviderLocation.lng}
                  name={selectedProvider.name}
                />
              )}
              {currentLocation && (
                <CurrentLocationMarker
                  lat={currentLocation.lat}
                  lng={currentLocation.lng}
                />
              )}
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                  }}
                >
                  <CircularProgress color="success" />
                </div>
              )}
            </GoogleMapReact>
          </div>
        </Grid>
      </Grid>
    </Dialog>
  );
};

function mapDispatchToProps(state) {
  return {
    location: state.location,
    searchLoc: state.searchLoc,
  };
}

export default connect(mapDispatchToProps)(ProvidersNearBy);
